import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

export const AvatarModal = props => {
    const { tenant_uid, showToast, onClose } = props;
    const [state, setState] = useState({
        avatar: props.avatar,
        filename: "",
        raw_file: null
    });
    const { avatar, filename, raw_file } = state;

    //File ref required to access the file browser
    const fileRef = React.useRef();

    //Funky pass through to open the file browse
    const handleFileClick = () => {
        fileRef.current.click();
    };

    //We have a selected file
    const handleFileChange = e => {
        const raw_file = e.target.files[0];
        setState({ ...state, filename: raw_file.name, raw_file });
    };

    const handleChange = e => {
        const new_avatar = {
            ...avatar,
            [e.target.id]: e.target.value
        };

        setState({ ...state, avatar: new_avatar });
    };

    const handleSave = () => {
        let payload = {
            tenant_uid,
            name: avatar.name
        };
        if (filename != null) {
            payload.image = raw_file;
        }

        //Reach out to the server
        if ("uid" in avatar) {
            payload.avatar_uid = avatar.uid;
            Util.fetch_js(
                "/avatar/modify/",
                payload,
                js => {
                    onClose();
                    showToast(Language.getSentenceCase('Successfully updated'), 'successful');
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
        //Create
        else {
            Util.fetch_js(
                "/avatar/create/",
                payload,
                js => {
                    onClose();
                    showToast(Language.getSentenceCase('Successfully updated'), 'successful');
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
    };

    return (
        <div className='basic-modal basic-modal--add-question' tabIndex={-1}>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content starts')}</p>
            <div className='basic-modal__head'>
                <h2>Avatar</h2>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className='basic-modal__field-container basic-modal__field-container--add-question'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('name')}
                    </label>
                    <input
                        type='text'
                        className='mt-1 border-1 border-gray-300 rounded'
                        placeholder={Language.getTitleCase('Avatar name')}
                        id='name'
                        value={avatar.name}
                        onChange={handleChange}
                    />
                </div>
                <div className='basic-modal__field basic-modal__field--add-question'></div>
            </div>

            <div className='basic-modal__field-container basic-modal__field-container--add-question'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('image')}
                    </label>
                    {avatar.image ? (
                        <img
                            style={{ width: 150, height: 150 }}
                            src={avatar.image}
                            alt='avatar'
                            onClick={handleFileClick}
                        />
                    ) : (
                        <div onClick={handleFileClick}>
                            <svg
                                className='basic-modal__add-image-icon'
                                width='45'
                                height='34'
                                viewBox='0 0 45 34'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    opacity='0.2'
                                    d='M40.7812 34C43.0664 34 45 32.1543 45 29.7812V4.46875C45 2.18359 43.0664 0.25 40.7812 0.25H4.21875C1.8457 0.25 0 2.18359 0 4.46875V29.7812C0 32.1543 1.8457 34 4.21875 34H40.7812ZM9.84375 5.17188C12.4805 5.17188 14.7656 7.45703 14.7656 10.0938C14.7656 12.8184 12.4805 15.0156 9.84375 15.0156C7.11914 15.0156 4.92188 12.8184 4.92188 10.0938C4.92188 7.45703 7.11914 5.17188 9.84375 5.17188ZM5.625 28.375V24.1562L11.8652 17.916C12.3047 17.4766 12.9199 17.4766 13.3594 17.916L18.2812 22.75L30.1465 10.8848C30.5859 10.4453 31.2012 10.4453 31.6406 10.8848L39.375 18.5312V28.375H5.625Z'
                                    fill='#607D8B'
                                />
                            </svg>

                            <p className='basic-modal__upload-label'>
                                <span>{Language.getSentenceCase('No image file selected')}</span>
                            </p>
                        </div>
                    )}
                </div>

                <div className='basic-modal__wrapper basic-modal__wrapper--add-badge'>
                    <input
                        type='file'
                        accept='image/*'
                        ref={node => (fileRef.current = node)}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                    />
                    <input
                        className='mt-1 border-1 border-gray-300 rounded'
                        type='text'
                        readOnly
                        name='user-file'
                        placeholder={Language.getSentenceCase('No File Selected')}
                        value={filename}
                    />
                    <button
                        className='basic-modal__button basic-modal__button--solid'
                        style={{ backgroundColor: "var(--drip7-primary-color)"}}
                        type='button'
                        name='choose-file'
                        onClick={handleFileClick}>
                        {Language.getTitleCase('Choose File')}
                    </button>
                </div>
            </div>

            <div className='basic-modal__button-container'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>
        </div>
    );
};
