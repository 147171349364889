import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Util from "../helpers/util";

export const Drip7Dropdown = props => {
    const id = props.id || ""
    const name = props.name || ""
    const entries = props.entries || []
    const title = props.title || null
    const onChange = props.onChange || (() => {})
    const className = props.className || ""

    return (
        <Menu as="div" className={Util.classNames("relative inline-block text-left", className)}>
            <div>
                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {title}
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                <div className="py-1">
                    {entries.map((entry, idx) => (
                    <MenuItem key={`combo_${title}_${idx}`}>
                        <div className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                             onClick={() => onChange({target: { id, name, value: entry}})}>
                            {entry}
                        </div>
                    </MenuItem>
                    ))}
                </div>
            </MenuItems>
        </Menu>
    )
}

