import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { buildQuestions, filterQuestions } from "../../helpers/content";
import {AnswerType, BlockMode, ContentType, Languages} from "../../helpers/consts";
import { QuestionTableWidget } from "./question_table_widget";
import {InputWithArrows} from "../input_with_arrows";
import TrashImage from "../../assets/images/admin/trash.svg";
import { SelectItems } from "../select_items";

export const CurriculumSpecificSlotWidget = props => {
    const { category_summaries, category_questions, question_uids, category_uids, slots, onChange } = props;

    const [state, setState] = useState({
        category_uid: '',
        tag_uid: '',
        content_type: '',
        answer_type: '',
    })
    const { category_uid, tag_uid, content_type, answer_type } = state

    const handleSlotChange = ( uids ) => {
        const value = uids.map( x => ({ question_uid: x }))
        onChange( { target: { id: 'slots', value }})
    }

    const handleChange = (e) => {
        setState(prev => ({...prev,
            [e.target.id]: e.target.value,
        }))
    }

    const ctt = (content_type == '')? "Content": content_type
    const att = (answer_type == '')? "Answer": answer_type

    let cat_title = "Category"
    category_summaries.forEach( x => {
        if ( x.uid == category_uid ) {
            cat_title = x.name
        }
    })

    const cat_lookup = {}
    category_uids.forEach( x => { cat_lookup[x] = true })
    const question_lookup = {}
    question_uids.forEach( x => { question_lookup[x] = true })

    let questions_raw = {}
    category_questions.forEach(x => {
        if ( category_uid != '' && category_uid != x.uid ) {
            return
        }

        const include_all = x.uid in cat_lookup
        x.questions.forEach(q => {
            if ( content_type != '' && content_type != q.content_type ) {
                return
            }
            if ( answer_type != '' && answer_type != q.answer_type ) {
                return
            }

            if ( include_all || q.uid in question_lookup ) {
                const c = (Array.isArray(q.follow_up_uids) && q.follow_up_uids.length > 0 || Object.keys(q.follow_up_uids).length > 0)? '[C] ': ''
                questions_raw[q.uid] = { ...q, name: `${c}${q.name}`}
            }
        })
    })

    return (
        <SelectItems
            title={Language.getTitleCase( "questions" )}
            selected={slots.map(x => x.question_uid)}
            items={Object.values(questions_raw)}
            onChange={handleSlotChange}>

                <div className="buttons">
                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton title={cat_title}>
                            <Dropdown.Item
                                as='button'
                                onClick={handleChange}
                                id='category_uid'
                                key='category_uid_-1'
                                value=''>
                                ---
                            </Dropdown.Item>
                            {Object.entries(category_summaries)
                                .sort((c1, c2) => {
                                    return c1[1].name.localeCompare(c2[1].name);
                                })
                                .map(([key, category]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChange}
                                        id='category_uid'
                                        value={category.uid}
                                        key={"category_uid_" + key}>
                                        {category.name}
                                    </Dropdown.Item>
                                ))}
                        </DropdownButton>
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton title={ctt}>
                            <Dropdown.Item
                                as='button'
                                onClick={handleChange}
                                id='content_type'
                                key='content_type_-1'
                                value=''>
                                ---
                            </Dropdown.Item>
                            {Object.entries(ContentType).map(
                                ([key, content_type]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChange}
                                        id='content_type'
                                        value={content_type}
                                        key={"content_type_" + key}>
                                        {Language.getTitleCase(content_type)}
                                    </Dropdown.Item>
                                )
                            )}
                        </DropdownButton>
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton title={att}>
                            <Dropdown.Item
                                as='button'
                                onClick={handleChange}
                                id='answer_type'
                                key='answer_type_-1'
                                value=''>
                                ---
                            </Dropdown.Item>
                            {Object.entries(AnswerType).map(
                                ([key, answer_type]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChange}
                                        id='answer_type'
                                        value={answer_type}
                                        key={"answer_type_" + key}>
                                        {Language.getTitleCase(answer_type)}
                                    </Dropdown.Item>
                                )
                            )}
                        </DropdownButton>
                    </div>
                </div>
        </SelectItems>
    );
};
