import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import DatePicker, { DateObject } from "react-multi-date-picker";
import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import {
    Access,
    QueueDelivery,
    CurriculumUsage, QueueDeliveryUi
} from "../../helpers/consts";

import { QueueScheduleWidget } from "../../components/admin/queue_schedule_widget";
import { QueueCampaignWidget } from "../../components/admin/queue_campaign_widget";
import { SelectItems } from "../../components/select_items";
import {InputWithArrows} from "../../components/input_with_arrows";
import { ConfirmModal } from "../confirm_modal";


export const QueueTrackModal = props => {
    const { tenant_uid, showToast, onClose } = props
    let raw_queue_track = props.queue_track
    if (raw_queue_track == true || raw_queue_track == null || raw_queue_track == undefined) {
        raw_queue_track = {
            name: "",
            max_backlog: 10,
            max_daily: 2,
        };
    }

    const [state, setState] = useState({
        queue_track: raw_queue_track,
        queues: [],
        selected: [],
        confirm: null,
    })
    const { queue_track, queues, selected, confirm } = state

    //Get all the queues we currently have in the system
    useEffect(() => {
        Util.fetch_js( "/queue/list/", { tenant_uid, access: Access.EXEC },
            js => {
                let selected = []
                if ( 'uid' in queue_track && queue_track.uid != "" ) {
                    let items = js.queues.filter(x => x.queue_track != null && ('uid' in x.queue_track) && x.queue_track.uid == queue_track.uid)
                    items.sort((a,b) => a.priority - b.priority)
                    selected = items.map(x => x.uid)
                }

                setState( prev => ({ ...prev,
                    queues: js.queues.filter(x => x.delivery == QueueDelivery.Drip && x.active ),
                    selected,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }, [])

    const handleChange = (e) => {
        setState( prev => ({ ...prev,
            [e.target.id]: e.target.value,
        }))
    }

    const handleQueueTrackChange = (e) => {
        let { queue_track } = state
        queue_track[e.target.id] = e.target.value

        setState( prev => ({ ...prev,
            queue_track
        }))
    }

    const handleSave = () => {
        const payload = { ...queue_track, queue_uids: selected, }

        //Ensure the name is going to be clickable
        if ( queue_track.name.length < 3 ) {
            showToast(Language.getSentenceCase("Name must have at least 3 characters"), "failure")
            return
        }

        // Create?
        if (!("uid" in queue_track) || queue_track.uid == "") {
            Util.fetch_js( "/queue_track/create/", { ...payload, tenant_uid },
                js => {
                    showToast(Language.getSentenceCase('created queue track'), 'successful');
                    onClose(true);
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
        //Update
        else {
            Util.fetch_js( "/queue_track/modify/", { ...payload, queue_track_uid: queue_track.uid },
                js => {
                    showToast(Language.getSentenceCase('updated queue track'), 'successful');
                    onClose(true);
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
    }

    const handleRemove = () => {
        Util.fetch_js( "/queue_track/remove/", { queue_track_uids: [queue_track.uid] },
            js => {
                showToast("Deleted queue track", "successful");
                onClose()
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState( prev => ({ ...prev,
            confirm: null
        }))
    }

    const confirmAction = (action) => {
        const confirm = {
            action,
            msg: Language.getTitleCase('delete queue track'),
            yes: Language.getTitleCase('remove'),
            no: Language.getTitleCase('cancel')
        };
        setState( prev => ({ ...prev,
            confirm
        }))
    }

    return (
        <div className='basic-modal basic-modal--add-group'>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('queue track')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('name')}
                    </label>
                    <input
                        type='text'
                        className='mt-1 border-1 border-gray-300 rounded'
                        id='newQuestionCategory'
                        placeholder='Name'
                        id='name'
                        value={queue_track.name}
                        onChange={handleQueueTrackChange}
                    />
                </div>
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('max daily questions')}
                    </label>
                    <InputWithArrows
                        id="max_daily"
                        value={queue_track.max_daily}
                        min={1}
                        max={100}
                        onChange={handleQueueTrackChange}
                    />
                </div>

                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('max backlog of questions')}
                    </label>
                    <InputWithArrows
                        id="max_backlog"
                        value={queue_track.max_backlog}
                        min={1}
                        max={100}
                        onChange={handleQueueTrackChange}
                    />
                </div>
            </div>

            <SelectItems
                selected={selected}
                items={queues}
                title={"queues"}
                searchTitle={"search queues by name"}
                onChange={value => handleChange({ target: { id: 'selected', value }})}
            />

            <div className='basic-modal__button-container'>
                {'uid' in queue_track &&
                    <button
                        type='button'
                        className='central__head-button central__head-button--remove'
                        name='remove-user'
                        onClick={() => confirmAction(handleRemove) }>
                        {Language.getTitleCase('remove')}
                    </button>
                }
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>

            {confirm != null && (
                <ConfirmModal
                    msg={confirm.msg}
                    yesTxt={confirm.yes}
                    noTxt={confirm.no}
                    onYes={() => confirm.action(true)}
                    onNo={() => setState(prev => ({ ...prev, confirm: null }))}
                />
            )}
        </div>
    );
}
