import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import QrReader from 'react-qr-scanner'
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Button } from "../drip7_button";

export const AnswerTypeQRCode = React.forwardRef((props, ref) => {
    const { answer, value, showToast, onChanged } = props;

    const is_answered = (value != undefined && value != null && Util.xint(value, -1) != -1)
    const safe_value = Util.xstr( value )

    React.useImperativeHandle(ref, () => ({
        reset: () => {
        },
        attemptAnswer: () => {
            return false;
        }
    }));

    const [state, setState] = useState({
        scanning: false,
        scan_value: ''
    })
    const { scanning, scan_value } = state

    const calcResult = ( answer, safe_value ) => {
        const ans_correct = (answer && 'correct' in answer && answer.correct != undefined && answer.correct != null)? answer.correct: safe_value
        const is_correct = (Util.xstr(ans_correct) == Util.xstr(safe_value))

        return {
            ans_correct,
            is_correct,
        }
    }

    const handleScan = () => {
        //Scan QR Code
        setState(prev => ({ ...prev,
            scanning: !scanning,
        }))
    }

    const handleScanResult = (data) => {
        if (data && data.text) {
            setState(prev => ({ ...prev,
                scanning: !scanning,
                answer: data.text
            }))
            console.log(`read ${data.text}`)
            const safe_value = data.text
            const { ans_correct, is_correct } = calcResult( answer, safe_value )
    
            onChanged(safe_value, is_correct, ans_correct.toString());
        }
      }

    const handleError = (err) => {
        showToast(err, 'failure')
    }

    const btn_title = scanning ? 'Stop Scanning' : 'Scan QR Code'
      
    return (
        <div className='daily-questions__answers daily-questions__answers--large'>
            {scanning &&
                <QrReader
                    delay={100}
                    style={{
                        height: 240,
                        width: 320,
                    }}
                    facingMode='rear'
                    // facingMode isn't working, but this constraints property setting
                    //      fixes it to use the rear camera
                    //   https://stackoverflow.com/questions/67739444/facingmode-property-not-working-in-react-qr-scanner-in-react-js
                    constraints={{
                        video: { facingMode: "environment" }
                    }}
                    onError={handleError}
                    onScan={handleScanResult}
                />
            }
            <Drip7Button
                nameBtn='scan-qrcode'
                onClick={handleScan}>
                {Language.getTitleCase(btn_title)}
            </Drip7Button>
        </div>
    );
});
