import React, { useState } from "react";
import Language from "../../helpers/language";
import Util from "../../helpers/util";
import {InputWithArrows} from "../input_with_arrows";

export const UserQuestionTableWidget = props => {
    const { user_questions, user_question_updates } = props;
    let { selected, show_selection, onChange, onSortBy, onSelectionChange } = props;

    if (show_selection == null || show_selection == undefined) {
        show_selection = false
    }
    if (onSortBy == null || onSortBy == undefined) {
        onSortBy = () => { };
    }
    if (onSelectionChange == null || onSelectionChange == undefined) {
        onSelectionChange = () => { };
    }

    const handleSelect = (checked, uid) => {
        if (checked) {
            selected[uid] = true;
        }
        else if (uid in selected) {
            delete selected[uid];
        }

        onSelectionChange(selected);
    };

    const handleSelectAll = e => {
        let selection = {};

        if (e.target.checked) {
            user_questions.forEach( x => {
                selection[x.uid] = true;
            })
        }

        onSelectionChange(selection);
    };

    return (
        <table className='questions__table'>
            <tbody>
                <tr className='questions__table-row questions__table-row--head'>
                    {show_selection && (
                        <th className='questions__table-head questions__table-head--input'>
                            <div className='d7__checkbox-container'>
                                <input
                                    className='d7__checkbox'
                                    aria-label="checkbox"
                                    type='checkbox'
                                    onChange={handleSelectAll}
                                />
                                <span className='dash'></span>
                            </div>
                        </th>
                    )}
                    <th className='questions__table-head questions__table-head--subject'>
                        <h5 className='questions__table-heading-no-sort'
                            onClick={() => onSortBy("name")}>
                            {Language.getTitleCase('question name')}
                        </h5>
                    </th>
                    <th className='questions__table-head questions__table-head--subject'>
                        <h5 className='questions__table-heading-no-sort'
                            onClick={() => onSortBy("queue_name")}>
                            {Language.getTitleCase('queue')}
                        </h5>
                    </th>
                    <th className='questions__table-head questions__table-head--subject'>
                        <h5 className='questions__table-heading-no-sort'>
                            {Language.getTitleCase( 'points' )}
                        </h5>
                    </th>
                    <th className='questions__table-head questions__table-head--subject'>
                        <h5 className='questions__table-heading-no-sort'>
                            {Language.getTitleCase( 'answer' )}
                        </h5>
                    </th>
                    <th className='questions__table-head questions__table-head--subject'>
                        <h5
                            className='questions__table-heading-no-sort'
                            onClick={() => onSortBy("assigned_on")}>
                            {Language.getTitleCase('assigned on')}
                        </h5>
                    </th>
                    <th className='questions__table-head questions__table-head--subject'>
                        <h5
                            className='questions__table-heading-no-sort'
                            onClick={() => onSortBy("answered_on")}>
                            {Language.getTitleCase('answered on')}
                        </h5>
                    </th>
                </tr>

                {Object.entries(user_questions).map(([key, uq]) => (
                    <tr
                        className='questions__table-row questions__table-row--body'
                        key={"category_key_" + key}>
                        {show_selection && (
                            <td className='questions__table-data questions__table-data--input'>
                                <div className='d7__checkbox-container'>
                                    <input
                                        className='d7__checkbox'
                                        type='checkbox'
                                        aria-label="checkbox"
                                        onChange={event => handleSelect( event.target.checked, uq.uid )}
                                        checked={uq.uid in selected}
                                    />
                                    <span className='checkmark'></span>
                                </div>
                            </td>
                        )}
                        <td className='questions__table-data questions__table-data--name'>
                            <h5 className='questions__table-text'>
                                {Util.truncate( uq.question.name, 32 )}
                            </h5>
                        </td>
                        <td className='questions__table-data'>
                            <h5 className='questions__table-text'>
                                {uq.queue_name}
                            </h5>
                        </td>
                        <td className='questions__table-data'>
                            <InputWithArrows
                                id={uq.uid}
                                value={(uq.uid in user_question_updates)? user_question_updates[uq.uid] : uq.points}
                                readOnly={uq.unanswered}
                                onChange={e => onChange( e.target.id, e.target.value )}
                            />
                        </td>
                        <td className='questions__table-data'>
                            <h5 className='questions__table-text'>
                                {uq.correct_name}
                            </h5>
                        </td>
                        <td className='questions__table-data'>
                            <h5 className='questions__table-text'>
                                {Util.epochToDate(uq.assigned_on, false)}
                            </h5>
                        </td>
                        <td className='questions__table-data'>
                            <h5 className='questions__table-text'>
                                {uq.unanswered && "-"}
                                {!uq.unanswered &&
                                    <>
                                        {Util.epochToDate(uq.answered_on, false)}
                                        &nbsp;({Math.floor(Math.abs(uq.answered_on - uq.assigned_on) / 3600000)}h)
                                    </>
                                }
                            </h5>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
