import React, { useState } from "react";
import Language from "../../helpers/language";
import Util from "../../helpers/util";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export const QuestionTableWidget = (props) => {
    const { questions, uncapped } = props;
    let {
        selected,
        show_selection,
        show_tips,
        onQuestionClick,
        onSortBy,
        onSelectionChange,
    } = props;
    let { show_tags, show_answers } = props;
    const sortable =
        props.sortable != undefined && props.sortable == false ? false : true;

    if (show_selection == null || show_selection == undefined) {
        show_selection = true;
    }
    if (onQuestionClick == null || onQuestionClick == undefined) {
        onQuestionClick = () => {};
    }
    if (onSortBy == null || onSortBy == undefined) {
        onSortBy = () => {};
    }
    if (onSelectionChange == null || onSelectionChange == undefined) {
        onSelectionChange = () => {};
    }
    if (show_tags == null || show_tags == undefined) {
        show_tags = false;
    }
    if (show_answers == null || show_answers == undefined) {
        show_answers = true;
    }
    if (show_tips == null || show_tips == undefined) {
        show_tips = false;
    }

    //Keep the entries to a reasonable number
    let capped = [];
    if (!uncapped && questions.length > 50) {
        for (let i = 0; i < 50 && i < questions.length; i++) {
            capped.push(questions[i]);
        }
    } else {
        capped = questions;
    }

    const handleSelect = (checked, uid) => {
        if (checked) {
            selected[uid] = true;
        } else if (uid in selected) {
            delete selected[uid];
        }

        onSelectionChange(selected);
    };

    const handleSelectAll = (e) => {
        const selection = {};

        if (e.target.checked) {
            for (let i = 0; i < questions.length; i++) {
                selection[questions[i].uid] = true;
            }
        }

        onSelectionChange(selection);
    };
    return (
        <table className="questions__table">
            <tbody>
                <tr className="questions__table-row questions__table-row--head">
                    {show_selection && (
                        <th className="questions__table-head questions__table-head--input sticky-header">
                            <div className="d7__checkbox-container">
                                <input
                                    aria-label="checkbox"
                                    className="d7__checkbox"
                                    type="checkbox"
                                    onChange={handleSelectAll}
                                />
                                <span className="dash"></span>
                            </div>
                        </th>
                    )}
                    <th className="questions__table-head questions__table-head--name sticky-header">
                        <span
                            className={show_tips ? "tool-tip__container" : ""}
                        >
                            <span className="tool-tip__text">
                                {Language.getSentenceCase(
                                    "sort alphabetically by question name"
                                )}
                            </span>
                            <h5
                                className={
                                    sortable == true
                                        ? "questions__table-heading"
                                        : "questions__table-heading-no-sort"
                                }
                                onClick={() => onSortBy("name")}
                            >
                                {Language.getTitleCase("question name")}
                            </h5>
                        </span>
                    </th>
                    <th
                        className="questions__table-head sticky-header"
                        onClick={() => onSortBy("tenant_name")}
                    >
                        <span
                            className={show_tips ? "tool-tip__container" : ""}
                        >
                            <span className="tool-tip__text">
                                {Language.getSentenceCase(
                                    "sort alphabetically by tenant"
                                )}
                            </span>
                            <h5
                                className={
                                    sortable == true
                                        ? "questions__table-heading"
                                        : "questions__table-heading-no-sort"
                                }
                            >
                                {Language.getTitleCase("tenant")}
                            </h5>
                        </span>
                    </th>
                    <th
                        className="questions__table-head sticky-header"
                        onClick={() => onSortBy("content_type")}
                    >
                        <span
                            className={show_tips ? "tool-tip__container" : ""}
                        >
                            <span className="tool-tip__text">
                                {Language.getSentenceCase(
                                    "sort alphabetically by content type"
                                )}
                            </span>
                            <h5
                                className={
                                    sortable == true
                                        ? "questions__table-heading"
                                        : "questions__table-heading-no-sort"
                                }
                            >
                                {Language.getTitleCase("content type")}
                            </h5>
                        </span>
                    </th>
                    <th
                        className="questions__table-head sticky-header"
                        onClick={() => onSortBy("answer_type")}
                    >
                        <span
                            className={show_tips ? "tool-tip__container" : ""}
                        >
                            <span className="tool-tip__text">
                                {Language.getSentenceCase(
                                    "sort alphabetically by answer type"
                                )}
                            </span>
                            <h5
                                className={
                                    sortable == true
                                        ? "questions__table-heading"
                                        : "questions__table-heading-no-sort"
                                }
                            >
                                {Language.getTitleCase("answer type")}
                            </h5>
                        </span>
                    </th>
                    <th className="questions__table-head sticky-header">
                        <span
                            className={show_tips ? "tool-tip__container" : ""}
                        >
                            <span className="tool-tip__text tool-tip__text__right">
                                {Language.getSentenceCase(
                                    "sort alphabetically by category"
                                )}
                            </span>
                            <h5
                                className={
                                    sortable == true
                                        ? "questions__table-heading"
                                        : "questions__table-heading-no-sort"
                                }
                                onClick={() => onSortBy("category")}
                            >
                                {Language.getTitleCase("category")}
                            </h5>
                        </span>
                    </th>
                    {show_answers && (
                        <th className="questions__table-head sticky-header">
                            <span
                                className={
                                    show_tips ? "tool-tip__container" : ""
                                }
                            >
                                <span className="tool-tip__text tool-tip__text__right">
                                    {Language.getSentenceCase(
                                        "sort greatest to least by correct, incorrect, and unanswered"
                                    )}
                                </span>
                                <h5
                                    className={
                                        sortable == true
                                            ? "questions__table-heading"
                                            : "questions__table-heading-no-sort"
                                    }
                                    onClick={() => onSortBy("answers")}
                                >
                                    {Language.getTitleCase("answers")}
                                </h5>
                            </span>
                        </th>
                    )}
                    {false && (
                        <th className="questions__table-head sticky-header">
                            <h5
                                className={
                                    sortable == true
                                        ? "questions__table-heading"
                                        : "questions__table-heading-no-sort"
                                }
                                onClick={() => onSortBy("created_on")}
                            >
                                {Language.getTitleCase("uploaded on")}
                            </h5>
                        </th>
                    )}
                </tr>

                {Object.entries(capped).map(([key, question]) => (
                    <tr
                        className="questions__table-row questions__table-row--body"
                        key={"category_key_" + key}
                    >
                        {show_selection && (
                            <td className="questions__table-data questions__table-data--input">
                                <div className="d7__checkbox-container">
                                    <input
                                        className="d7__checkbox"
                                        aria-label="checkbox"
                                        type="checkbox"
                                        onChange={(event) =>
                                            handleSelect(
                                                event.target.checked,
                                                question.uid
                                            )
                                        }
                                        checked={question.uid in selected}
                                    />
                                    <span className="checkmark"></span>
                                </div>
                            </td>
                        )}
                        <td className="questions__table-data questions__table-data--name tableW30">
                            <h5
                                className="questions__table-text"
                                onClick={() =>
                                    onQuestionClick(question, "name")
                                }
                            >
                                {question.compound ? "[C] " : ""}
                                {question.name}
                            </h5>
                        </td>
                        <td className="questions__table-data tableW15">
                            <h5
                                className="questions__table-text"
                                onClick={() =>
                                    onQuestionClick(question, "tenant_name")
                                }
                            >
                                {question.tenant_name}
                            </h5>
                        </td>
                        <td className="questions__table-data tableW15">
                            <h5
                                className="questions__table-text"
                                onClick={() =>
                                    onQuestionClick(question, "content_type")
                                }
                            >
                                {question.content_type}
                            </h5>
                        </td>
                        <td className="questions__table-data tableW15">
                            <h5
                                className="questions__table-text"
                                onClick={() =>
                                    onQuestionClick(question, "answer_type")
                                }
                            >
                                {question.answer_type}
                            </h5>
                        </td>
                        <td className="questions__table-data tableW15">
                            <h5
                                className="questions__table-text"
                                onClick={() =>
                                    onQuestionClick(question, "category")
                                }
                            >
                                {question.category}
                            </h5>
                        </td>
                        {show_tags && (
                            <td className="questions__table-data tableW15">
                                <h5
                                    className="questions__table-text"
                                    onClick={() =>
                                        onQuestionClick(question, "tags")
                                    }
                                >
                                    {question.tags}
                                </h5>
                            </td>
                        )}
                        {show_answers && (
                            <td
                                className={
                                    show_tips
                                        ? "tool-tip__container"
                                        : "" + "questions__table-data tableW1"
                                }
                            >
                                <h5
                                    className="questions__table-text"
                                    onClick={() =>
                                        onQuestionClick(question, "answers")
                                    }
                                >
                                    <span className="tool-tip__text tool-tip__text-mini tool-tip__text__right">
                                        {question.answers.correct +
                                            " " +
                                            Language.getSentenceCase("correct")}
                                        <br />
                                        {question.answers.incorrect +
                                            " " +
                                            Language.getSentenceCase(
                                                "incorrect"
                                            )}
                                        <br />
                                        {question.answers.unanswered +
                                            " " +
                                            Language.getSentenceCase(
                                                "unanswered"
                                            )}
                                        <br />
                                    </span>
                                    <span style={{ color: "green" }}>
                                        {question.answers.correct}
                                    </span>{" "}
                                    /{" "}
                                    <span style={{ color: "red" }}>
                                        {question.answers.incorrect}
                                    </span>{" "}
                                    / <span>{question.answers.unanswered}</span>
                                </h5>
                            </td>
                        )}
                        {false && (
                            <td className="questions__table-data tableW15">
                                <h5
                                    className="questions__table-text"
                                    onClick={() =>
                                        onQuestionClick(question, "created_on")
                                    }
                                >
                                    {Util.epochToDate(question.created_on)}
                                </h5>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
