import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";

import { AnswerType, ContentType } from "../../helpers/consts";
import Language from "../../helpers/language";

export const AnswerBooleanWidget = props => {
    const { access, onChange } = props;

    const correct = ('correct' in props.answer && (props.answer.correct == true || props.answer.correct == false))? props.answer.correct : null

    useEffect(() => {
        onChange('answer', {}, { correct });
    }, [])

    const checkedKlass = (ideal, checked) => {
        const klass = (ideal == checked) ? " basic-modal__answer-checkmark--correct-answer" : ""
        return `basic-modal__answer-checkmark ${klass}`
    };

    const handleCorrect = correct => {
        if ( !access.write ) {
            return
        }

        if ( props.answer.correct == correct ) {
            correct = null
        }

        onChange('answer', {}, { correct });
    };

    return (
        <div className='basic-modal__add-answer-container width-100'>
            <div className='basic-modal__input-wrapper basic-modal__input-wrapper--add-answer width-100'>
                <div className='basic-modal__input basic-modal__input--add-answer width-100'>
                    {Language.getTitleCase('true')}
                </div>
                <svg
                    className={checkedKlass(true, correct)}
                    width='16'
                    height='13'
                    viewBox='0 0 16 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={() => handleCorrect(true)}>
                    <path
                        d='M5.56836 11.7656C5.86133 12.0586 6.35938 12.0586 6.65234 11.7656L15.2656 3.15234C15.5586 2.85938 15.5586 2.36133 15.2656 2.06836L14.2109 1.01367C13.918 0.720703 13.4492 0.720703 13.1562 1.01367L6.125 8.04492L2.81445 4.76367C2.52148 4.4707 2.05273 4.4707 1.75977 4.76367L0.705078 5.81836C0.412109 6.11133 0.412109 6.60938 0.705078 6.90234L5.56836 11.7656Z'
                        fill='white'></path>
                </svg>
            </div>
            <div className='basic-modal__input-wrapper basic-modal__input-wrapper--add-answer width-100'>
                <div className='basic-modal__input basic-modal__input--add-answer width-100'>
                    {Language.getTitleCase('false')}
                </div>
                <svg
                    className={checkedKlass(false, correct)}
                    width='16'
                    height='13'
                    viewBox='0 0 16 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={() => handleCorrect(false)}>
                    <path
                        d='M5.56836 11.7656C5.86133 12.0586 6.35938 12.0586 6.65234 11.7656L15.2656 3.15234C15.5586 2.85938 15.5586 2.36133 15.2656 2.06836L14.2109 1.01367C13.918 0.720703 13.4492 0.720703 13.1562 1.01367L6.125 8.04492L2.81445 4.76367C2.52148 4.4707 2.05273 4.4707 1.75977 4.76367L0.705078 5.81836C0.412109 6.11133 0.412109 6.60938 0.705078 6.90234L5.56836 11.7656Z'
                        fill='white'></path>
                </svg>
            </div>
        </div>
    );
};
