import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { Drip7Button } from "../../components/drip7_button";
import { GroupModal } from "../../modals/admin/group_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import { InputModal } from "../../modals/input_modal";


export const GroupsTab = (props) => {
    const { tenant_uid, tenants, showToast } = props

    const [state, setState] = useState({
        groups_selected: {},
        groups: [],
        show_create_group: false,
        show_edit_group: false,
        confirm: null,
        search: "",
        sort_by: "",
        sort_by_reverse: false
    })
    const { groups_selected, groups, show_create_group, show_edit_group, confirm, search, sort_by, sort_by_reverse } = state

    useEffect(() => {
        updateGroupList()
    }, [props])

    const filterGroups = (search, groups) => {
        //Do nothing
        if (search == "") {
            return groups;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            if (group.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(group);
            }
        }

        return result;
    }

    const updateGroupList = () => {
        //Get a list of all groups for tenant
        Util.fetch_js( "/group/list/", { tenant_uid },
            js => {
                setState(prev => ({...prev,
                    groups: js.groups
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const handleSortBy = (code) => {
        const sbr = sort_by == code ? !sort_by_reverse : false;

        setState(prev => ({...prev,
            sort_by: code,
            sort_by_reverse: sbr
        }))
    }

    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.name]: e.target.value }))
    }

    const handleRemoveGroup = () => {
        const group_uids = Object.keys(groups_selected)

        Util.fetch_js( "/group/remove/", { group_uids },
            js => {
                updateGroupList();
                setState(prev => ({...prev,
                    groups_selected: {}
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        setState(prev => ({...prev,
            confirm: null
        }))
    }

    const handleModalCreateClose = (name) => {
        if (name != null && name != "") {
            Util.fetch_js( "/group/create/", { tenant_uid, name },
                js => {
                    updateGroupList()
                    showToast(Language.getSentenceCase('Successfully created group'), 'successful')
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }

        setState(prev => ({...prev,
            show_create_group: false,
            show_edit_group: false,
        }))
    }

    const handleModalClose = () => {
        updateGroupList()
        setState(prev => ({...prev,
            show_create_group: false,
            show_edit_group: false,
        }))
    }

    const handleShowCreate = () => {
        setState(prev => ({...prev,
            show_create_group: true
        }))
    }

    const handleShowEdit = (group) => {
        setState(prev => ({...prev,
            show_edit_group: group
        }))
    }

    const handleSelectGroup = (checked, uid) => {
        if (checked) {
            groups_selected[uid] = true
        }
        else {
            delete groups_selected[uid]
        }

        setState(prev => ({...prev,
            groups_selected
        }))
    }

    const handleSelectAll = (checked, grps) => {
        const groups = filterGroups(search, grps)
        let groups_selected = {};

        //Should we check or send empty list?
        if (checked) {
            groups.forEach( x => { groups_selected[x.uid] = true; })
        }

        setState(prev => ({...prev,
            groups_selected
        }))
    }

    const handleViewGroup = (group) => {
        setState(prev => ({...prev,
            show_create_group: group
        }))
    }

    const selected_count = Object.keys(groups_selected).length;

    let filtered_groups = filterGroups(search, groups);

    //Sort?
    if (sort_by != "") {
        if (!sort_by_reverse) {
            filtered_groups.sort((a, b) =>
                a[sort_by]
                    .toLowerCase()
                    .localeCompare(b[sort_by].toLowerCase())
            );
        }
        else {
            filtered_groups.sort((a, b) =>
                b[sort_by]
                    .toLowerCase()
                    .localeCompare(a[sort_by].toLowerCase())
            );
        }
    }

    const confirmAction = action => {
        let yes = null;
        let msg = null;
        if (action == handleRemoveGroup) {
            yes = Language.getTitleCase('remove');
            msg = (selected_count != 1 ? Language.getTitleCase('Remove groups') : Language.getTitleCase('Remove group')) + '?'
        }
        else {
            throw Language.getSentenceCase('Program error, Unknown action') + ": " + action;
        }

        //Setting a confirm action, will load message box, and if accepted saves
        const new_confirm = { action, msg, yes, no: Language.getTitleCase('cancel') };
        setState(prev => ({...prev,
            confirm: new_confirm
        }))
    };

    const tenant = tenants.find(x => x.uid == tenant_uid);

    return (
        <>
            <div className='central__main'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'
                            />
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by name') +'...'}
                            name="search"
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        {selected_count > 0 && (
                            <Drip7Button
                                nameBtn='remove-user'
                                color='red-500'
                                onClick={() => confirmAction(handleRemoveGroup) }>
                                {(selected_count != 1 ? Language.getTitleCase('Remove groups') : Language.getTitleCase('Remove group'))}
                            </Drip7Button>
                        )}

                        <Drip7Button
                            nameBtn='add-user'
                            onClick={handleShowCreate}>
                            {Language.getTitleCase('create group')}
                        </Drip7Button>
                    </div>
                </div>

                <div className='table__container'>
                    {confirm != null && (
                        <ConfirmModal
                            msg={confirm.msg}
                            yesTxt={confirm.yes}
                            noTxt={confirm.no}
                            onYes={() => confirm.action(true)}
                            onNo={() => setState(prev => ({...prev, confirm: null })) }
                        />
                    )}
                    <table className='users-table' style={{width: '100%'}}>
                        <tbody className='users-table'>
                            <tr className='table__row table__row--head user-table-info border-b border-gray-100'>
                                <th className='table__head table__head--checkbox'>
                                    <div className='d7__checkbox-container'>
                                        <input
                                            className='d7__checkbox'
                                            aria-label="checkbox"
                                            type='checkbox'
                                            onChange={event => handleSelectAll( event.target.checked, groups ) }
                                        />
                                        <span className='dash'></span>
                                    </div>
                                </th>
                                <th className='table__head table__head--name'
                                    onClick={() => handleSortBy("name") }>
                                    {Language.getTitleCase('name')} <i className='sort-button'></i>
                                </th>
                                <th className='table__head table__head--count'>
                                    {Language.getTitleCase('user count') + " "}
                                    <i className='sort-button'></i>
                                </th>
                            </tr>
                            {Object.entries(filtered_groups).map(
                                ([key, group]) => (
                                    <tr className='table__row user-table-info border-b border-gray-100'
                                        key={"group_tr_" + key}>
                                        <td className='user-table-select'>
                                            <div className='d7__checkbox-container'>
                                                <input
                                                    type='checkbox'
                                                    aria-label="checkbox"
                                                    onChange={event => handleSelectGroup( event.target.checked, group.uid ) }
                                                    checked={ group.uid in groups_selected }
                                                />
                                                <span className='checkmark'></span>
                                            </div>
                                        </td>
                                        <td className='table__data table__data--text table__data--group-name'
                                            onClick={() => handleShowEdit(group) }>
                                            {group.name}
                                        </td>
                                        <td className='table__data table__data--text table__data--count'
                                            onClick={() => handleViewGroup(group) }>
                                            {group.user_count}
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <Drip7Modal>
                {show_create_group && (
                    <InputModal
                        title={Language.getTitleCase('create group')}
                        placeholder={Language.getTitleCase('group name')}
                        add_button={Language.getTitleCase('create')}
                        showToast={showToast}
                        onClose={handleModalCreateClose}
                    />
                )}
                {show_edit_group && (
                    <GroupModal
                        group={show_edit_group}
                        tenant={tenant}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}
            </Drip7Modal>
        </>
    );
}
