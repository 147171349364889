import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { AuthenticationType, MemberRole } from "../../helpers/consts";
import { ConfirmModal } from "../confirm_modal";
import EmailImg from "../../assets/images/shared/email.svg"
import TrashImage from "../../assets/images/admin/trash.svg"


export const SSOEmailUnregisteredModal = (props) => {
    const { emaillist, onClose, onSend } = props

    const [state, setState] = useState({
        sendlist: Array.from(props.emaillist)
    })
    const { sendlist } = state

    const handleEmailCheck = (e) => {
        let idx = sendlist.indexOf(e);
        if (idx == -1)
            sendlist.push(e);
        else
            sendlist.splice(idx, 1);

        setState(prev => ({...prev, sendlist: sendlist}));
    }

    return (
        <div className='membership-modal' tabIndex={-2}>
            <div className="membership-heading">
                <h3>{Language.getTitleCase('users to email')}</h3>
                <svg
                    className='close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>
            <div className="membership-list">
                {emaillist.map((email, idx) => (
                    <div className="customization-checkbox"
                        id='action_id'
                        key={"sso_tr_" + idx}>
                        <input
                            type='checkbox'
                            aria-label="checkbox"
                            name={email}
                            id={"sso-email-check--" + idx}
                            checked={ sendlist.indexOf( email ) != -1 }
                            onChange={() => handleEmailCheck( email ) }
                        />
                        <span className='checkmark'></span>
                        <label htmlFor={"sso-email-check--" + idx}>
                            {email}
                        </label>
                    </div>
                ))}
            </div>
            <div className='membership-button'>
                <button className='solid-button' onClick={() => {
                            onSend(sendlist);
                            onClose();
                        }
                    }>
                    {Language.getTitleCase('send')}
                </button>
                <button className='solid-button' onClick={onClose}>
                    {Language.getTitleCase('close')}
                </button>
            </div>
        </div>
    )
}