import React, { useState } from "react";

import { AnswerType, ContentType, CurriculumUsage } from "../../helpers/consts";
import { filterCategory, findContent } from "../../helpers/content";
import Language from "../../helpers/language";
import {SelectItems} from "../select_items";
import { CurriculumSpecificWidget } from "./curriculum_specific_widget";
import {Dropdown, DropdownButton} from "react-bootstrap";

export const QuestionFollowUpSingleWidget = props => {
    const { access, follow_up_uids, categories, curriculums, onChange } = props;

    let cur_uids = {}
    curriculums.forEach( x => { cur_uids[x.uid] = true })
    const curriculum_uids = follow_up_uids.filter( x => x in cur_uids )

    //Setup the state
    const [state, setState] = useState({
        show_curriculum: curriculum_uids.length > 0,
    })

    //Setup the curriculum
    const show_curriculum = state.show_curriculum || curriculum_uids.length > 0
    const clean_curriculums = curriculums.filter(x => x.usage == CurriculumUsage.CURRICULUM).map(x => (
        { ...x, name: `${x.name} ( ${x.delivery_count} )` }
    ))

    const showStyle = (show) => {
        return ( !show ) ? { rotate: '-90deg' }: {}
    }

    const toggle = ( name ) => {
        setState(prev => ({...prev,
            [name]: !prev[name]
        }))
    }

    const handleAddQuestion = question => {
        if ( !access.write ) {
            return
        }

        follow_up_uids.push( question.uid )
        onChange( follow_up_uids )
    };

    const handleRemoveQuestion = question => {
        if ( !access.write ) {
            return
        }

        onChange( follow_up_uids.filter( x => x != question.uid ) )
    };

    const handleCurriculumUids = (curriculum_uids) => {
        if ( !access.write ) {
            return
        }

        let fup_uids = follow_up_uids.filter( x => !(x in cur_uids))
        curriculum_uids.forEach( x => fup_uids.push( x ))

        onChange( fup_uids )
    }

    return (
        <>
            <div className='basic-modal__add-question basic-modal__add-question--text'>
                <div className='basic-modal__config'>
                    <label className='basic-modal__label'
                        onClick={() => toggle('show_curriculum')}>
                        {Language.getTitleCase('follow up curriculums')}
                        &nbsp;
                        <i className='drop-chevron' style={ showStyle(show_curriculum) }></i>
                    </label>
                    <div className='central__filters'>
                        {show_curriculum &&
                        <SelectItems
                            title={Language.getTitleCase( "curriculums" )}
                            selected={follow_up_uids}
                            items={clean_curriculums}
                            onChange={handleCurriculumUids}
                        />
                        }
                    </div>
                </div>
            </div>

            <div className='basic-modal__add-question basic-modal__add-question--text'>
                <CurriculumSpecificWidget
                    title={Language.getTitleCase('Follow up questions')}
                    categories={categories}
                    question_uids={follow_up_uids}
                    show_hide={true}
                    onAdd={handleAddQuestion}
                    onRemove={handleRemoveQuestion}
                />
            </div>
        </>
    );
};
