import React from "react";
import { useStore } from "../../store";

import { useMsal } from "@azure/msal-react";

import Language from "../../helpers/language";

const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return (
        <BaseComponent
            {...props}
            usr_info={usr_info}
            fileRef={React.useRef()}
            msal={useMsal()}
        />
    );
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        const { tenant_sso } = this.props.tenant;

        const info = JSON.parse(JSON.stringify(tenant_sso));   //deep copy so we don't change the actual information
        if (!('group_filter' in info))
            info['group_filter'] = {};
        if (!('groups') in info['group_filter'])
            info['group_filter']['groups'] = [];
        if (!('auto_add' in info['group_filter']))
            info['group_filter']['auto_add'] = false;

        this.state = {
            search: "",
            info: info,
            groups: [],
            selected_groups: {},
        };

        this.handleChecked = this.handleChecked.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleAddSelected = this.handleAddSelected.bind(this);
        this.handleRemoveSelected = this.handleRemoveSelected.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this)
        this.handleUnselectAll = this.handleUnselectAll.bind(this)
    }

    filterGroups(search, groups) {
        //Do nothing
        if (search == "") {
            return groups;
        }

        //Only keep
        const search_lc = search.toLowerCase();
        return groups.filter((g) => g.toLowerCase().indexOf(search_lc) >= 0)
    }

    handleChecked(e) {
        const { info } = this.state;

        if (e.target.id == 'auto_add') {
            info.group_filter.auto_add = e.target.checked
        }
        else if (e.target.id == "cache_storeAuthStateInCookie") {
            info.auth_config.cache.storeAuthStateInCookie = e.target.checked;
        }

        this.setState({ info })
    }

    handleChange(e) {
        const { info } = this.state;

        if (e.target.id.startsWith("auth_")) {
            info.auth_config.auth[e.target.id.substr(5)] = e.target.value;
        }
        else if (e.target.id == "cache_cacheLocation") {
            info.auth_config.cache.cacheLocation = e.target.value;
        }

        this.setState({ info })
    }

    handleUpdate() {
        const { onUpdate, onClose } = this.props;
        const { info, groups, selected_groups } = this.state;

        info.group_filter.groups = groups.map((g) => {
            return {
                name: g,
                selected: Object.keys(selected_groups).indexOf(g) >= 0
            }
        })

        onUpdate(info)
        onClose()
    }

    handleAddSelected(group) {
        let { selected_groups } = this.state;
        selected_groups[group] = true

        this.setState({ ...this.state, selected_groups });
    }

    handleRemoveSelected(group) {
        let { selected_groups } = this.state;
        delete selected_groups[group]

        this.setState({ ...this.state, selected_groups });
    }

    handleSelectAll() {
        let { selected_groups, groups } = this.state;
        groups.forEach((g) => selected_groups[g] = true);

        this.setState({ ...this.state, selected_groups });
    }

    handleUnselectAll() {
        this.setState({ ...this.state, selected_groups: {} });
    }

    render() {
        const { onClose } = this.props;
        const { info, search, groups, selected_groups } = this.state;

        const filtered_groups = this.filterGroups(search, groups);

        let selected = {};
        let unselected = {};
        filtered_groups.forEach((g) => {
            if (g in selected_groups) {
                selected[g] = true;
            }
            else {
                unselected[g] = true;
            }
        })

        return (
            <div className='basic-modal basic-modal--add-group'>
                <div className='basic-modal__head'>
                    <h2>{Language.getTitleCase('configure single sign-on')}</h2>
                    <svg
                        className='basic-modal__close-button'
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={onClose}>
                        <path
                            d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                            fill='currentColor'></path>
                    </svg>
                </div>

                <div className='create-group'>
                    <div className="customization-menu customization-fields">
                        <div className="grid-item">
                            <label htmlFor='auth-client-id'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("the application (client) id from active directory")}
                                    </span>
                                {Language.getTitleCase('client id')}&nbsp;
                                <span className='req'>*</span>
                            </span>
                            </label>
                            <input
                                type='text'
                                name='auth-client-id'
                                placeholder={Language.getSentenceCase('Application (client) ID')}
                                required=''
                                onChange={this.handleChange}
                                id='auth_clientId'
                                value={info.auth_config.auth.clientId}
                            />
                        </div>
                        <div className="grid-item">
                            <label htmlFor='auth-authority'>                        
                            <span className='tool-tip__container'>
                            <span className="tool-tip__text">
                                {Language.getSentenceCase("using the tenant id from active directory, the url to execute sso - https://login.microsoftonline.com/<tenantid>")}
                            </span>
                                {Language.getTitleCase('authority')}&nbsp;
                                <span className='req'>*</span>
                            </span> 
                            </label>
                            <input
                                type='text'
                                name='auth-authority'
                                placeholder={Language.getTitleCase('authority')}
                                required=''
                                onChange={this.handleChange}
                                id='auth_authority'
                                value={info.auth_config.auth.authority}
                            />
                        </div>
                        <div className="grid-item">
                            <label htmlFor='auth-client-id'>
                            <span className='tool-tip__container'>
                            <span className="tool-tip__text">
                                {Language.getSentenceCase("once login is successful, where should the ad login send the user - https://<subdomain>.drip7.com")}
                            </span>
                                {Language.getTitleCase('redirect uri')}&nbsp;
                                <span className='req'>*</span>
                            </span>
                            </label>
                            <input
                                type='text'
                                name='auth-redirect-uri'
                                placeholder={Language.getTitleCase('redirecturi')}
                                required=''
                                onChange={this.handleChange}
                                id='auth_redirectUri'
                                value={info.auth_config.auth.redirectUri}
                            />
                        </div>
                        <div className="grid-item">
                            <label htmlFor='auth-cache-location'>
                            <span className='tool-tip__container'>
                            <span className="tool-tip__text">
                                {Language.getSentenceCase("this is generally 'sessionstorage', but is determined by active directory")}
                            </span>
                                {Language.getTitleCase('cache location')}&nbsp;
                                <span className='req'>*</span>
                            </span>
                            </label>
                            <input
                                type='text'
                                name='auth-cache-location'
                                placeholder={Language.getTitleCase('cache location')}
                                required=''
                                onChange={this.handleChange}
                                id='cache_cacheLocation'
                                value={info.auth_config.cache.cacheLocation}
                            />
                        </div>
                        <div className='customization-checkbox'>
                            <input
                                type='checkbox'
                                name='auth-store-cookie'
                                aria-label="checkbox"
                                id='cache_storeAuthStateInCookie'
                                onChange={this.handleChecked}
                                checked={
                                    info.auth_config.cache
                                        .storeAuthStateInCookie
                                }
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='auth-store-cookie'>
                            <span className='tool-tip__container'>
                        {/* <span className="tool-tip__text">
                            {Language.getSentenceCase("__replace")}
                        </span> */}
                                {Language.getTitleCase('store cookie')}
                            </span>
                            </label>
                        </div>
                        {false && 
                            <div className='customization-checkbox' style={{ margin: "0px"}}>
                                <input
                                    type='checkbox'
                                    id='auto_add'
                                    name='auto-add'
                                    onChange={this.handleChecked}
                                    checked={info.group_filter.auto_add}
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='auto-add'>
                                    {Language.getTitleCase('automatically sync new groups')}
                                </label>
                            </div>
                        }
                        </div>
                    {false && 
                    <>
                    <div className='search-bar-container'>
                        <i className='icon search-icon'></i>
                        <input
                            className='search-input'
                            type='search'
                            name='search'
                            placeholder={Language.getSentenceCase('Search by name')}
                            value={search}
                            onChange={e =>
                                this.setState({ search: e.target.value })
                            }
                        />
                    </div>
                    <div className='create-group__users-container'>
                        <div className='create-group__users'>
                            <h3>
                                {Language.getTitleCase('sync groups')}&nbsp;( <a onClick={this.handleUnselectAll} href="#">{Language.getSentenceCase('all')}</a> )
                            </h3>
                            <ul className='create-group__users-list' aria-live='polite'>
                                {Object.keys(selected).map( (grp) => (
                                        <li
                                            className='create-group__users-item'
                                            key={"selected_groups_" + grp}
                                            onClick={() => this.handleRemoveSelected(grp)}
                                            >
                                            {grp}
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>

                        <svg
                            aria-hidden='true'
                            focusable='false'
                            data-prefix='fas'
                            data-icon='exchange-alt'
                            className='svg-inline--fa fa-exchange-alt fa-w-16'
                            role='img'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'>
                            <path
                                fill='currentColor'
                                d='M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z'></path>
                        </svg>

                        <div className='create-group__users'>
                            <h3>{Language.getTitleCase('don\'t sync groups')}&nbsp;( <a onClick={this.handleSelectAll} href="#">{Language.getSentenceCase('all')}</a> )</h3>
                            <ul className='create-group__users-list' aria-live='polite'>
                                {Object.keys(unselected).map( (grp) => (
                                        <li
                                            className='create-group__users-item'
                                            key={"unselected_groups_" + grp}
                                            onClick={() => this.handleAddSelected(grp)}
                                            >
                                            {grp}
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                    </>
                    }
                </div>
                <div className='basic-modal__button-container'>
                    <button
                        className='basic-modal__button basic-modal__button--clear'
                        onClick={onClose}>
                        {Language.getTitleCase('cancel')}
                    </button>
                    <button
                        className='basic-modal__button basic-modal__button--solid'
                        onClick={this.handleUpdate}>
                        {Language.getTitleCase('update')}
                    </button>
                </div>
            </div>
        );
    }
}

export const SSOConfigModal = withStore(Klass);
