import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Language from "../../helpers/language";

export const AnswerTypeInlineInput = (props) => {
    const { name, value, onChanged } = props;

    return (
        <div className='daily-questions__fitb'>
            <input
                type='text'
                name={name}
                value={value}
                className={"daily-questions__fitb-answer"}
                placeholder='Type Answer Here'
                onChange={onChanged}
            />
        </div>
    );
}
