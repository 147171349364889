import Language from "./language";
import {ContentType, Languages, NavigationPages} from "./consts";

export const buildQuestions = (question_categories) => {
    let result = []

    //Unpack the data
    question_categories.forEach(cat => {
        //Go through all the questions
        cat.questions.forEach(question => {
            //Build up the result array with the question, and augmented category data
            result.push({ ...question,
                tenant_name: cat.tenant_name,
                category: cat.name,
                category_uid: cat.uid,
            })
        })
    })

    return result
}

export const findContent = (language, contents) => {
    let update_contents = contents;

    let content = contents.find(x => {
        return x.language.toLowerCase() == language.toLowerCase();
    });
    if (content == null) {
        //Okay so we are going to create, can we copy from english?
        content = { language, content: {}, answer: {}, explanation: {} };

        //Store the new content and return
        update_contents.push(content);
    }

    const placeholder = language.toLowerCase() != Languages.English
        ? contents.find(x => { return x.language.toLowerCase() == Languages.English; })
        : { language, content: {}, answer: {}, explanation: {} };

    return [content, placeholder, update_contents];
}

export const filterQuestions = ( questions, search, category_uid,
                                 content_type, answer_type ) => {
    if (search == null || search == undefined) {
        search = "";
    }
    if (category_uid == null || category_uid == undefined) {
        category_uid = "";
    }
    if (content_type == null || content_type == undefined) {
        content_type = "";
    }
    if (answer_type == null || answer_type == undefined) {
        answer_type = "";
    }

    let result = [];

    const search_lc = search.toLowerCase();
    const content_type_lc = content_type.toLowerCase();
    const answer_type_lc = answer_type.toLowerCase();

    for (let i = 0; i < questions.length; i++) {
        const question = questions[i];

        if ( search != "" &&
             question.name.toLowerCase().indexOf(search_lc) < 0 &&
             question.category.toLowerCase().indexOf(search_lc) < 0 ) {
            continue
        }

        //Drop down filtering
        if (category_uid != "" && question.category_uid != category_uid) {
            continue
        }
        if ( content_type_lc != "" &&
            question.content_type.toLowerCase() != content_type_lc ) {
            continue
        }
        if ( answer_type_lc != "" &&
            question.answer_type.toLowerCase() != answer_type_lc ) {
            continue
        }

        result.push(question)
    }

    return result
};

export const filterCategory = (categories, category_uid, phishing) => {
    if (category_uid == null || category_uid == undefined) {
        category_uid = "";
    }

    //Is there nothing to do? Add it all up and return
    if (category_uid == "" ) {
        let question_count = 0
        for (let c_idx = 0; c_idx < categories.length; c_idx++) {
            const cat = categories[c_idx]
            question_count += (!phishing)? cat.question_count: cat.phish_count
        }

        return {
            cat_title: Language.getTitleCase('categories'),
            question_count
        }
    }

    for (let c_idx = 0; c_idx < categories.length; c_idx++) {
        const cat = categories[c_idx];
        if (cat.uid != category_uid) {
            continue;
        }

        const question_count = (!phishing)? cat.question_count: cat.phish_count
        return {
            cat_title: `${cat.name} (${question_count})`,
            question_count,
        }
    }

    //Error, shouldn't happen
    return {
        cat_title: "Categories",
        question_count: -1
    };
};

export const queueAudience = (queue) => {
    const selected_groups = queue.groups.map( x => x.uid )

    return queueAudienceRaw( queue.user_count, 10000000, queue.groups, selected_groups )
}


export const queueAudienceRaw = (user_count, total_user_count, groups, selected_groups, zero_is_everyone=true) => {
    //Map out array to string
    const group_count = selected_groups.length
    const group_str = groups.filter( g => selected_groups.indexOf(g.uid) >= 0 ).map( x => x.name ).join(', ')

    const is_everyone = (zero_is_everyone && user_count == 0) || (user_count == total_user_count)

    //Based on the combo of attached info, we pull the audience
    if ( is_everyone && group_count <= 0) {
        return Language.getSentenceCase('everyone')
    }
    else if (user_count > 0 && group_count <= 0) {
        return `${user_count} ${Language.getString('user(s)')}`
    }
    else if (user_count <= 0 && group_count > 0) {
        return group_str
    }
    else if (user_count <= 0 && group_count <= 0) {
        return Language.getSentenceCase('Zero users')
    }
    else {
        return `${user_count} ${Language.getString('user(s)')} ${Language.getString('and')} ${group_str}`
    }
}


export const categoryQuestionsToOverview = ( category_questions, category_uids, question_uids ) => {
    let selected_question_categories = {}

    const lookup = {}
    question_uids.forEach(x => {
        lookup[x] = true
    })

    category_questions.forEach(cat => {
        //Skip categorys that are fully selected
        if ( cat.uid in category_uids ) {
            return
        }

        cat.questions.forEach( x => {
            if ( !(x.uid in lookup) ) {
                return
            }

            //Make the category
            if ( !(cat.uid in selected_question_categories) ) {
                let question_count_by_type = {}
                Object.entries(ContentType).forEach(([k,v]) => {
                    question_count_by_type[v.toLowerCase()] = 0
                })

                selected_question_categories[cat.uid] = {
                    access: cat.access,
                    uid: cat.uid,
                    name: cat.name,
                    tenant_name: cat.tenant_name,
                    phish_count: 0,
                    question_count: 0,
                    question_count_by_type,
                }
            }

            //Add this question in
            selected_question_categories[cat.uid].question_count_by_type[x.content_type.toLowerCase()]++
            if ( x.content_type != ContentType.Phish ) {
                selected_question_categories[cat.uid].question_count++
            }
            else {
                selected_question_categories[cat.uid].phish_count++
            }
        })
    })

    return Object.values( selected_question_categories )
}

export const buildNavigation = (navigations) => {
    const defaults = [
        {code: 'DASHBOARD',         name: Language.getTitleCase("Dashboard"), path: '/dashboard'},
        {code: 'TIER',              name: Language.getTitleCase("Tier"), path: '/tier'},
        {code: 'BADGE_STATUS',      name: Language.getTitleCase("Badge Status"), path: '/badge-status'},
        {code: 'QUESTION_ARCHIVE',  name: Language.getTitleCase("question review"), path: '/question-archive'},
        {code: 'PROFILE_SETTINGS',  name: Language.getTitleCase("Profile Settings"), path: '/profile-settings'},
    ]
    if ( navigations == undefined || navigations == null || navigations.length <= 0 ) {
        return defaults
    }

    let lookup = {}
    Object.entries(NavigationPages).forEach(([k,v]) => {
        lookup[v] = k
    })

    //Build out a lookup, using defaults lower than the cap
    let navs = navigations.map( x => x )
    navs.forEach( x => {
        x.code = (x.path in lookup)? lookup[x.path]: 'CUSTOM'
    })

    while ( navs.length < 2 ) {
        navs.push( defaults[navs.length] )
    }

    return navs
}

export const setTenantColors = (tenant) => {
    //Set primary and secondary color CSS variables
    const primary_color = (tenant && tenant.primary_color)
        ? tenant.primary_color
        : "#41C8FF";
    const secondary_color = (tenant && tenant.secondary_color)
        ? tenant.secondary_color
        : "#fa5c3c"

    document.documentElement.style.setProperty(
        "--drip7-primary-color",
        primary_color
    );
    //Handle the various opacities for the primary color
    const primary_opacity = [{key:'-05', val:'0D'},
        {key:'-075',val:'13'},
        {key:'-20',val:'31'},
        {key:'-25',val:'40'},
        {key:'-50',val:'80'}
    ]
    primary_opacity.forEach((o) => {
        document.documentElement.style.setProperty(
            "--drip7-primary-color"+o.key,
            primary_color + o.val
        );
    })
    document.documentElement.style.setProperty(
        "--drip7-secondary-color",
        secondary_color
    );
}


export const letterGrade = (score) => {
    let sign = ' '
    if ( score % 10 >= 7 || score >= 100) {
        sign = "+"
    }
    else if ( score % 10 <= 3 ) {
        sign = "-"
    }

    // Return the result!
    if ( score >= 90 ) {
        return `A${sign}`
    }
    else if ( score >= 80 ) {
        return `B${sign}`
    }
    else if ( score >= 70 ) {
        return `C${sign}`
    }
    else if ( score >= 60 ) {
        return `D${sign}`
    }
    else {
        return `F `
    }
}
