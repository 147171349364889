import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { SketchPicker } from "react-color";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import radialDiagram from "../../assets/images/admin/phone-example/radial-diagram.svg"
import { Drip7Button } from "../../components/drip7_button";

//State wrapper
const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    //File ref required to access the file browser, one for each logo
    return (
        <BaseComponent
            {...props}
            usr_info={usr_info}
            fileRefs={[
                React.useRef(),
                React.useRef(),
                React.useRef(),
                React.useRef()
            ]}
        />
    );
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            info: props.initial_info,
            show_primary: false,
            show_secondary: false
        };

        this.logos = {
            full_logo: {
                label: Language.getTitleCase('login page logo'),
                img: this.state.info.full_logo
            },
            compact_logo: {
                label: Language.getTitleCase('user header logo'),
                img: this.state.info.compact_logo
            },
            alternate_logo: {
                label: Language.getTitleCase('admin header logo'),
                img: this.state.info.alternate_logo
            },
            favicon: {
                label: Language.getTitleCase('favicon'),
                img: this.state.info.favicon
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleConfirmColor = this.handleConfirmColor.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleShowColor = this.handleShowColor.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //If this is the same id, and the reload hasn't been changed, we don't want to change anything
        if (
            this.state.info.uid == this.props.initial_info.uid &&
            this.props.reload == prevProps.reload
        ) {
            return;
        }

        //Setup my state info based on props
        const changed = this.props.initial_info.uid == "create_tenant";
        this.setState({ changed, info: this.props.initial_info });
    }

    //We have a selected file
    handleFileChange(e) {
        const raw_file = e.target.files[0];
        if (raw_file) {
            const info = {
                ...this.state.info,
                [e.target.id + "_rawfile"]: raw_file,
                [e.target.id + "_filename"]: raw_file.name
            };
            this.props.onChanged(info);
            this.setState({ info });
        }
    }

    handleChange() {
        //Store the value
        const info = { ...this.state.info };
        this.props.onChanged(info);
    }

    handleShowColor(e) {
        this.setState({ ...this.state, [e.target.id]: !this.state[e.target.id] });
    }

    handleConfirmColor(e) {
        this.setState({ ...this.state, [e.target.id]: false });
        this.handleChange();
    }

    handleColorChange(key, color) {
        const info = { ...this.state.info, [key]: color.hex };
        this.setState({ ...this.state, info: info });

        //Set primary and secondary color CSS variables
        document.documentElement.style.setProperty(
            "--drip7-primary-color",
            info.primary_color
        );
        document.documentElement.style.setProperty(
            "--drip7-secondary-color",
            info.secondary_color
        );
    }

    render() {
        //Funky pass through to open the file browse
        const handleFileClick = i => {
            this.props.fileRefs[i].current.click();
        };

        let iref = 0;
        const { info, show_primary, show_secondary } = this.state;
        const logo_ui = Object.keys(this.logos).map(k => {
            const filename = info[k + "_filename"];
            const blob = info[k + "_rawfile"]
                ? new Blob([info[k + "_rawfile"]], { type: "image/*" })
                : null;
            const blobUrl = blob ? URL.createObjectURL(blob) : null;
            const i = iref;
            iref = iref + 1;
            return (
                <div key={k} className='image-uploader'>
                    <label htmlFor={k}>
                        {this.logos[k].label}
                        <div className="tooltip-container">
                            <i className='help'></i>
                            <span className="tooltip-container__text">This is placeholder text.</span>
                        </div>
                    </label>
                    <div
                        className='image-preview'
                        onClick={() => handleFileClick(i)}>
                        <div className='image-placeholder'>
                            <img
                                alt='company logo'
                                src={
                                    blobUrl ||
                                    this.logos[k].img ||
                                    "/static/images/admin/image-placeholder.svg"
                                }
                            />
                            {!(
                                this.logos[k].img ||
                                this.logos[k].img.length > 0
                            ) && <p>{Language.getTitleCase('No File Selected')}</p>}
                        </div>
                    </div>
                    <input
                        type='file'
                        id={k}
                        accept='image/*'
                        ref={node => (this.props.fileRefs[i].current = node)}
                        onChange={this.handleFileChange}
                        style={{ display: "none" }}
                    />
                    <input
                        className='basic-modal__input'
                        type='text'
                        readOnly
                        style={{ width: "100%",display:'none' }}
                        name='user-file'
                        placeholder={Language.getTitleCase('No File Selected')}
                        value={filename}
                    />
                    <label>
                    .png or .jpg required
                    </label>
                    <Drip7Button
                        onClick={() => handleFileClick(i)}
                        className="mb-4"
                        name={k}
                        aria-label={`Choose File for ${this.logos[k].label}`}>
                        {Language.getTitleCase('Choose File')}
                    </Drip7Button>
                </div>
            );
        });

        const blobPrimary = info["full_logo_rawfile"]
            ? new Blob([info["full_logo_rawfile"]], { type: "image/*" })
            : null;
        const blobPrimaryUrl = blobPrimary
            ? URL.createObjectURL(blobPrimary)
            : null;
        const blobCompact = info["compact_logo_rawfile"]
            ? new Blob([info["compact_logo_rawfile"]], { type: "image/*" })
            : null;
        const blobCompactUrl = blobCompact
            ? URL.createObjectURL(blobCompact)
            : null;

        return (
            <div className='design-panes'
                 role='tabpanel'
                 id='tab_panel_design_settings'>
                <div className='design-settings'>
                    <div className='color-selectors'>
                        <label htmlFor='primary-color'>
                            {Language.getTitleCase('primary color')}
                            <div className="tooltip-container">
                                <i className='help'></i>
                                <span className="tooltip-container__text">This is placeholder text.</span>
                            </div>
                        </label>
                        <Drip7Button
                            mode='dropdown'
                            idBtn='show_primary'
                            nameBtn='primary color'
                            onClick={this.handleShowColor}>
                            <div className='color-value'>
                                <i
                                    className='swatch'
                                    style={{
                                        background: info.primary_color
                                    }}></i>
                                <span style={{color:'black'}}>{Language.getTitleCase('color')}</span>
                            </div>
                            <i className='drop-triangle'></i>
                        </Drip7Button>
                        {show_primary && (
                            <SketchPicker
                                color={info.primary_color}
                                onChange={e =>
                                    this.handleColorChange("primary_color", e)
                                }
                                onChangeComplete={e =>
                                    this.handleColorChange("primary_color", e)
                                }
                            />
                        )}
                        {show_primary && (
                            <Drip7Button
                                idBtn='show_primary'
                                onClick={this.handleConfirmColor}>
                                {Language.getTitleCase('confirm')}
                            </Drip7Button>
                        )}

                        <label htmlFor='secondary-color'>
                            {Language.getTitleCase('secondary color')}
                            <div className="tooltip-container">
                                <i className='help'></i>
                                <span className="tooltip-container__text">This is placeholder text.</span>
                            </div>
                        </label>
                        <Drip7Button
                            mode='dropdown'
                            idBtn='show_secondary'
                            name='secondary-color'
                            onClick={this.handleShowColor}>
                            <div className='color-value'>
                                <i
                                    className='swatch'
                                    style={{
                                        background: info.secondary_color
                                    }}></i>
                                <span style={{color:'black'}}>{Language.getTitleCase('color')}</span>
                            </div>
                            <i className='drop-triangle'></i>
                        </Drip7Button>
                        {show_secondary && (
                            <SketchPicker
                                color={info.secondary_color}
                                onChange={e =>
                                    this.handleColorChange("secondary_color", e)
                                }
                                onChangeComplete={e =>
                                    this.handleColorChange("secondary_color", e)
                                }
                            />
                        )}
                        {show_secondary && (
                            <Drip7Button
                                idBtn='show_secondary'
                                onClick={this.handleConfirmColor}>
                                {Language.getTitleCase('confirm')}
                            </Drip7Button>
                        )}
                    </div>

                    <div className='image-uploads'>{logo_ui}</div>
                </div>

                <div className='design-preview'>
                    <div className='phone-preview-container'>
                        <div className='phone-preview'>
                            <div
                                style={{
                                    borderRadius: "17px",
                                    width: "88%",
                                    height: "95%",
                                    backgroundColor: info.primary_color
                                }}></div>
                        </div>
                        <div
                            className='logo'
                            style={{
                                content:
                                    "url(" +
                                    (blobPrimaryUrl || info.full_logo) +
                                    ")"
                            }}></div>
                    </div>

                    <div className='phone-preview-container'>
                        <div className='phone-preview'>
                            <div
                                style={{
                                    borderRadius: "17px",
                                    width: "88%",
                                    height: "95%",
                                    backgroundColor: info.secondary_color
                                }}></div>
                        </div>
                        <div className='example-ui'>
                            <img
                                alt='example ui'
                                className='topbar'
                                src={blobCompactUrl || info.compact_logo}
                            />
                            <div className='example-main'>
                                <div className='example-ui-user'>
                                    <div className='example-user-photo'></div>
                                    <h5
                                        className='example-user-name'
                                        style={{ color: info.secondary_color }}>
                                        Jennifer Kinley
                                    </h5>
                                    <h6 className='example-user-dept'>
                                        {Language.getTitleCase('sales department')}
                                    </h6>
                                </div>
                                <img
                                    alt='example diagram'
                                    className='example-diagram'
                                    src={radialDiagram}
                                />
                                <div className='example-legend'>
                                    <ul>
                                        <li className='leg-psw'>
                                            <span>
                                                <div className='legend-key'></div>
                                                40%
                                            </span>{" "}
                                            - {Language.getTitleCase('password')}
                                        </li>
                                        <li className='leg-core'>
                                            <span>
                                                <div className='legend-key'></div>
                                                40%
                                            </span>{" "}
                                            - {Language.getTitleCase('cyber core')}
                                        </li>
                                        <li className='leg-phish'>
                                            <span>
                                                <div className='legend-key'></div>
                                                20%
                                            </span>{" "}
                                            - {Language.getTitleCase('phishing')}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const TenantCustomizeTab = withStore(Klass);
