import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../store";
import Util from "../helpers/util";

import { AnswerType, ContentType } from "../helpers/consts";
import Chart from "react-apexcharts";
import Language from "../helpers/language";

export const QuestionActivityCard = props => {
    const { cascade, tenant_uid } = props;

    const chart = useRef(null)
    const [state, setState] = useState({
        local_tenant_uid: '',
        local_cascade: null,
    });
    const { local_tenant_uid, local_cascade } = state

    const loadGraph = (series, labels) => {
        if ( chart.current != null ) {
            chart.current.destroy()
        }

        const options = {
            series,
            labels,

            chart: {
                height: 320,
                stacked: true,
                type: "line",
                zoom: {
                    enabled: false
                },
                toolbar: {
                    offsetY: -24,
                    tools: {
                        download: '<svg fill="#000000" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 212.973 212.973" xml:space="preserve"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g> <g> <path d="M205.473,76.146c-4.143,0-7.5,3.358-7.5,7.5v103.32H15V83.646c0-4.142-3.357-7.5-7.5-7.5S0,79.503,0,83.646v110.82 c0,4.142,3.357,7.5,7.5,7.5h197.973c4.143,0,7.5-3.358,7.5-7.5V83.646C212.973,79.503,209.615,76.146,205.473,76.146z"></path> <path d="M101.171,154.746c1.407,1.407,3.314,2.197,5.304,2.197c1.989,0,3.896-0.79,5.304-2.197l32.373-32.374 c2.929-2.929,2.929-7.678,0-10.606c-2.93-2.93-7.678-2.929-10.607,0l-19.569,19.569l0.004-112.828c0-4.142-3.357-7.5-7.5-7.5 c-4.142,0-7.5,3.358-7.5,7.5l-0.004,112.829l-19.546-19.547c-2.929-2.929-7.677-2.93-10.607,0c-2.929,2.929-2.929,7.677,0,10.606 L101.171,154.746z"></path> </g> </g></svg>'
                      },
                    show: false, // Hide the toolbar
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1, 1]
            },
            colors: [ "#87DCAA", "#FD6E6E", "#1482E6", ],
            fill: {
                colors: [ "#87DCAA", "#FD6E6E", "#1482E6", ],
            },

            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    max: 100,
                    opposite: true,
                    title: {
                        text: Language.getSentenceCase('question') + " %"
                    }
                },
                {
                    show: false,
                    max: 100
                },
                {
                    show: false,
                    max: 100
                }
            ],
            tooltip: {
                y: {
                    formatter: function(y) {
                        return y + '%'
                    }
                }
            }
        };

        chart.current = new ApexCharts(
            document.querySelector("#question-activity_chart"),
            options
        );
        chart.current.render();
    }

    useEffect(() => {
        //Bail
        if (tenant_uid == "" || (tenant_uid == local_tenant_uid && cascade == local_cascade)) {
            return;
        }

        //Get a count of tenant members
        Util.fetch_js("/dashboard/usage_span/", { tenant_uid, cascade },
            js => {
                let labels = [];
                const series = [
                    {
                        name: Language.getTitleCase('correct'),
                        type: "area",
                        data: []
                    },
                    {
                        name: Language.getTitleCase('incorrect'),
                        type: "area",
                        data: []
                    },
                    {
                        name: Language.getTitleCase('unanswered'),
                        type: "area",
                        data: []
                    }
                ]

                //Load in the activity
                for (let i = 0; i < js.activity.length; i++) {
                    const act = js.activity[i];
                    const total =
                        parseInt(act.correct) +
                        parseInt(act.incorrect) +
                        parseInt(act.unanswered);

                    //Convert from 0 to 100
                    let [cor, inc, una] = [0, 0, 0];
                    if (total > 0) {
                        cor = parseInt( Math.round((parseInt(act.correct) * 100) / total))
                        inc = parseInt( Math.round((parseInt(act.incorrect) * 100) / total))
                        una = 100 - (cor + inc) // Ensure we always end in whole numbers == 100
                    }

                    //Load in the data, stack it manually
                    series[0].data.push(cor);
                    series[1].data.push(inc);
                    series[2].data.push(una);
                    labels.push( Util.friendlyDate(Util.dayToDate(act.timestamp_day)) );
                }

                loadGraph( series, labels )
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        //Force the tenant uid state update to prevent double loads
        setState(prev => ({...prev,
            local_tenant_uid: tenant_uid,
            local_cascade: cascade,
        }))
    }, [props]);

    //destroy card on unmounting - removes console errors
    useEffect(() => {
        return () => {
            if ( chart.current != null ) {
                chart.current.destroy()
            }
        }
    }, [])

    return (
        <div
            className='admin-card admin-card__large'
            style={{ marginLeft: "0px" }}>
            <h3>{Language.getTitleCase('Question Activity')}</h3>
            <div id='question-activity_chart'></div>
        </div>
    );
};
