import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Language from "../../helpers/language";
import Util from "../../helpers/util";

export const AnswerTypeFill = React.forwardRef((props, ref) => {
    const { answer, value, readOnly, onChanged } = props;
    const [state, setState] = useState({
        user_answer: Util.xstr(value),
        submitted: (value != null && value != undefined),
    })
    const {user_answer, submitted} = state

    let answer_display = ""
    if ( 'correct' in answer ) {
        answer_display = typeof answer.correct == "object" ? answer.correct[0] : answer.correct;
    }

    const cleanAnswer = (answer) => {
        if (answer === null || answer === undefined) {
            return ""
        }
        if ( answer === -1 ) {
            return ""
        }

        return Util.xstr(answer)
            .toLowerCase()
            .replace(/^\s+/, "")
            .replace(/\s+$/, "");
    }

    React.useImperativeHandle(ref, () => ({
        reset: () => {
            setState(prev => ({...prev,
                user_answer: "",
                submitted: false,
            }))
        },
        attemptAnswer: () => {
            if (user_answer == "") {
                return false;
            }

            handleSubmit( user_answer )
            return true;
        }
    }));

    const isCorrect = (user_answer) => {
        const ualc = user_answer.toLowerCase();

        //Is this answer now correct?
        if ( !('correct' in answer) || answer.correct == null || answer.correct == undefined ) {
            return true
        }
        else if (Array.isArray( answer.correct )) {
            for (let i = 0; i < answer.correct.length; i++) {
                const ans = cleanAnswer( answer.correct[i] )
                if ( ans === '' || ualc.search(ans) >= 0 ) {
                    return true
                }
            }
        }
        else {
            const ans = cleanAnswer( answer.correct )
            return ans === '' || ualc.search(ans) >= 0;
        }

        return false
    }

    const handleKeypress = e => {
        // Enter pressed? login
        if ( e.charCode == 13 ) {
            onChanged( user_answer, isCorrect( user_answer ), answer_display );
        }
    };

    const handleChange = e => {
        setState(prev => ({ ...prev,
            user_answer: e.target.value
        }))
    }

    const handleSubmit = ( user_answer ) => {
        onChanged( user_answer, isCorrect( user_answer ), answer_display );
        setState(prev => ({...prev,
            submitted: true,
        }))
    }

    const klass = () => {
        if (!submitted) {
            return ""
        }

        const mod = isCorrect(user_answer) ? "correct": "incorrect";
        return ` daily-questions__fitb-answer--${mod}`
    };

    return (
        <>
            <div className='daily-questions__fitb'>
                <input
                    type='text'
                    className={"daily-questions__fitb-answer" + klass()}
                    placeholder='Type Answer Here'
                    disabled={submitted || readOnly}
                    onChange={handleChange}
                    onKeyPress={handleKeypress}
                    autoFocus={true}
                    value={user_answer}
                />
            </div>
            {submitted && !isCorrect(user_answer) && (
                <div className='daily-questions__fitb-answer--correct'>
                    <i>{Language.getSentenceCase('correct answer')}: {answer_display}</i>
                </div>
            )}
        </>
    );
});
