import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../store";
import { useParams } from "react-router-dom";
import { Badge } from "../../components/badge";
import { BadgePopup } from "../../components/user/badge_popup";
import { Slider } from "../../components/user/v3_slider";
import Util from "../../helpers/util";
import { AvatarCard } from "../../components/user/v3_avatar";
import { WeeklySchedule } from "../../components/user/v3_weekly_schedule";
import { NextTraining } from "../../components/user/v3_next_training";
import { PreviousTraining } from "../../components/user/v3_previous_training";
import { PreviousTrainingDesktop } from "../../components/user/v3_previous_training_desktop";
import userLogo from "../../assets/images/user-portal/user_logo.png";
import { useNavigate } from "react-router-dom";
import Language from "../../helpers/language";

export const V3Dashboard = (props) => {
    const { usr_info } = useStore((x) => x);

    const { onPathChange, showToast } = props;

    const [state, setState] = useState({
        loaded: false,
        questions: [],
        question_count: 0,
        question_time: 0,
        curriculums: [],
        prev_questions: [],
    })
    const { loaded, question_count, question_time, curriculums, prev_questions } = state;

    const [selected_idx, setSelectedIdx] = useState(-1);

    const navigate = useRef(useNavigate());

    useEffect(() => {
        onPathChange("dashboard");

        //Check if there are new questions
        Util.fetch_js( "/client/dashboard/overview/", {},
            (js) => {
                const { question_count, question_time, curriculums, prev_questions } = js

                setState((prev) => ({ ...prev,
                    loaded: true,
                    question_count,
                    question_time,
                    curriculums,
                    prev_questions,
                }));
            },
            (reason, code) => {
                navigate.current("/login");
                showToast(reason, "failure");
            }
        );
    }, []);

    return (
        <div className="userdash-container">
            <Slider items={curriculums}/>

            <div className="userdash-cards">
                <AvatarCard
                    name={usr_info.nickname || usr_info.name}
                    tenant={usr_info.tenant?.name}
                    tier={usr_info.tier}
                    img={usr_info.profile_url}
                />
                <WeeklySchedule
                    questions={prev_questions}
                    selected_idx={selected_idx}
                />
                <div className="lesson-container">

                    <div className="lesson-block">
                        <PreviousTrainingDesktop
                            questions={prev_questions}
                            language={usr_info.language}
                            onIndex={setSelectedIdx}
                        />
                        <WeeklySchedule
                            questions={prev_questions}
                            selected_idx={selected_idx}
                        />

                    </div>
                    <div className="lesson-block">
                        <NextTraining
                            question={prev_questions.length > 0 ? prev_questions[0]: {}}
                            duration={question_time}
                            questionCount={question_count}
                        />
                    </div>
                </div>
                <PreviousTraining
                    questions={prev_questions}
                    language={usr_info.language}
                    onIndex={setSelectedIdx}
                />
            </div>
        </div>
    );
};
