import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { GroupModal } from "../../modals/admin/group_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import { InputModal } from "../../modals/input_modal";
import { ManualPointModal } from "../../modals/admin/manual_point_modal";

export const ManualPoints = (props) => {
    const { tenant_uid, tenants, showToast, onPathChange } = props;

    const [state, setState] = useState({
        manual_points_selected: {},
        manual_points: [],
        show_modal: false,
        confirm: null,
        search: "",
        sort_by: "",
        sort_by_reverse: false,
        local_tenant_uid: "",
    });
    const {
        manual_points_selected,
        manual_points,
        show_modal,
        confirm,
        search,
        sort_by,
        sort_by_reverse,
        local_tenant_uid,
    } = state;

    useEffect(() => {
        onPathChange("manual-points");
    }, []);

    useEffect(() => {
        if (local_tenant_uid != tenant_uid) {
            handleUpdate();
            setState((prev) => ({ ...prev, local_tenant_uid: tenant_uid }));
        }
    }, [props]);

    const filterManualPoints = (search, mps) => {
        //Do nothing
        if (search == "") {
            return mps;
        }

        //Only keep
        const search_lc = search.toLowerCase();
        console.log("mps", mps);
        return mps.filter(
            (mp) => mp.user.name.toLowerCase().indexOf(search_lc) >= 0
        );
    };

    const handleUpdate = () => {
        //Get a list of all groups for tenant
        Util.fetch_js(
            "/manual_points/list/",
            { tenant_uid },
            (js) => {
                setState((prev) => ({
                    ...prev,
                    manual_points: js.manual_points,
                    manual_points_selected: {},
                }));
            },
            (reason, code) => {
                showToast(reason, "failure");
            }
        );
    };

    const handleSortBy = (code) => {
        const sbr = sort_by == code ? !sort_by_reverse : false;

        setState((prev) => ({ ...prev, sort_by: code, sort_by_reverse: sbr }));
    };

    const handleChange = (e) => {
        setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const handleRemovePoints = () => {
        const manual_point_uids = Object.keys(manual_points_selected);

        Util.fetch_js(
            "/manual_points/remove/",
            { manual_point_uids },
            (js) => {
                handleUpdate();
                setState((prev) => ({ ...prev, manual_points_selected: {} }));
            },
            (reason, code) => {
                showToast(reason, "failure");
            }
        );

        setState((prev) => ({ ...prev, confirm: null }));
    };

    const handleModalClose = () => {
        handleUpdate();
        setState((prev) => ({ ...prev, show_modal: false }));
    };

    const handleModalShow = (mp) => {
        if (!("uid" in mp)) {
            mp = true;
        }
        setState((prev) => ({ ...prev, show_modal: mp }));
    };

    const handleSelect = (checked, uid) => {
        if (checked) {
            manual_points_selected[uid] = true;
        } else {
            delete manual_points_selected[uid];
        }

        setState((prev) => ({ ...prev, manual_points_selected }));
    };

    const handleSelectAll = (checked, manual_points) => {
        const mps = filterManualPoints(search, manual_points);
        let manual_points_selected = {};

        //Should we check or send empty list?
        if (checked) {
            mps.forEach((x) => {
                manual_points_selected[x.uid] = true;
            });
        }

        setState((prev) => ({ ...prev, manual_points_selected }));
    };

    const selected_count = Object.keys(manual_points_selected).length;

    let filtered_manual_points = filterManualPoints(search, manual_points);

    // Sort?

    if (sort_by != "") {
        console.log("sb", sort_by);
        console.log("sbr", sort_by_reverse);
        console.log("fmp", filtered_manual_points);
        if (!sort_by_reverse) {
            filtered_manual_points.sort((a, b) => {
                if (sort_by == "creator") {
                    return a.user.name
                        .toLowerCase()
                        .localeCompare(b.user.name.toLowerCase());
                } else {
                    if(typeof a[sort_by] == 'number'){
                        return a[sort_by]-b[sort_by]
                    }else{
                    return a[sort_by]
                        .toLowerCase()
                        .localeCompare(b[sort_by].toLowerCase());
                }}
            });
        } else {
            filtered_manual_points.sort((a, b) => {
                if (sort_by == "creator") {
                    return b.user.name
                        .toLowerCase()
                        .localeCompare(a.user.name.toLowerCase());
                } else {
                    if(typeof a[sort_by] == 'number'){
                        return b[sort_by]-a[sort_by]
                    }else{
                    return b[sort_by]
                        .toLowerCase()
                        .localeCompare(a[sort_by].toLowerCase());
                }}
            });
        }
    }

    const confirmAction = (action) => {
        let yes = null;
        let msg = null;
        if (action == handleRemovePoints) {
            yes = Language.getTitleCase("remove");
            msg = Language.getTitleCase("remove assignments") + "?";
        } else {
            throw (
                Language.getSentenceCase("Program error, Unknown action") +
                ": " +
                action
            );
        }

        //Setting a confirm action, will load message box, and if accepted saves
        const new_confirm = {
            action,
            msg,
            yes,
            no: Language.getTitleCase("cancel"),
        };
        setState((prev) => ({ ...prev, confirm: new_confirm }));
    };

    const tenant = tenants.find((x) => x.uid == tenant_uid);

    return (
        <div className="groups-page main-modal">
            <nav className="central__nav">
                <ul className="central__nav-list">
                    <li className="central__nav-item central__nav-item--small central__nav-item--selected">
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="var(--drip7-primary-color)"
                                d="M15.4707 9.3125H8.52734L13.1562 13.9707C13.332 14.1465 13.625 14.1465 13.8008 13.9707C14.9434 12.916 15.7344 11.4805 15.9395 9.83984C15.998 9.57617 15.7637 9.3125 15.4707 9.3125ZM15.002 7.4375C14.7676 3.92188 11.9844 1.13867 8.46875 0.904297C8.20508 0.875 8 1.10938 8 1.37305V7.90625H14.5332C14.7969 7.90625 15.0312 7.70117 15.002 7.4375ZM6.59375 9.3125V2.36914C6.59375 2.10547 6.33008 1.87109 6.06641 1.90039C2.55078 2.39844 -0.115234 5.44531 0.03125 9.10742C0.148438 12.8574 3.37109 15.9336 7.15039 15.875C8.61523 15.875 9.99219 15.4062 11.1055 14.5859C11.3398 14.4395 11.3398 14.0879 11.1348 13.8828L6.59375 9.3125Z"
                            />
                        </svg>
                        {Language.getTitleCase("points")}
                    </li>
                </ul>
            </nav>

            <div className="central__main">
                <div className="central__head">
                    <div className="central__head-search-wrapper">
                        <svg
                            width="15"
                            height="16"
                            viewBox="0 0 15 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z"
                                fill="currentColor"
                            />
                        </svg>
                        <input
                            type="text"
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={ Language.getSentenceCase("Search by name") + "..." }
                            name="search"
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="header-buttons">
                        {selected_count > 0 && (
                            <button
                                type="button"
                                style={{ marginRight: "16px" }}
                                className="central__head-button central__head-button--remove"
                                name="remove-user"
                                onClick={() =>
                                    confirmAction(handleRemovePoints)
                                }
                            >
                                {selected_count != 1
                                    ? Language.getTitleCase(
                                          "remove assignments"
                                      )
                                    : Language.getTitleCase(
                                          "remove assignment"
                                      )}
                            </button>
                        )}

                        <button
                            type="button"
                            className="solid-button central__head-button central__head-button--solid central__head-button--add"
                            name="add-user"
                            onClick={handleModalShow}
                        >
                            {Language.getTitleCase("assign points")}
                        </button>
                    </div>
                </div>

                <div className="table__container">
                    {confirm != null && (
                        <ConfirmModal
                            msg={confirm.msg}
                            yesTxt={confirm.yes}
                            noTxt={confirm.no}
                            onYes={() => confirm.action(true)}
                            onNo={() =>
                                setState((prev) => ({ ...prev, confirm: null }))
                            }
                        />
                    )}
                    <table className="table table--groups">
                        <tbody className="table table--groups">
                            <tr className="table__row table__row--head">
                                <th className="table__head table__head--checkbox">
                                    <div className="d7__checkbox-container" style={{borderBottomWidth: '0px'}}>
                                        <input
                                            className="d7__checkbox"
                                            aria-label="checkbox"
                                            type="checkbox"
                                            onChange={(event) =>
                                                handleSelectAll(
                                                    event.target.checked,
                                                    manual_points
                                                )
                                            }
                                        />
                                        <span className="dash"></span>
                                    </div>
                                </th>
                                <th
                                    className="table__head table__head--name"
                                    onClick={() => handleSortBy("creator")}
                                >
                                    {Language.getTitleCase("creator")}
                                    <i className='sort-button' style={{borderBottomWidth: '0px'}}></i>
                                </th>
                                <th
                                    className="table__head table__head--count"
                                    onClick={() => handleSortBy("action")}
                                >
                                    {Language.getTitleCase("action") + " "}
                                    <i className='sort-button' style={{borderBottomWidth: '0px'}}></i>
                                </th>
                                <th
                                    className="table__head table__head--count"
                                    onClick={() => handleSortBy("points")}
                                >
                                    {Language.getTitleCase("points") + " "}
                                    <i className='sort-button' style={{borderBottomWidth: '0px'}}></i>
                                </th>
                                <th
                                    className="table__head table__head--count"
                                    onClick={() => handleSortBy("user_count")}
                                >
                                    {Language.getTitleCase("user count") + " "}
                                    <i className='sort-button' style={{borderBottomWidth: '0px'}}></i>
                                </th>
                                <th
                                    className="table__head table__head--count"
                                    onClick={() => handleSortBy("assigned_on")}
                                >
                                    {Language.getTitleCase("assigned on") + " "}
                                    <i className='sort-button' style={{borderBottomWidth: '0px'}}></i>
                                </th>
                            </tr>
                            {Object.entries(filtered_manual_points).map(
                                ([key, mp]) => (
                                    <tr
                                        className="table__row"
                                        key={"group_tr_" + key}
                                    >
                                        <td className="user-table-select">
                                            <div className="d7__checkbox-container" style={{borderBottomWidth: '0px'}}>
                                                <input
                                                    type="checkbox"
                                                    aria-label="checkbox"
                                                    onChange={(event) =>
                                                        handleSelect(
                                                            event.target
                                                                .checked,
                                                            mp.uid
                                                        )
                                                    }
                                                    checked={
                                                        mp.uid in
                                                        manual_points_selected
                                                    }
                                                />
                                                <span className="checkmark"></span>
                                            </div>
                                        </td>
                                        <td
                                            className="table__data table__data--text table__data--group-name"
                                            onClick={() => handleModalShow(mp)}
                                        >
                                            {mp.user.name}
                                        </td>
                                        <td
                                            className="table__data table__data--text table__data--group-name"
                                            onClick={() => handleModalShow(mp)}
                                        >
                                            {mp.action}
                                        </td>
                                        <td
                                            className="table__data table__data--text table__data--group-name"
                                            onClick={() => handleModalShow(mp)}
                                        >
                                            {mp.points}
                                        </td>
                                        <td
                                            className="table__data table__data--text table__data--count"
                                            onClick={() => handleModalShow(mp)}
                                        >
                                            {mp.user_count}
                                        </td>
                                        <td
                                            className="table__data table__data--text table__data--count"
                                            onClick={() => handleModalShow(mp)}
                                        >
                                            {Util.friendlyDate(
                                                new Date(mp.assigned_on)
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <Drip7Modal>
                {show_modal && (
                    <ManualPointModal
                        manual_point={show_modal}
                        tenant_uid={tenant_uid}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}
            </Drip7Modal>
        </div>
    );
};
