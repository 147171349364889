import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";

export const AnswerTypeCheckbox = React.forwardRef((props, ref) => {
    const { answer, value, readOnly, onChanged } = props;

    const value_lookup = {}
    if ( Array.isArray(value) ) {
        value.forEach( x => { value_lookup[x] = true })
    }

    const safe_checkboxes = ('checkboxes' in answer && Array.isArray(answer.checkboxes))? answer.checkboxes: []
    const [state, setState] = useState({
        selected_answers: Object.keys(safe_checkboxes).map( k => k in value_lookup ),
    })
    const { selected_answers } = state

    React.useImperativeHandle(ref, () => ({
        reset: () => {
            setState(prev => ({ ...prev,
                selected_answers: Object.keys(safe_checkboxes).map( k => k in value_lookup ),
            }))
        },
        attemptAnswer: () => {
            if ( readOnly ) {
                return false
            }

            const user_answer = selected_answers.filter( x => x ).length
            const indexes = Object.entries(selected_answers).filter(([k,v]) => v).map(([k,v]) => parseInt(k))
            let bin = null

            if ( 'answers' in props.answer ) {
                Object.entries(props.answer.answers).forEach( ([k, v]) => {
                    if ( bin == null && user_answer >= v[0] && user_answer <= v[1] ) {
                        bin = k
                    }
                })
            }
            const is_correct = (parseInt(answer.correct) == -1) || (bin != null && bin == parseInt(answer.correct))
            //console.log( indexes )
            //console.log( is_correct )
            //console.log( user_answer )

            onChanged( indexes, is_correct, user_answer)
            return true
        }
    }));

    const handleCheck = (idx, checked) => {
        if ( readOnly ) {
            return
        }

        let {selected_answers} = state
        selected_answers[idx] = checked

        setState(prev => ({...prev,
            selected_answers
        }))
    }

    return (
        <div className='daily-questions__answers daily-questions__answers--checkbox'>
            {Object.entries(safe_checkboxes).map(([key, value]) => (
            <div className='checkbox__container customization-checkbox' key={"multi_key_" + key}>
                <div>
                    <input
                        type='checkbox'
                        aria-label="checkbox"
                        className='checkbox'
                        checked={selected_answers[key]}
                        onChange={() => handleCheck(key, !selected_answers[key])}/>
                    <span className='checkmark'></span>
                </div>
                <label className='checkbox__label'
                       onClick={() => handleCheck(key, !selected_answers[key])}>
                    {value}
                </label>
            </div>
            ))}
        </div>
    );
});
