import React from "react";
import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { AvatarModal } from "../../modals/admin/avatar_modal";
import { ConfirmModal } from "../../modals/confirm_modal";

//State wrapper
const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return <BaseComponent {...props} usr_info={usr_info} />;
};

//Define my view compon
class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            avatars_selected: {},
            avatars: [],
            show_create_avatar: false,
            confirm: null,
            search: "",
            sort_by: "",
            sort_by_reverse: false
        };

        this.updateAvatarList = this.updateAvatarList.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleShowCreate = this.handleShowCreate.bind(this);
        this.handleSelectAvatar = this.handleSelectAvatar.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleViewAvatar = this.handleViewAvatar.bind(this);
        this.handleRemoveAvatar = this.handleRemoveAvatar.bind(this);
        this.handleSortBy = this.handleSortBy.bind(this);
    }

    componentDidMount() {
        this.props.onPathChange("avatar");
        this.updateAvatarList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.uid != this.props.uid) {
            this.updateAvatarList();
        }
    }

    filterAvatars(search, avatars) {
        //Do nothing
        if (search == "") {
            return avatars;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < avatars.length; i++) {
            const avatar = avatars[i];
            if (avatar.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(avatar);
            }
        }

        return result;
    }

    updateAvatarList() {
        const { showToast } = this.props;

        //Get a list of all avatars for this tenant
        Util.fetch_js( "/avatar/list/", {},
            js => {
                this.setState({ avatars: js.avatars });
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    handleSortBy(code) {
        const { sort_by, sort_by_reverse } = this.state;
        const sbr = sort_by == code ? !sort_by_reverse : false;

        this.setState({ sort_by: code, sort_by_reverse: sbr });
    }

    handleRemoveAvatar() {
        const { showToast } = this.props;
        let { avatars_selected, avatars } = this.state;

        Util.fetch_js(
            "/avatar/remove/",
            { avatar_uids: Object.keys(avatars_selected) },
            js => {
                this.updateAvatarList();
                this.setState({ avatars_selected: {} });
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
        this.setState({ confirm: null });
    }

    handleModalClose() {
        this.updateAvatarList();
        this.setState({ show_create_avatar: false });
    }

    handleShowCreate() {
        this.setState({ show_create_avatar: true });
    }

    handleSelectAvatar(checked, uid) {
        let { avatars_selected } = this.state;

        if (checked) {
            avatars_selected[uid] = true;
        }
        else {
            delete avatars_selected[uid];
        }

        this.setState({ avatars_selected });
    }

    handleSelectAll(checked) {
        const { search } = this.state;

        const avatars = this.filterAvatars(search, this.state.avatars);
        let avatars_selected = {};

        //Should we check or send empty list?
        if (checked) {
            for (let i = 0; i < avatars.length; i++) {
                avatars_selected[avatars[i].uid] = true;
            }
        }

        this.setState({ avatars_selected });
    }

    handleViewAvatar(avatar) {
        this.setState({ show_create_avatar: avatar });
    }

    render() {
        const { tenant_uid, tenants, showToast } = this.props;
        const {
            avatars,
            avatars_selected,
            search,
            sort_by,
            sort_by_reverse,
            show_create_avatar,
            confirm
        } = this.state;
        const selected_count = Object.keys(avatars_selected).length;

        let filtered_avatars = this.filterAvatars(search, avatars);

        //Sort?
        if (sort_by != "") {
            if (!sort_by_reverse) {
                filtered_avatars.sort((a, b) =>
                    a[sort_by]
                        .toLowerCase()
                        .localeCompare(b[sort_by].toLowerCase())
                );
            }
            else {
                filtered_avatars.sort((a, b) =>
                    b[sort_by]
                        .toLowerCase()
                        .localeCompare(a[sort_by].toLowerCase())
                );
            }
        }

        const confirmAction = action => {
            let yes = null;
            let msg = null;
            if (action == this.handleRemoveAvatar) {
                yes = Language.getTitleCase('remove');
                msg = Language.getSentenceCase('Remove Avatar(s)?');
            }
            else {
                throw Language.getSentenceCase('Program error, Unknown action') + ": " + action;
            }

            //Setting a confirm action, will load message box, and if accepted saves
            const new_confirm = { action, msg, yes, no: Language.getTitleCase('cancel') };
            this.setState({ ...this.state, confirm: new_confirm });
        };

        return (
            <div className='avatar-page main-modal'>
                <nav className='central__nav central__nav--no-list'>
                    <h2 className='central__nav-head'>{Language.getTitleCase('avatar list')}</h2>
                </nav>

                <div className='central__main'>
                    <div className='central__head'>
                        <div className='central__head-search-wrapper'>
                            <svg
                                width='15'
                                height='16'
                                viewBox='0 0 15 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                    fill='currentColor'
                                />
                            </svg>
                            <input
                                className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                                type='search'
                                name='search'
                                placeholder={Language.getSentenceCase('Search avatar words') + '...'}
                                value={search}
                                onChange={e => this.setState({ search: e.target.value }) }
                            />
                        </div>

                        <div className='central__head-button-container'>
                            {selected_count > 0 && (
                                <button
                                    type='button'
                                    className='central__head-button central__head-button--remove'
                                    name='remove-user'
                                    onClick={() =>
                                        confirmAction(this.handleRemoveAvatar)
                                    }>
                                    {(selected_count != 1 ? Language.getTitleCase('remove avatars') : Language.getTitleCase('remove avatar'))}
                                </button>
                            )}

                            <button
                                type='button'
                                className='central__head-button central__head-button--solid'
                                name='add-user'
                                onClick={this.handleShowCreate}>
                                {Language.getTitleCase('create avatar')}
                            </button>
                        </div>
                    </div>

                    <div className='table__container'>
                        {confirm != null && (
                            <ConfirmModal
                                msg={confirm.msg}
                                yesTxt={confirm.yes}
                                noTxt={confirm.no}
                                onYes={() => confirm.action(true)}
                                onNo={() =>
                                    this.setState({
                                        ...this.state,
                                        confirm: null
                                    })
                                }
                            />
                        )}
                        <table className='table table--avatar'>
                            <tbody className='table table--avatar'>
                                <tr className='table__row table__row--head'>
                                    <th className='table__head'>
                                        <div className='d7__checkbox-container' style={{borderBottomWidth: '0px'}}>
                                            <input
                                                className='d7__checkbox'
                                                type='checkbox'
                                                aria-label="checkbox"
                                                onChange={event =>
                                                    this.handleSelectAll(
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                            <span className='dash'></span>
                                        </div>
                                    </th>
                                    <th
                                        className='table__head table__head--name'
                                        onClick={() =>
                                            this.handleSortBy("name")
                                        }>
                                        {Language.getTitleCase('name')} <i className='sort-button' style={{borderBottomWidth: '0px'}}></i>
                                    </th>
                                    <th className='table__head table__head--img'>
                                        {Language.getTitleCase('image')}<i className='sort-button' style={{borderBottomWidth: '0px'}}></i>
                                    </th>
                                </tr>
                                {Object.entries(filtered_avatars).map(
                                    ([key, avatar]) => (
                                        <tr
                                            className='table__row'
                                            key={"avatar_tr_" + key}>
                                            <td className='user-table-select'>
                                                <div className='d7__checkbox-container' style={{borderBottomWidth: '0px'}}>
                                                    <input
                                                        type='checkbox'
                                                        aria-label="checkbox"
                                                        onChange={event =>
                                                            this.handleSelectAvatar(
                                                                event.target
                                                                    .checked,
                                                                avatar.uid
                                                            )
                                                        }
                                                        checked={
                                                            avatar.uid in
                                                            avatars_selected
                                                        }
                                                    />
                                                    <span className='checkmark'></span>
                                                </div>
                                            </td>
                                            <td
                                                className='table__data table__data--img-name'
                                                onClick={() =>
                                                    this.handleViewAvatar(
                                                        avatar
                                                    )
                                                }>
                                                {avatar.name}
                                            </td>
                                            <td
                                                className='table__data table__data--img'
                                                onClick={() =>
                                                    this.handleViewAvatar(
                                                        avatar
                                                    )
                                                }>
                                                <img
                                                    className='badge-placeholder'
                                                    alt='avatar'
                                                    src={avatar.image}
                                                />
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Drip7Modal>
                    {show_create_avatar && (
                        <AvatarModal
                            avatar={show_create_avatar}
                            showToast={showToast}
                            tenant_uid={tenant_uid}
                            tenants={tenants}
                            onClose={this.handleModalClose}
                        />
                    )}
                </Drip7Modal>
            </div>
        );
    }
}

export const Avatar = withStore(Klass);
