import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Language from "../../helpers/language";

export const AnswerTypeInlineBool = (props) => {
    const { name, value, onChanged } = props;

    console.log(value)

    const handleChange = value => {
        onChanged({ target: { name, value }})
    };

    const klass = exp => {
        //No matter i f the answer is correct or not, if this is the correct answer, mark it
        if ( (typeof value === "boolean") || (value instanceof Boolean) ) {
            if ( value == exp ) {
                return " daily-questions__" + exp + "--correct";
            }
        }

        return "";
    };

    return (
        <div className='daily-questions__true-or-false'>
            <button
                className={"daily-questions__true" + klass(true)}
                onClick={() => handleChange(true)}>
                {Language.getSentenceCase('true')}
            </button>
            <button
                className={"daily-questions__false" + klass(false)}
                onClick={() => handleChange(false)}>
                {Language.getSentenceCase('false')}
            </button>
        </div>
    );
}
