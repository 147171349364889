import React, {useEffect, useState} from "react";
import Util from "../../helpers/util";
import { BadgePopup } from "../../components/user/badge_popup";
import { Badge } from "../../components/badge";
import { BadgeTable } from "../../components/badge_table";
import { useStore } from "../../store";
import Language from "../../helpers/language";
import NonBadgePlaceholder1 from "../../assets/images/badges/NonBadgePlaceholder1.jpg"
import ContentLoader from "react-content-loader";
import Categories from "../../components/user/categories";
//import { Spinner } from "react-bootstrap";


function create_groups(badges) {
    let groups = {};

    // Sort by points, then by name
    const bs = badges.sort((b1, b2) => {
        const diff = b2.percentage - b1.percentage
        if ( diff != 0 ) {
            return diff
        }

        return b1.badge.name.localeCompare( b2.name )
    });

    bs.forEach(b => {
        const group = (b.badge.group || "default").toLowerCase();
        if (group in groups) {
            groups[group].badges.push(b);
        }
        else {
            groups[group] = { badges: [b], expand: true };
        }
    });

    return groups;
}

export const BadgeStatus = (props) => {
    const { onPathChange, showToast } = props

    const [state, setState] = useState({
        loading: false,
        show_badge: null,
        badge_groups: {},
        show_filter: false,
        filter: 'earned'
    })
    const { show_badge, badge_groups, loading, filter, show_filter } = state

    useEffect(() => {
        Util.fetch_js( "/client/badge/list_by_tenant/", {},
            js => {
                setState( prev => ({...prev,
                    badge_groups: create_groups(js.badges),
                    loading: false
                }))
            },
            (reason, code) => {
                setState(prev => ({...prev,
                    loading: false
                }))
                console.log(reason, "failure");
            }
        );

        onPathChange("badge-status")
        setState(prev => ({...prev,
            loading: true
        }))
    }, [])

    const badgeContinue = (uid) => {
        const keys = Object.keys(badge_groups);

        //Rip through the badges looking for a match
        for ( const key of keys ) {
            const badges = badge_groups[key].badges;
            for ( const b of badges ) {
                if (uid == b.badge.uid) {
                    setState(prev => ({...prev,
                        show_badge: b.badge
                    }))
                    return
                }
            }
        }
    }

    const handleExpand = (group) => {
        badge_groups[group].expand = !badge_groups[group].expand;
        setState(prev => ({...prev,
            badge_groups: badge_groups
        }))
    }

    const handleChange = (e) => {
        setState(prev => ({...prev,
            filter: e.target.value,
            show_filter: false
        }))
    };

    const show_not_earned = Object.values(badge_groups).map(x => x.badges.filter(b => !b.completed && !b.badge.hidden).length).reduce((a, b) => a + b, 0) > 0

    return (
        <div className='user-portal__main-container user-portal__main-container--badge-status'>
            <div className='badge-status__heading mobile-heading z-40'>
                <h1>{Language.getTitleCase('Badge Status')}</h1>
            </div>
            <nav className='user-portal__nav'>
                <div
                    className='user-portal__filter-wrapper user-portal__filter-wrapper--leaderboard'
                    role='button'
                    tabIndex='0'
                    style={{width: '100px'}}
                    onClick={() => setState(prev => ({...prev, show_filter: !show_filter }))}>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setState(prev => ({...prev, show_filter: !show_filter }))}>
                        {Language.getTitleCase('filter')}
                    </span>
                    <div
                        className={`user-portal__filter-button user-portal__filter--leaderboard user-portal__filter-button expand ${show_filter ? "active" : ""} `}
                        onClick={() => setState(prev => ({...prev, show_filter: !show_filter }))}>
                        <div id='show_departmentFilter' >
                            <svg
                                width='12'
                                height='20'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z'
                                    fill='black'></path>
                            </svg>
                        </div>
                    </div>
                </div>
                {show_filter && (<Categories>
                        <li className={`user-portal__filter user-portal__filter--department`}>
                            <div className='user-portal__filter-checkbox'>
                                <input
                                    onClick={handleChange}
                                    id='filter'
                                    value='earned'
                                    type='checkbox'
                                    aria-label="checkbox"
                                    checked={
                                        filter == 'earned'
                                    }
                                    readOnly
                                />
                                <label>
                                    {Language.getTitleCase('earned')}
                                </label>
                            </div>
                        </li>
                        <li className={`user-portal__filter user-portal__filter--department`}>
                            <div className='user-portal__filter-checkbox'>
                                <input
                                    onClick={handleChange}
                                    id='filter'
                                    value='in progress'
                                    aria-label="checkbox"
                                    type='checkbox'
                                    checked={
                                        filter == 'in progress'
                                    }
                                    readOnly
                                />
                                <label>
                                    {Language.getTitleCase('in progress')}
                                </label>
                            </div>
                        </li>
                        {show_not_earned && (
                        <li className={`user-portal__filter user-portal__filter--department`}>
                            <div className='user-portal__filter-checkbox'>
                                <input
                                    onClick={handleChange}
                                    id='filter'
                                    value='not earned'
                                    type='checkbox'
                                    aria-label="checkbox"
                                    checked={ filter == 'not earned' }
                                    readOnly
                                />
                                <label>
                                    {Language.getTitleCase('not earned')}
                                </label>
                            </div>
                        </li>)}
                    </Categories>)}
            </nav>

            <div className='central-container__filter-button-container central-container__filter-button-container--mobile central-container__filter-button-container--question-archive z-30'>
            <button
                className={`central-container__filter-button central-container__filter-button--question-archive  ${filter == 'earned' ? 'selected ' : ''}`}
                onClick={() => setState(prev => ({...prev, filter: 'earned'}))}>
                <svg
                    width='18'
                    height='19'
                    viewBox='0 0 18 19'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M0 2.21875V10.6562C0 11.1484 0.351562 11.5 0.84375 11.5H3.65625C4.11328 11.5 4.5 11.1484 4.5 10.6562V2.21875C4.5 1.76172 4.11328 1.375 3.65625 1.375H0.84375C0.351562 1.375 0 1.76172 0 2.21875ZM1.40625 9.25C1.40625 8.79297 1.75781 8.40625 2.25 8.40625C2.70703 8.40625 3.09375 8.79297 3.09375 9.25C3.09375 9.74219 2.70703 10.0938 2.25 10.0938C1.75781 10.0938 1.40625 9.74219 1.40625 9.25ZM10.9688 18.25C11.8125 18.25 13.5 17.9688 13.5 15.4023C13.5 13.9258 12.5859 13.082 12.3047 12.0625H15.8906C17.0508 12.0625 17.9648 11.1133 18 10.0234C18 9.39062 17.7188 8.72266 17.2969 8.30078C17.6484 7.49219 17.5781 6.33203 16.9805 5.52344C17.2617 4.60938 16.9805 3.48438 16.3828 2.88672C16.5586 2.25391 16.4883 1.72656 16.1719 1.30469C15.4688 0.285156 13.6758 0.25 12.1641 0.25H12.0586C10.3711 0.285156 9 0.882812 7.875 1.375C7.3125 1.62109 6.57422 1.9375 6.01172 1.9375C5.80078 1.97266 5.625 2.14844 5.625 2.35938V9.88281C5.625 9.98828 5.66016 10.1289 5.73047 10.1992C7.13672 11.5703 7.73438 13.0117 8.85938 14.1719C9.38672 14.6992 9.5625 15.4727 9.77344 16.2461C9.91406 16.8789 10.2305 18.25 10.9688 18.25Z'
                        fill='currentColor'></path>
                </svg>
                <h3 className='central-container__filter-button-name'>
                    {Language.getTitleCase('earned')}
                </h3>
            </button>


            <button
                className={`central-container__filter-button central-container__filter-button--question-archive ${filter == 'in progress' ? 'selected ' : ''}`}
                onClick={() => setState(prev => ({...prev, filter: 'in progress'}))}>
                <svg
                    width='18'
                    height='19'
                    viewBox='0 0 18 19'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M3.65625 8.125H0.84375C0.351562 8.125 0 8.51172 0 8.96875V17.4062C0 17.8984 0.351562 18.25 0.84375 18.25H3.65625C4.11328 18.25 4.5 17.8984 4.5 17.4062V8.96875C4.5 8.51172 4.11328 8.125 3.65625 8.125ZM2.25 16.8438C1.75781 16.8438 1.40625 16.4922 1.40625 16C1.40625 15.543 1.75781 15.1562 2.25 15.1562C2.70703 15.1562 3.09375 15.543 3.09375 16C3.09375 16.4922 2.70703 16.8438 2.25 16.8438ZM13.5 3.13281C13.5 0.53125 11.8125 0.25 10.9688 0.25C10.2305 0.25 9.91406 1.65625 9.77344 2.28906C9.5625 3.0625 9.38672 3.83594 8.85938 4.36328C7.73438 5.52344 7.13672 6.96484 5.73047 8.33594C5.66016 8.44141 5.625 8.54688 5.625 8.65234V16.1758C5.625 16.3867 5.80078 16.5625 6.01172 16.5977C6.57422 16.5977 7.3125 16.9141 7.875 17.1602C9 17.6523 10.3711 18.25 12.0586 18.25H12.1641C13.6758 18.25 15.4688 18.25 16.1719 17.2305C16.4883 16.8086 16.5586 16.2812 16.3828 15.6484C16.9805 15.0508 17.2617 13.9258 16.9805 13.0117C17.5781 12.2031 17.6484 11.043 17.2969 10.2344C17.7188 9.8125 18 9.14453 17.9648 8.51172C17.9648 7.42188 17.0508 6.4375 15.8906 6.4375H12.3047C12.5859 5.45312 13.5 4.60938 13.5 3.13281Z'
                        fill='currentColor'></path>
                </svg>
                <h3 className='central-container__filter-button-name'>
                    {Language.getTitleCase('in progress')}
                </h3>
            </button>

            {show_not_earned &&
                <button
                    className={`central-container__filter-button central-container__filter-button--question-archive ${filter == 'not earned' ? 'selected ' : ''}`}
                    onClick={() => setState(prev => ({...prev, filter: 'not earned'}))}>
                    <svg
                        width='18'
                        height='17'
                        viewBox='0 0 18 17'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M1.125 15C1.125 15.6328 1.61719 16.125 2.25 16.125H15.75C16.3477 16.125 16.875 15.6328 16.875 15V4.875H1.125V15ZM6.75 7.54688C6.75 7.33594 6.92578 7.125 7.17188 7.125H10.8281C11.0391 7.125 11.25 7.33594 11.25 7.54688V7.82812C11.25 8.07422 11.0391 8.25 10.8281 8.25H7.17188C6.92578 8.25 6.75 8.07422 6.75 7.82812V7.54688ZM16.875 0.375H1.125C0.492188 0.375 0 0.902344 0 1.5V3.1875C0 3.50391 0.246094 3.75 0.5625 3.75H17.4375C17.7188 3.75 18 3.50391 18 3.1875V1.5C18 0.902344 17.4727 0.375 16.875 0.375Z'
                            fill='currentColor'></path>
                    </svg>

                    <h3 className='central-container__filter-button-name'>
                        {Language.getTitleCase('not earned')}
                    </h3>
                </button>
            }
        </div>

            {show_badge != null && show_badge != undefined && (
                <BadgePopup
                    badge={show_badge}
                    onClose={() => setState(prev => ({...prev, show_badge: null }))}
                    showToast={showToast}
                />
            )}
            <div className='central-container badge-status'>
                { loading && <div className='leaderboard-loader'>
                    {[...Array(10)].map((x, i) =>
                        <ContentLoader className='leaderboard__loader--desktop' width="573" height="65" key={'loading' + i} viewBox="0 0 573 65">
                            <circle cx="59" cy="16" r="16" />
                            <rect x="90" y="13" width="530" height="8" />
                        </ContentLoader>
                    )}
                    {[...Array(10)].map((x, i) =>
                        <ContentLoader className='leaderboard__loader--mobile' width="300" height="65" key={'loading' + i} viewBox="0 0 300 65">
                            <circle cx="85" cy="24" r="24" />
                            <rect x="116" y="13" width="220" height="10" />
                            <rect x="116" y="28" width="80" height="8" />
                        </ContentLoader>
                    )}
                    </div>
                }

                {Object.entries(badge_groups).map(([title, detail]) => (
                    <div
                        key={"group_title_" + title}
                        className='badge-status__group'>
                        {badge_groups.length > 1 && (
                            <div className='badge-status__group-name'>
                                <h3 onClick={() => handleExpand(title) }>
                                    {title}
                                    <svg
                                        className='container-symbol'
                                        width='10'
                                        height='6'
                                        viewBox='0 0 10 6'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        {detail.expand && (
                                            <path
                                                d='M1.5332 0.5 C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                                                fill='currentColor'></path>
                                        )}
                                        {!detail.expand && (
                                            <path
                                                d='M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z'
                                                fill='currentColor'></path>
                                        )}
                                    </svg>
                                </h3>
                            </div>
                        )}

                        {detail.expand && filter == 'earned' &&(
                            <div className='badge-status__badge-group-container'>
                                <table className='badge-table'>
                                    <tbody>
                                        {detail.badges.map(b => ((b.completed) &&
                                            <BadgeTable
                                                key={"badge_key_" + b.badge.uid}
                                                uid={b.badge.uid}
                                                name={b.badge.name}
                                                percentage={b.percentage}
                                                message={b.contents[0]?.message}
                                                owned_perc={b.owned_perc}
                                                logo={b.badge.image}
                                                points={b.badge.points}
                                                onClick={badgeContinue}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {detail.expand && filter == 'in progress' &&(
                            <div className='badge-status__badge-group-container'>
                                <table className='badge-table'>
                                    <tbody>
                                        {detail.badges.map(b => ( (b.percentage > 0 && !b.completed) &&
                                            <BadgeTable
                                                key={"badge_key_" + b.badge.uid}
                                                uid={b.badge.uid}
                                                name={b.badge.name}
                                                percentage={b.percentage}
                                                owned_perc={b.owned_perc}
                                                message={b.contents[0]?.message}
                                                points={b.badge.points}
                                                logo={!b.badge.obscure ? b.badge.image : NonBadgePlaceholder1}
                                                onClick={badgeContinue}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {show_not_earned && detail.expand && filter == 'not earned' &&(
                            <div className='badge-status__badge-group-container'>
                                <table className='badge-table'>
                                    <tbody>
                                        {detail.badges.map(b => ( (!b.completed && !b.badge.hidden) &&
                                            <BadgeTable
                                                key={"badge_key_" + b.badge.uid}
                                                uid={b.badge.uid}
                                                name={b.badge.name}
                                                percentage={b.percentage}
                                                message={b.contents[0]?.message}
                                                owned_perc={b.owned_perc}
                                                points={b.badge.points}
                                                logo={!b.badge.obscure ? b.badge.image : NonBadgePlaceholder1}
                                                onClick={badgeContinue}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
