import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { useStore } from "../store";
import Util from "../helpers/util";

export const Progress = props => {
    const { label, label_only, idx, total, percentage } = props;

    let progress = 0;

    //Calculate the progress
    if (percentage != undefined && percentage != null) {
        progress = Math.round(percentage);
    }
    else if (total > 0) {
        progress = Math.round((100 * idx) / total);
    }

    //Cap the values
    if (progress < 0) {
        progress = 0;
    }
    if (progress > 100) {
        progress = 100;
    }

    const bar_label =
        label_only || idx >= total ? label : `${idx + 1} / ${total} ${label}`;

    return (
        <div className='daily-questions__progression'>
            <div className='daily-questions__progression-stats'>
                <h4 className='daily-questions__questions-answered'>
                    {bar_label}
                </h4>
                <h4 className='daily-questions__percentage-answered'>
                    {progress}%
                </h4>
            </div>
            <div className='daily-questions__progression-bar'>
                <div className='daily-questions__progression-bar-bg'></div>
                <div
                    className='daily-questions__progression-bar-fg'
                    style={{ width: progress + "%" }}></div>
            </div>
        </div>
    );
};
