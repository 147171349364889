import React, {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { buildQuestions, filterQuestions } from "../../helpers/content";
import {AnswerType, BlockMode, ContentType, Languages} from "../../helpers/consts";
import { QuestionTableWidget } from "./question_table_widget";
import {InputWithArrows} from "../input_with_arrows";
import { CurriculumGuidedSlotWidget } from "./curriculum_guided_slot_widget";
import PlusImg from '../../assets/images/shared/plus.svg'
import Form from "react-bootstrap/Form";
import {DripQuestions} from "../user/drip_questions";
import { Drip7Button } from "../drip7_button";


export const CurriculumPreviewWidget = props => {
    const { curriculum, tenant_uid, category_uids, tag_uids, question_uids, blocks, showToast } = props;

    const [state, setState] = useState({
        idx: 0,
        user_questions: [],
    });
    const { idx, user_questions } = state

    const [drip_ref, setDripRef] = useState( useRef() )

    useEffect(() => {
        const payload = {
            tenant_uid,
            category_uids,
            tag_uids,
            question_uids,
            blocks,
        }
        Util.fetch_js('/curriculum/preview/', payload,
            js => {
                setState( prev => ({ ...prev, user_questions: js.user_questions }))
            },
            (err, code) => {
                showToast( err, "failure")
            })
    }, [])

    const handleChange = (e) => {
        if ( e.target.id == 'idx' ) {
            const value = parseInt( e.target.value )
            if ( value < 0 || value >= user_questions.length ) {
                return
            }

            //Do we have a valid ref?
            if ( drip_ref.current != null ) {
                drip_ref.current.setIdx( value )
            }
        }

        setState( prev => ({ ...prev, [e.target.id]: e.target.value }))
    }

    const handleAddQuestions = (idx, questions) => {
        let { user_questions } = state

        const skips = {}
        user_questions.forEach( x => { skips[x.question.uid] = true })

        //Add questions that aren't in my uid
        const filtered_questions = questions.filter( x => !(x.question.uid in skips) )
        filtered_questions.forEach( x => {
            user_questions.splice(++idx, 0, x)
        })

        showToast( `Questions added ${filtered_questions.length}`, "success" )
        setState(prev => ({...prev,
            user_questions,
        }))
    }

    return (
        <div className='basic-modal__config'>
            <div className='central__filters'>
                <div className="buttons buttons__centered">
                    <Drip7Button
                        disabled={idx == 0}
                        style={{ marginRight: '6px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => handleChange({ target: { id: "idx", value: idx - 1 }})}>
                        <svg width="15" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                            style={{marginRight: '6px'}}>
                            <path
                                d="M14.5714 6.92857V8.14286C14.5714 8.47805 14.4671 8.76581 14.2584 9.00614C14.056 9.24014 13.7904 9.35714 13.4615 9.35714H6.78292L9.5625 12.1462C9.80283 12.3739 9.92299 12.6585 9.92299 13C9.92299 13.3415 9.80283 13.6261 9.5625 13.8538L8.851 14.5748C8.617 14.8088 8.3324 14.9258 7.99721 14.9258C7.66834 14.9258 7.38058 14.8088 7.13393 14.5748L0.958147 8.38951C0.724144 8.15551 0.607143 7.87091 0.607143 7.53571C0.607143 7.20685 0.724144 6.91908 0.958147 6.67243L7.13393 0.506138C7.37426 0.26581 7.66202 0.145646 7.99721 0.145646C8.32608 0.145646 8.61068 0.26581 8.851 0.506138L9.5625 1.20815C9.80283 1.44847 9.92299 1.73623 9.92299 2.07143C9.92299 2.40662 9.80283 2.69438 9.5625 2.93471L6.78292 5.71429H13.4615C13.7904 5.71429 14.056 5.83445 14.2584 6.07478C14.4671 6.30878 14.5714 6.59338 14.5714 6.92857Z"
                                fill="currentColor"></path>
                        </svg>
                        {Language.getTitleCase('Previous')}
                    </Drip7Button>

                    <div className="progress-bar_container">
                        <Form.Range
                            id="idx"
                            max={user_questions.length - 1}
                            min={0}
                            value={idx}
                            onChange={handleChange}
                        />
                        <label>{idx + 1} / {user_questions.length}</label>
                    </div>

                    <Drip7Button
                        disabled={idx == user_questions.length-1}
                        style={{ marginLeft: '6px', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => handleChange({ target: { id: "idx", value: idx + 1 }})}>
                        {Language.getTitleCase('Next')}

                        <svg width="15" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                            style={{marginLeft: '6px'}}>
                            <path
                                d="M14.016 7.53571C14.016 7.87723 13.899 8.16499 13.665 8.399L7.48926 14.5748C7.24261 14.8088 6.95485 14.9258 6.62598 14.9258C6.30343 14.9258 6.01883 14.8088 5.77218 14.5748L5.06069 13.8633C4.82036 13.623 4.7002 13.3352 4.7002 13C4.7002 12.6648 4.82036 12.377 5.06069 12.1367L7.84026 9.35714H1.16169C0.832822 9.35714 0.564035 9.24014 0.355329 9.00614C0.152948 8.76581 0.0517578 8.47805 0.0517578 8.14286V6.92857C0.0517578 6.59338 0.152948 6.30878 0.355329 6.07478C0.564035 5.83445 0.832822 5.71429 1.16169 5.71429H7.84026L5.06069 2.92522C4.82036 2.69754 4.7002 2.41295 4.7002 2.07143C4.7002 1.72991 4.82036 1.44531 5.06069 1.21763L5.77218 0.506138C6.01251 0.26581 6.29711 0.145646 6.62598 0.145646C6.96117 0.145646 7.24893 0.26581 7.48926 0.506138L13.665 6.68192C13.899 6.90327 14.016 7.18787 14.016 7.53571Z"
                                fill="currentColor"></path>
                        </svg>
                    </Drip7Button>
                </div>
            </div>

            <DripQuestions
                ref={drip_ref}
                dryRun={true}
                user_questions={user_questions}
                onAddQuestions={handleAddQuestions}
                showToast={showToast}
                onIdx={idx => setState( prev => ({ ...prev, idx }))}
                />
        </div>
    );
};
