import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import DatePicker, { DateObject } from "react-multi-date-picker";
import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { SelectItems } from "../../components/select_items";

export const BadgeAssignModal = (props) => {
    const { badge, tenant_uid, onClose, showToast } = props

    const [state, setState] = useState({
        action: 'assign',
        users: [],
        groups: [],
        selected_users: [],
        selected_groups: [],
    })
    const { action, users, groups, selected_users, selected_groups } = state

    useEffect(() => {
        //Get users and groups
        Util.fetch_js( "/human/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev, users: js.users }))
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        );

        Util.fetch_js( "/group/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev, groups: js.groups }))
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        );
    }, [])

    const handleSave = () => {
        const payload = {
            badge_uid: badge.uid,
            user_uids: selected_users,
            group_uids: selected_groups,
        }
        Util.fetch_js( `/badge/${action.toLowerCase()}/`, payload,
            js => {
                showToast(`${action} ${badge.name} ${Language.getString('to')} ${js.count} ${Language.getString('user(s)')}`, 'successful');
                onClose(true);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const handleChange = (event) => {
        setState(prev => ({ ...prev,
            [event.target.name]: event.target.value
        }))
    }

    return (
        <div className='basic-modal basic-modal--add-group'>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('badge assignment')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className='basic-modal__wrapper basic-modal__wrapper--column'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('name')}
                    </label>
                    <div className='basic-modal__input dropdown dropdown--attached dropdown--add-question'>
                        <p>{badge.name}</p>
                    </div>
                </div>

                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('action')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <DropdownButton title={Language.getTitleCase(action)}>
                            {Object.entries(['assign', 'unassign']).map(([key, act]) => (
                                <Dropdown.Item
                                    as='button'
                                    onClick={handleChange}
                                    name='action'
                                    value={act}
                                    key={"action_" + key}>
                                    {Language.getTitleCase(act)}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>
            </div>

            <div className='basic-modal__wrapper basic-modal__wrapper--column'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <div className='dropdown dropdown--attached dropdown--add-question flex justify-center'>
                        <img className="badge-img" alt='badge' src={badge.image} />
                    </div>
                </div>
            </div>

            <SelectItems
                selected={selected_users}
                items={users}
                title={"users"}
                searchTitle={"search users by name"}
                onChange={value => handleChange({ target: { name: 'selected_users', value }})}
                />

            <SelectItems
                selected={selected_groups}
                items={groups}
                title={"groups"}
                hideSearch={true}
                hideAll={true}
                onChange={value => handleChange({ target: { name: 'selected_groups', value }})}
            />

            <div className='basic-modal__button-container'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>

                {action == 'unassign' &&
                    <button
                        className='basic-modal__button central__head-button central__head-button--remove'
                        onClick={handleSave}>
                        {Language.getTitleCase(action)}
                    </button>
                }

                {action == 'assign' &&
                    <button
                        className='basic-modal__button basic-modal__button--solid'
                        onClick={handleSave}>
                        {Language.getTitleCase(action)}
                    </button>
                }
            </div>
        </div>
    );
}
