import React, {useState, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../store";
import Util from "../helpers/util";

import { AnswerType, ContentType } from "../helpers/consts";
import Chart from "react-apexcharts";
import Language from "../helpers/language";

export const ActiveQueueCard = props => {
    const { cascade, tenant_uid, showToast } = props;
    const chart = useRef(null)
    const [state, setState] = useState({
        local_tenant_uid: '',
        local_cascade: null,
    });
    const { local_tenant_uid, local_cascade } = state

    const loadGraph = (series, labels) => {
        if ( chart.current != null ) {
            chart.current.destroy()
        }

        const options = {
            series, labels,

            chart: {
                width: '100%',
                type: "donut"
            },
            plotOptions: {
                pie: {
                    startAngle: -90, endAngle: 270,
                }
            },
            dataLabels: {
                formatter( val, opts ) {
                    return opts.w.globals.series[opts.seriesIndex];
                }
            },
            tooltip: {
                y: {
                    formatter: function( val ) {
                        return val + " " + Language.getTitleCase('users');
                    }
                }
            },
            responsive: [{
                breakpoint: 480, options: {
                    chart: {
                        width: 200
                    }, legend: {
                        position: "bottom"
                    }
                }
            }]
        };

        chart.current = new ApexCharts(
            document.querySelector( "#activequeuecard_chart" ),
            options
        );
        chart.current.render();
    }

    useEffect(() => {
        //Bail
        if (tenant_uid == "" || (tenant_uid == local_tenant_uid && cascade == local_cascade)) {
            return;
        }

        //Query the stats
        Util.fetch_js( "/dashboard/active_queues/", { tenant_uid, cascade },
            js => {
                let series = [];
                let labels = [];

                //Series and labels are better now
                if (js.queues.length > 0) {
                    series = js.queues.map(x => x.user_count);
                    labels = js.queues.map(x => x.name);
                }
                else {
                    series = [js.user_count];
                    labels = [Language.getTitleCase('none')];
                }

                loadGraph( series, labels )
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        //Force the tenant uid state update to prevent double loads
        setState(prev => ({...prev,
            local_tenant_uid: tenant_uid,
            local_cascade: cascade,
        }))



    }, [props]);
    
    //destroy card on unmounting - removes console errors
    useEffect(() => {
        return () => {
            if ( chart.current != null ) {
                chart.current.destroy()
            }
        }
    }, [])

    //style={{ height: "240px", flexGrow: 2 }}>

    return (
        <div className='admin-card admin-card--user-count'>
            <h3>{Language.getTitleCase('Active Queues')}</h3>
            <div id='activequeuecard_chart' ></div>
        </div>
    );
};
