import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import {
    Access,
    CurriculumUsage
} from "../../helpers/consts";
import { CurriculumModal } from "../../modals/admin/curriculum_modal";
import {QueueModal} from "../../modals/admin/queue_modal";

//State wrapper
const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return <BaseComponent {...props} usr_info={usr_info} />;
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
            confirm: null,
            curriculums: [],
            curriculum_selected: {},
            default_delivery: "",
            show_curriculum: null
        };

        this.filterCurriculum = this.filterCurriculum.bind(this);
        this.updateCurriculumList = this.updateCurriculumList.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectCurriculum = this.handleSelectCurriculum.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCurriculum = this.handleCurriculum.bind(this);
        this.handleCloseCurriculum = this.handleCloseCurriculum.bind(this);
        this.handleRemoveCurriculum = this.handleRemoveCurriculum.bind(this);
    }

    componentDidMount() {
        this.updateCurriculumList();
        this.props.onPathChange("curriculum");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tenant_uid != this.props.tenant_uid) {
            this.updateCurriculumList();
        }
    }

    filterCurriculum(curriculums) {
        const { search, default_delivery } = this.state;

        const search_lc = search.toLowerCase();
        const delivery_lc = default_delivery.toLowerCase();

        //Only keep
        let result = [];
        for (let i = 0; i < curriculums.length; i++) {
            const curriculum = curriculums[i];
            if ( search_lc != "" &&
                curriculum.name.toLowerCase().indexOf(search_lc) < 0 ) {
                continue;
            }
            if ( delivery_lc != "" &&
                curriculum.default_delivery.toLowerCase() != default_delivery ) {
                continue;
            }

            result.push(curriculum);
        }

        return result;
    }

    updateCurriculumList() {
        const { tenant_uid, showToast } = this.props;

        console.log(tenant_uid)
        //Get a list of all curriculums for this tenant
        Util.fetch_js( "/curriculum/list/", { tenant_uid, access: Access.EXEC },
            js => {
                this.setState({ curriculums: js.curriculums });
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    handleSelectAll(checked) {
        const { curriculums } = this.state;
        let curriculum_selected = {};

        //Get data
        let filtered_curriculum = this.filterCurriculum(curriculums);

        //Should we check or send empty list?
        if (checked) {
            for (let i = 0; i < filtered_curriculum.length; i++) {
                curriculum_selected[filtered_curriculum[i].uid] = true;
            }
        }

        this.setState({ curriculum_selected });
    }

    handleSelectCurriculum(checked, uid) {
        let { curriculum_selected } = this.state;

        //update my list
        if (checked) {
            curriculum_selected[uid] = true;
        }
        else {
            delete curriculum_selected[uid];
        }

        this.setState({ curriculum_selected });
    }

    handleChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleCurriculum(show_curriculum) {
        this.setState({ show_curriculum });
    }

    handleCloseCurriculum() {
        this.updateCurriculumList();
        this.setState({ show_curriculum: null });
    }

    handleRemoveCurriculum() {
        const { showToast } = this.props;
        const { curriculum_selected } = this.state;

        Util.fetch_js(
            "/curriculum/remove/",
            { curriculum_uids: Object.keys(curriculum_selected) },
            js => {
                this.updateCurriculumList();
                showToast(Language.getSentenceCase('removed'), 'successful');

                this.setState({ curriculum_selected: {}, confirm: null });
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    render() {
        const {
            search,
            curriculums,
            curriculum_selected,
            default_delivery,
            show_curriculum,
            confirm
        } = this.state;
        const { tenant_uid, tenants, showToast } = this.props;

        const fc = this.filterCurriculum(curriculums);
        console.log(fc)
        const filtered_curriculums = [
            ...fc.filter(c => c.tenant.uid === tenant_uid),
            ...fc.filter(c => c.tenant.uid !== tenant_uid)
        ]

        const confirmAction = action => {
            let yes = null;
            let msg = null;
            if (action === this.handleRemoveCurriculum) {
                yes = Language.getTitleCase('remove');
                msg = Language.getSentenceCase('remove curriculum?');
            }
            else {
                throw Language.getSentenceCase('Program error, Unknown action') + ": " + action;
            }

            //Setting a confirm action, will load message box, and if accepted saves
            const new_confirm = { action, msg, yes, no: Language.getTitleCase('cancel') };
            this.setState({ confirm: new_confirm });
        };

        return (
            <div className='curriculum main-modal'>
                <div className='central__main central__main--management'>
                    <nav className='central__nav'>
                        <ul className="central__nav-list">
                            <li className="central__nav-item central__nav-item--small central__nav-item--selected">
                                <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill='var(--drip7-primary-color)' d="M44.8047 15.4863L24.9063 8.26801C23.9219 7.91066 22.8672 7.91066 21.8828 8.26801L2.125 15.4863C1.42188 15.7722 1 16.4154 1 17.1301C1 17.9162 1.42188 18.5595 2.125 18.8453L6.34375 20.3462C5.5 21.4897 4.86719 22.7761 4.58594 24.2055C3.8125 24.5628 3.25 25.349 3.25 26.2781C3.25 26.9927 3.53125 27.636 4.02344 28.0648L2.33594 38.7136C2.19531 39.4283 2.75781 40 3.39063 40H7.53906C8.17188 40 8.73438 39.4283 8.59375 38.7136L6.90625 28.0648C7.39844 27.636 7.75 26.9927 7.75 26.2781C7.75 25.5634 7.32813 24.9202 6.83594 24.5628C7.11719 23.2764 7.75 22.1329 8.59375 21.2038L21.9531 26.0637C22.9375 26.421 23.9922 26.421 24.9766 26.0637L44.8047 18.8453C45.5078 18.5595 46 17.9162 46 17.1301C46 16.4154 45.5078 15.7722 44.8047 15.4863ZM25.6797 28.2077C24.9766 28.4936 24.2031 28.565 23.5 28.565C22.7266 28.565 21.9531 28.4936 21.25 28.2077L10.9844 24.4913L10 34.7113C10 37.6415 15.9766 40 23.5 40C30.8828 40 37 37.6415 37 34.7113L35.9453 24.4913L25.6797 28.2077Z" />
                                </svg>  
                                {Language.getTitleCase('curriculum')}
                            </li>
                        </ul>
                        {/* <h2 className='central__nav-head'>{Language.getTitleCase('curriculum')}</h2> */}
                    </nav>
                    <div className='central__head'>
                        <div className='central__head-search-wrapper'>
                            <svg
                                width='15'
                                height='16'
                                viewBox='0 0 15 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                    fill='currentColor'></path>
                            </svg>
                            <input
                                type='text'
                                className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                                placeholder={Language.getSentenceCase('Search by name') + '...'}
                                id='search'
                                value={search}
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className='central__head-button-container'>
                            {Object.keys(curriculum_selected).length > 0 && (
                                <button
                                    type='button'
                                    className='central__head-button central__head-button--remove'
                                    onClick={() => confirmAction( this.handleRemoveCurriculum )}>
                                    {Language.getTitleCase('Remove Curriculum')}
                                </button>
                            )}

                            <button
                                className='central__head-button central__head-button--solid central__head-button--add'
                                id='addContent'
                                type='button'
                                onClick={() =>
                                    this.handleCurriculum({
                                        usage: CurriculumUsage.CURRICULUM
                                    })
                                }>
                                {Language.getTitleCase('create curriculum')}
                            </button>
                        </div>
                    </div>

                    <table
                        className='management__table'
                        role='region'
                        aria-live='polite'>
                        <tbody>
                            <tr className='management__table-row management__table-row--head'>
                                <th className='management__table-head management__table-head--input'>
                                    <div className='d7__checkbox-container'>
                                        <input
                                            className='d7__checkbox'
                                            aria-label="checkbox"
                                            type='checkbox'
                                            onChange={e =>
                                                this.handleSelectAll(
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        <span className='dash'></span>
                                    </div>
                                </th>
                                <th className='management__table-head management__table-head--name'>
                                    <div className='management__table-heading'>
                                        {Language.getTitleCase('name')}
                                    </div>
                                </th>
                                <th className='management__table-head management__table-head--languages'>
                                    <div className='management__table-heading'>
                                        {Language.getTitleCase('tenant')}
                                    </div>
                                </th>
                                <th className='management__table-head management__table-head--languages'>
                                    <div className='management__table-heading'>
                                        {Language.getTitleCase('usage')}
                                    </div>
                                </th>
                                <th className='management__table-head management__table-head--languages'>
                                    <div className='management__table-heading'>
                                        {Language.getTitleCase('questions')}
                                    </div>
                                </th>
                            </tr>
                            {Object.entries(filtered_curriculums).map(
                                ([key, curriculum]) => (
                                    <tr
                                        className='management__table-row management__table-row--body'
                                        key={"curriculum_key_" + key}>
                                        <td className='management__table-data management__table-data--input'>
                                            <div className='d7__checkbox-container'>
                                                <input
                                                    className='d7__checkbox'
                                                    aria-label="checkbox"
                                                    type='checkbox'
                                                    onChange={e =>
                                                        this.handleSelectCurriculum(
                                                            e.target.checked,
                                                            curriculum.uid
                                                        )
                                                    }
                                                    checked={
                                                        curriculum.uid in
                                                        curriculum_selected
                                                    }
                                                />
                                                <span className='checkmark'></span>
                                            </div>
                                        </td>
                                        <td className='management__table-data management__table-data--name'
                                            onClick={() => this.handleCurriculum( curriculum ) }>
                                            <div className='management__table-text pointer'>
                                                {curriculum.name}
                                            </div>
                                        </td>
                                        <td className='management__table-data'
                                            onClick={() => this.handleCurriculum( curriculum ) }>
                                            <div className='management__table-text pointer'>
                                                {curriculum.tenant.name}
                                            </div>
                                        </td>
                                        <td className='management__table-data'
                                            onClick={() => this.handleCurriculum( curriculum ) }>
                                            <div className='management__table-text pointer'>
                                                {curriculum.usage === 'Campaign' ?
                                                    Language.getTitleCase('Phishing') : curriculum.usage }
                                            </div>
                                        </td>
                                        <td className='management__table-data'
                                            onClick={() => this.handleCurriculum( curriculum ) }>
                                            <div className='management__table-text pointer'>
                                                {curriculum.delivery_count}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>

                    {confirm != null && (
                        <ConfirmModal
                            msg={confirm.msg}
                            yesTxt={confirm.yes}
                            noTxt={confirm.no}
                            onYes={() => confirm.action(true)}
                            onNo={() =>
                                this.setState({ ...this.state, confirm: null })
                            }
                        />
                    )}

                    <Drip7Modal>
                        {show_curriculum != null &&
                            <CurriculumModal
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                curriculum={show_curriculum}
                                showToast={showToast}
                                onClose={this.handleCloseCurriculum}
                            />
                        }
                    </Drip7Modal>
                </div>
            </div>
        );
    }
}

export const Curriculum = withStore(Klass);
