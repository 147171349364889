import React from "react";
import { useStore } from "../../store";

import { useMsal } from "@azure/msal-react";

import Util from "../../helpers/util";
import SSO from "../../helpers/sso"
import Language from "../../helpers/language";

const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return (
        <BaseComponent
            {...props}
            usr_info={usr_info}
            fileRef={React.useRef()}
            msal={useMsal()}
        />
    );
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        const { tenant } = this.props;

        const info = JSON.parse(JSON.stringify(tenant));   //deep copy so we don't change the actual information
        if (!('bearer_token') in info)
            info['bearer_token'] = '';

        this.state = {
            search: "",
            info: info,
            groups: [],
            selected_groups: {},
            hide_bearer_token: true
        };

        this.copyEndpoint = this.copyEndpoint.bind(this);
        this.getSCIMEndpoint = this.getSCIMEndpoint.bind(this);
        this.refreshBearer = this.refreshBearer.bind(this);
        this.copyBearer = this.copyBearer.bind(this);
        this.toggleBearer = this.toggleBearer.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    getSCIMEndpoint() {
        const { tenant } = this.props
        return 'https://' + tenant.subdomain + '.drip7.com/scim/v2/'
    }

    copyEndpoint() {
        const { showToast } = this.props;

        // Copy the text inside the text field
        navigator.clipboard.writeText(this.getSCIMEndpoint());

        // Alert the user that the text has been copied
        showToast('Copied', 'success');
    }

    refreshBearer() {
        let { info } = this.state
        let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        info.bearer_token = ''
        for (let i=0; i<32; i=i+1)
            info.bearer_token += chars.charAt(Math.floor(Math.random()*chars.length))
        this.setState({...this.state, hide_bearer_token: false, info: info})
    }

    copyBearer() {
        const { showToast } = this.props;
        const { info } = this.state;

        // Copy the text inside the text field
        navigator.clipboard.writeText(info.bearer_token);

        this.setState({...this.state, hide_bearer_token: true})

        // Alert the user that the text has been copied
        showToast('Copied', 'success');
    }

    toggleBearer() {
        const { hide_bearer_token } = this.state
        this.setState({...this.state, hide_bearer_token: !hide_bearer_token})
    }

    handleUpdate() {
        const { onUpdate, onClose } = this.props;
        const { info } = this.state;

        onUpdate(info)
        onClose()
    }

    render() {
        const { onClose } = this.props;
        const { info, hide_bearer_token } = this.state;

        let token_display = info.bearer_token;
        if (!info.bearer_token || info.bearer_token.length == 0)
            token_display = '**empty**';
        else if (hide_bearer_token)
            token_display = token_display.split('').map((c) => '*').join('') 

        return (
            <div className='basic-modal basic-modal--add-group'>
                <div className='basic-modal__head'>
                    <h2>{Language.getTitleCase('configure scim')}</h2>
                    <svg
                        className='basic-modal__close-button'
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={onClose}>
                        <path
                            d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                            fill='currentColor'></path>
                    </svg>
                </div>

                {'last_scim' in info && info.last_scim != 0 && 
                    <div className="basic-modal__head">
                        <h2>
                            {Language.getTitleCase("Last SCIM provisioning")}: {Util.epochToDate(info.last_scim).toString()}
                        </h2>
                    </div>
                }

                <div className="create-group">
                    <div className="grid-item">
                        <label htmlFor='scim-endpoint'>
                            <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase("endpoint for scim provisions")}
                                </span>
                                {Language.getTitleCase('SCIM Endpoint')}: &nbsp;
                            </span>
                            <span className="basic-modal__code">
                                {this.getSCIMEndpoint()}&nbsp;
                            </span>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                strokeWidth="1.25"
                                stroke="currentColor"
                                className="size-6"
                                cursor={'pointer'}
                                onClick={this.copyEndpoint}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                            </svg>
                        </label>
                        <label htmlFor='bearer-token'>
                            <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase("bearer token for authentication")}
                                </span>
                                {Language.getTitleCase('bearer token')}: &nbsp;
                            </span>
                            <span className="basic-modal__code">
                                {token_display}&nbsp;
                            </span>
                            {hide_bearer_token ?
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.25"
                                    stroke="currentColor"
                                    className="size-6"
                                    cursor={'pointer'}
                                    onClick={this.toggleBearer}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                :
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.25"
                                    stroke="currentColor" 
                                    className="size-6"
                                    cursor={'pointer'}
                                    onClick={this.toggleBearer}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            }
                            &nbsp;
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                strokeWidth="1.25"
                                stroke="currentColor"
                                className="size-6"
                                cursor={'pointer'}
                                onClick={this.refreshBearer}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                            &nbsp;
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                width="15"
                                height="15" 
                                viewBox="0 0 24 24"
                                strokeWidth="1.25"
                                stroke="currentColor"
                                className="size-6"
                                cursor={'pointer'}
                                onClick={this.copyBearer}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                            </svg>
                        </label>
                    </div>
                </div>
                <div className='basic-modal__button-container'>
                    <button
                        className='basic-modal__button basic-modal__button--clear'
                        onClick={onClose}>
                        {Language.getTitleCase('cancel')}
                    </button>
                    <button
                        className='basic-modal__button basic-modal__button--solid'
                        onClick={this.handleUpdate}>
                        {Language.getTitleCase('update')}
                    </button>
                </div>
            </div>
        );
    }
}

export const SCIMConfigModal = withStore(Klass);
