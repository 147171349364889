import React, {useEffect, useRef, useState} from "react";
import '@splidejs/react-splide/css';
import Language from "../../helpers/language";
import {TierBadge, TierNumber} from "../../helpers/consts";
import Util from "../../helpers/util";
import {useStore} from "../../store";


export const AvatarCard = (props) => {
    let {tier, showToast} = props

    const { page_cache, setPageCache } = useStore( x => x )
    const avatar_cache = (page_cache.avatar !== undefined)? page_cache.avatar: { user_count: null, total_percentage: null }

    const [state, setState] = useState({
        user_count: avatar_cache.user_count,
        total_percentage: avatar_cache.total_percentage,
    })
    const { user_count } = state

    useEffect(() => {
        Util.fetch_js( "/client/tier/list/", {},
            js => {
                const { total_percentage, user_count } = js

                setState(prev => ({ ...prev,
                    total_percentage,
                    user_count,
                }))
                setPageCache( 'avatar', { total_percentage, user_count } )
            }, showToast)

    }, []);

    const { total_percentage, ready_percentage } = Util.boundedPercentage( state.total_percentage )

    return (
        <div className="avatar-card">
            <span className="avatar-card-container">
                <img className="avatar-card-img" src={props.img}></img>
                <span className="avatar-card-tier" >
                    <img className="avatar-card-tier-img" src={TierBadge[tier]}></img>
                    <span className="avatar-card-tier-number">{TierNumber[tier]}</span>
                </span>

            </span>
            <div className="avatar-card-info">
                <h4 className="avatar-card-info-name">{props.name} {Language.getString("in")} {Language.getTitleCase(tier)} {Language.getTitleCase("Tier")}</h4>
                {user_count && total_percentage &&
                <h4 className="avatar-card-info-tenant">{Language.getTitleCase(total_percentage <= 50? 'Top': 'Bottom')} {ready_percentage}% {Language.getString("of")} {user_count}k {Language.getTitleCase("users")}</h4>
                }
            </div>
        </div>
    )
}
  