import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from 'uuid';
import QRCode from "react-qr-code";
import { Page, 
    View, 
    Svg, 
    Path, 
    Line, 
    Polyline, 
    Polygon, 
    Rect, 
    Circle, 
    Ellipse, 
    G, 
    Document, 
    StyleSheet, 
    PDFDownloadLink 
} from '@react-pdf/renderer';

import { useStore } from "../../store";
import Util from "../../helpers/util";

import { AnswerType, ContentType } from "../../helpers/consts";
import Language from "../../helpers/language";
import { Drip7Button } from "../drip7_button";

export const AnswerQRCodeWidget = props => {
    const { access, name, onChange } = props;
    const correct = ('correct' in props.answer) ? props.answer.correct : uuidv4();
    const pdf_filename = (name && name.length > 0 ? `${name}.pdf` : 'qrcode.pdf' )
    const ref_qrcode = React.createRef();
    
    useEffect(() => {
        onChange('answer', {}, { correct });
    }, [])

    const svgToReactPDF = (obj, idx) => {
        // https://abulasar.com/creating-a-react-component-to-render-normal-svg-in-react-pdf

        let name = obj.tagName !== undefined ? obj.tagName : obj.type;
        let props = Object.assign({'key': idx},
            ...Array.from(obj.attributes, ({name, value}) => ({[name]: value}))
        );
        let children =
            obj.children !== undefined ? (
            [...obj.children].map((c, i) => {
                return svgToReactPDF(c, i);
            })
        ) : (
            <></>
        );

        if (name === "svg") {
            return <Svg {...props}>{children}</Svg>;
        }
        else if (name === "path") {
            return <Path {...props}>{children}</Path>;
        }
        else if (name === "line") {
            return <Line {...props}>{children}</Line>;
        }
        else if (name === "polyline") {
            return <Polyline {...props}>{children}</Polyline>;
        }
        else if (name === "polygon") {
            return <Polygon {...props}>{children}</Polygon>;
        }
        else if (name === "rect") {
            return <Rect {...props}>{children}</Rect>;
        }
        else if (name === "circle") {
            return <Circle {...props}>{children}</Circle>;
        }
        else if (name === "ellipse") {
            return <Ellipse {...props}>{children}</Ellipse>;
        }
        else if (name === "g") {
            return <G {...props}>{children}</G>;
        }
        else {
            console.log(`oops: ${name}`)
            return <></>
        }
    }

    const GetPDF = () => {
        const qrcode = ref_qrcode.current;
        const pdf = svgToReactPDF(qrcode)

        const styles = StyleSheet.create({
            page: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            },
            view: {
                width: '256px',
                height: '256px'
            }
        });
        
          
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.view}>
                        {pdf}
                    </View>
                </Page>
            </Document>
        )
    }

    return (
        <>
            <div className='basic-modal__add-image-container width-100'>
                <QRCode ref={ref_qrcode} size={256}
                    viewBox={`0 0 256 256`}
                    value={correct}/>
            </div>
            <div className='basic-modal__add-image-container width-100'>
                <PDFDownloadLink document={<GetPDF />} fileName={pdf_filename}>
                    {({ blob, url, loading, error }) => (<Drip7Button
                            disabled={loading}
                            nameBtn='get-qrcode'>
                            {Language.getTitleCase('Download')}
                        </Drip7Button>
                    )}
                </PDFDownloadLink>
            </div>
        </>
    );
};
