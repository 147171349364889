import React, { useState } from "react";

import { AnswerType, ContentType } from "../../helpers/consts";
import { filterCategory } from "../../helpers/content";
import Language from "../../helpers/language";
import {SelectItems} from "../select_items";

export const CurriculumCollectionWidget = props => {
    const { onChange, categories, category_uids, tags, tag_uids, phishing } = props;

    const [state, setState] = useState({
        show_categories: categories.length > 0 || tags.length <= 0,
        show_tags: false,
    })
    const show_categories = state.show_categories || category_uids.length > 0
    const show_tags = state.show_tags || tag_uids.length > 0

    const clean_categories = categories.filter(x => {
        if ( x.phish_count == 0 && x.question_count == 0 ) {
            return false
        }
        else if ( !phishing ) {
            return x.phish_count <= 0
        }
        else {
            return x.question_count <= 0
        }
    }).map(x => (
        { ...x, name: `${x.name} ( ${x.question_count + x.phish_count} )` }
    ))

    const showStyle = (show) => {
        return ( !show ) ? { rotate: '-90deg' }: {}
    }

    const toggle = ( name ) => {
        setState(prev => ({...prev,
            [name]: !prev[name]
        }))
    }

    return (
        <div className='basic-modal__config'>
            <label className='basic-modal__label'
                onClick={() => toggle('show_categories')}>
                {Language.getTitleCase('categories')}
                &nbsp;
                <i className='drop-chevron' style={ showStyle(show_categories) }></i>
            </label>
            <div className='central__filters'>
                {show_categories &&
                <SelectItems
                    title={Language.getTitleCase( "categories" )}
                    selected={category_uids}
                    items={clean_categories}
                    onChange={x => onChange( x, tag_uids )}
                />
                }
            </div>

            {/*}
            <label className='basic-modal__label'
                   onClick={() => toggle('show_tags')}>
                {Language.getTitleCase('tags')}
                &nbsp;
                <i className='drop-chevron' style={ showStyle(show_tags) }></i>
            </label>
                <div className='central__filters'>
                    {show_tags &&
                    <SelectItems
                        title={Language.getTitleCase("tags")}
                        selected={tag_uids}
                        items={tags}
                        onChange={x => onChange( category_uids, x )}
                    />
                    }
                </div>
                {*/}
        </div>
    );
};
