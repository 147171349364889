import React, { useState } from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"

import { useStore } from "../../store"
import Util from "../../helpers/util"
import Language from "../../helpers/language"

import { ConfirmModal } from "../../modals/confirm_modal"

import { ContentType, AnswerType } from "../../helpers/consts"
import {
    buildQuestions, filterCategory,
    filterQuestions
} from "../../helpers/content"
import { InputModal } from "../../modals/input_modal"
import { QuestionModal } from "../../modals/admin/question_modal"
import { QuestionTableWidget } from "../../components/admin/question_table_widget"
import { Drip7Modal } from "../../modals/drip7_modal"
import {QuestionBulkWsModal} from "../../modals/admin/question_bulk_ws_modal";

//Define my view compon
export const QuestionsTab = (props) => {
    const { categories, question_categories, show_answers, tenant_uid, tenants, onRefreshCategories, showToast } = props

    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    const {is_msp} = usr_info?.tenant
    const tenant = tenants.filter((t) => t.uid == tenant_uid)
    // for now, only show the QR Code option to Whitworth
    const qrcode_allowed = tenant.length > 0 ? tenant[0].subdomain == 'whitworth' : false

    const [state, setState] = useState({
        search: "",
        questions_selected: {},
        category_uid: "",
        content_type: "",
        answer_type: "",
        sort_by: "",
        sort_by_reverse: false,
        show_question: false,
        show_add_question_file: false,
        confirm: null,
        show_only_tenant: is_msp,
    })
    const {
        search,
        category_uid,
        content_type,
        answer_type,
        questions_selected,
        sort_by,
        sort_by_reverse,
        confirm,
        show_question,
        show_add_question_file,
        show_only_tenant,
    } = state

    const handleCreateQuestion = () => {
        const show_question = {
            name: "",
            content_type: "text",
            answer_type: "multiple"
        }

        setState(prev => ({ ...prev,
            show_question
        }))
    }

    const handleModalClose = () => {
        onRefreshCategories()

        setState(prev => ({ ...prev,
            show_add_question_file: false,
            show_question: false
        }))
    }

    const handleAddFile = () => {
        setState(prev => ({ ...prev,
            show_add_question_file: true
        }))
    }

    const handleExportCsv = () => {
        const questions = buildQuestions( question_categories )
        const question_uids = questions.map( x => x.uid )

        //Export a member list, and then redirect to it
        Util.fetch_js( "/question/export/", { question_uids },
            js => {
                window.location.href = js.url
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        )
    }

    const handleSelectAll = (checked) => {
        //Get data
        const questions = buildQuestions( question_categories )
        let filtered_questions = filterQuestions(
            questions,
            search,
            category_uid,
            content_type,
            answer_type
        )

        //Should we check or send empty list?
        let questions_selected = {}
        if (checked) {
            filtered_questions.forEach( x => {
                questions_selected[x.uid] = true
            })
        }

        setState(prev => ({ ...prev,
            questions_selected
        }))
    }

    const handleRemove = () => {
        const question_uids = Object.keys(questions_selected)

        //Delete the questions
        Util.fetch_js( "/question/remove/", { question_uids },
            js => {
                showToast(Language.getSentenceCase('deleted question(s)'), 'successful')
                onRefreshCategories()
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        )

        //Reset the questions
        setState(prev => ({ ...prev,
            confirm: null,
            questions_selected: {}
        }))
    }

    const confirmAction = (action) => {
        const new_confirm = {
            action,
            msg: Language.getSentenceCase('delete questions'),
            yes: Language.getTitleCase('remove'),
            no: Language.getTitleCase('cancel')
        }

        setState(prev => ({ ...prev,
            confirm: new_confirm
        }))
    }

    const handleSortBy = (code) => {
        let sbr = sort_by == code ? !sort_by_reverse : false

        //Answers is special
        if ( code == "answers" ) {
            if ( sort_by == 'correct' ) {
                code = "incorrect"
            }
            else if ( sort_by == 'incorrect' ) {
                code = "unanswered"
            }
            else {
                code = "correct"
            }

            sbr = true
        }

        setState(prev => ({ ...prev,
            sort_by: code,
            sort_by_reverse: sbr
        }))
    }

    const handleChanged = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const sortQuestions = (filtered_questions) => {
        //Sort?
        if (sort_by == "") {
            return filtered_questions
        }

        console.log(sort_by)

        if (!sort_by_reverse) {
            if (sort_by == "created_on") {
                filtered_questions.sort((a, b) => a[sort_by] - b[sort_by])
            }
            else if ( sort_by == 'correct' || sort_by == 'incorrect' || sort_by == 'unanswered') {
                filtered_questions.sort((a, b) => a.answers[sort_by] - b.answers[sort_by])
            }
            else {
                filtered_questions.sort((a, b) =>
                    a[sort_by]
                        .toLowerCase()
                        .localeCompare(b[sort_by].toLowerCase())
                )
            }
        }
        else {
            if (sort_by == "created_on") {
                filtered_questions.sort((a, b) => b[sort_by] - a[sort_by])
            }
            else if ( sort_by == 'correct' || sort_by == 'incorrect' || sort_by == 'unanswered') {
                filtered_questions.sort((a, b) => b.answers[sort_by] - a.answers[sort_by])
            }
            else {
                filtered_questions.sort((a, b) =>
                    b[sort_by]
                        .toLowerCase()
                        .localeCompare(a[sort_by].toLowerCase())
                )
            }
        }

        return filtered_questions
    }


    let qc = ( show_only_tenant )? question_categories.filter( x => x.tenant_uid == tenant_uid ): question_categories
    const questions = buildQuestions( qc )
    let filtered_questions = filterQuestions(
        questions,
        search,
        category_uid,
        content_type,
        answer_type
    )
    filtered_questions = sortQuestions( filtered_questions )

    const { cat_title } = filterCategory( categories, category_uid )

    const content_type_title = content_type == "" ? Language.getTitleCase('content type') : content_type
    const answer_type_title = answer_type == "" ? Language.getTitleCase('answer type') : answer_type

    const only_tenant_klass = (show_only_tenant)? 'central__head-button--solid': 'central__head-button--clear'

    return (
        <div className='central__main central__main--questions'>
            <div className='central__head'>
                <div className='central__head-container'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('search by name, category') + '...'}
                            id="search"
                            value={search}
                            onChange={handleChanged}
                        />
                    </div>
                </div>

                <div className='central__head-button-container'>
                    {Object.keys(questions_selected).length > 0 && (
                        <button
                            type='button'
                            className='central__head-button central__head-button--remove mr-4'
                            name='remove-questions'
                            onClick={() => confirmAction(handleRemove) }>
                            {Language.getTitleCase('remove questions')}
                        </button>
                    )}
                    {Object.keys(questions_selected).length > 0 &&
                        <button
                            className='central__head-button central__head-button--clear'
                            onClick={handleExportCsv}>
                            {Language.getTitleCase( 'export as csv' )}
                        </button>
                    }
                    {Object.keys( questions_selected ).length <= 0 &&
                        <button
                            className='central__head-button central__head-button--clear'
                            onClick={handleAddFile}>
                            {Language.getTitleCase( 'add from file' )}
                        </button>
                    }
                    <button
                        className='central__head-button central__head-button--solid'
                        onClick={handleCreateQuestion}>
                        {Language.getTitleCase('create question')}
                    </button>
                </div>

                <div className='central__filters'>
                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton  title={cat_title}>
                            <Dropdown.Item
                                as='button'
                                onClick={handleChanged}
                                id='category_uid'
                                key='category_uid_-1'
                                value=''>
                                ---
                            </Dropdown.Item>
                            {Object.entries(question_categories).map(
                                ([key, category]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChanged}
                                        id='category_uid'
                                        value={category.uid}
                                        key={"category_uid_" + key}>
                                        {category.name}
                                    </Dropdown.Item>
                                )
                            )}
                        </DropdownButton>
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton title={content_type_title}>
                            <Dropdown.Item
                                as='button'
                                onClick={handleChanged}
                                id='content_type'
                                key='content_type_-1'
                                value=''>
                                ---
                            </Dropdown.Item>
                            {Object.entries(ContentType).filter(x => x[0] != ContentType.Phish).map(
                                ([key, content_type]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChanged}
                                        id='content_type'
                                        value={content_type}
                                        key={"content_type_" + key}>
                                        {Language.getTitleCase(content_type)}
                                    </Dropdown.Item>
                                )
                            )}
                        </DropdownButton>
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton title={answer_type_title}>
                            <Dropdown.Item
                                as='button'
                                onClick={handleChanged}
                                id='answer_type'
                                key='answer_type_-1'
                                value=''>
                                ---
                            </Dropdown.Item>
                            {Object.entries(AnswerType).map(
                                ([key, answer_type]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChanged}
                                        id='answer_type'
                                        value={answer_type}
                                        key={"answer_type_" + key}>
                                        {Language.getTitleCase(answer_type)}
                                    </Dropdown.Item>
                                )
                            )}
                        </DropdownButton>
                    </div>

                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton title={show_only_tenant ? Language.getTitleCase('Show only My Tenant') : Language.getTitleCase('show all tenants')  }>
                            <Dropdown.Item
                                as='button'
                                onClick={() => handleChanged({ target: { id: 'show_only_tenant', value: false }})}>
                                    {Language.getTitleCase('show all tenants')}
                            </Dropdown.Item>
                            <Dropdown.Item
                                as='button'
                                onClick={() => handleChanged({ target: { id: 'show_only_tenant', value: true }})}>
                                    {Language.getTitleCase('Show only My Tenant')}
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
            </div>

            <QuestionTableWidget
                show_answers={show_answers}
                show_tips={true}
                questions={filtered_questions}
                selected={questions_selected}
                onSortBy={handleSortBy}
                onSelectionChange={questions_selected => setState(prev => ({ ...prev, questions_selected })) }
                onQuestionClick={show_question => setState(prev => ({ ...prev, show_question })) }
            />

            {confirm != null && (
                <ConfirmModal
                    msg={confirm.msg}
                    yesTxt={confirm.yes}
                    noTxt={confirm.no}
                    onYes={() => confirm.action(true)}
                    onNo={() => setState(prev => ({ ...prev, confirm: null }))}
                />
            )}

            <Drip7Modal>
                {show_question && (
                    <QuestionModal
                        tenant_uid={tenant_uid}
                        qrcode_allowed={qrcode_allowed}
                        categories={question_categories}
                        question={show_question}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}

                {show_add_question_file && (
                    <QuestionBulkWsModal
                        tenant_uid={tenant_uid}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}
            </Drip7Modal>
        </div>
    )
}