import React from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { useStore } from "../../store";
import { AuthenticationType, Notification } from "../../helpers/consts";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { SSOConfigModal } from '../../modals/admin/sso_config_modal';
import { SCIMConfigModal } from "../../modals/admin/scim_config_modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Drip7Button } from "../../components/drip7_button";

//State wrapper
const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return <BaseComponent {...props} usr_info={usr_info} />;
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            selected_idx: 0,
            show_sso_groups: false,
            show_scim_modal: false,
            info: props.initial_info
        };

        this.logins = { 1: Language.getTitleCase('user/password'), 2: Language.getTitleCase('azure sso') };

        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleSSOUpdate = this.handleSSOUpdate.bind(this);
        this.handleSSOModal = this.handleSSOModal.bind(this);
        this.handleSCIMUpdate = this.handleSCIMUpdate.bind(this);
        this.handleSCIMModal = this.handleSCIMModal.bind(this);
        this.handleSelectAuthentication = this.handleSelectAuthentication.bind(this);
        this.handleCopyClipboard = this.handleCopyClipboard.bind(this)
        this.initAuthConfig = this.initAuthConfig.bind(this);
        this.handleFetchManifest = this.handleFetchManifest.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //If this is the same id, and the reload hasn't been changed, we don't want to change anything
        if ( this.state.info.uid == this.props.initial_info.uid &&
            this.props.reload == prevProps.reload ) {
            return
        }

        //If the props inital_info is empty, don't change anything 
        if (Object.keys(this.props.initial_info).length == 0) {
            return
        }

        //Setup my state info based on props
        const changed = this.props.initial_info.uid == "create_tenant";
        const info = this.initAuthConfig(this.props.initial_info);
        this.setState({ changed, info });
    }

    initAuthConfig(info) {
        let { auth_config } = info.tenant_sso;

        //set up some defaults, if necessary
        if (!auth_config) auth_config = {};
        if (!auth_config.auth) auth_config.auth = {};
        if (!auth_config.auth.clientId) auth_config.auth.clientId = "";
        if (!auth_config.auth.authority)
            auth_config.auth.authority =
                "https://login.microsoftonline.com/{your_tenant_id}";
        if (!auth_config.auth.redirectUri)
            auth_config.auth.redirectUri =
                "https://" + info.subdomain + ".drip7.com";
        if (!auth_config.cache)
            auth_config.cache = { storeAuthStateInCookie: false };
        if (!auth_config.cache.cacheLocation)
            auth_config.cache.cacheLocation = "sessionStorage";
        info.tenant_sso.auth_config = auth_config;

        return info;
    }

    handleSelectAuthentication(id) {
        var info = {
            ...this.state.info,
            auth_type: id
        };
        if (id == AuthenticationType.AZURE_SSO) {
            info = this.initAuthConfig(info);
        }
        this.setState({ info });

        //Store the value
        this.props.onChanged(info);
    }

    handleSSOUpdate(tenant_sso) {
        const { info } = this.state;
        info.tenant_sso = tenant_sso;
        this.setState({ info })
        this.props.onChanged(info);
    }

    handleSSOModal() {
        const { show_sso_groups } = this.state
        this.setState({ ...this.state, show_sso_groups: !show_sso_groups })
    }

    handleSCIMUpdate(tenant) {
        const { info } = this.state;
        info.bearer_token = tenant.bearer_token;
        this.setState({ info })
        this.props.onChanged(info);
    }

    handleSCIMModal() {
        const { show_scim_modal } = this.state
        this.setState({ ...this.state, show_scim_modal: !show_scim_modal })
    }

    handleChange(e) {
        let { info } = this.state;

        info[e.target.id] = e.target.value

        //Store the value
        this.setState({ info });
        this.props.onChanged(info);
        console.log('state',this.state)
    }

    handleChecked(e) {
        let { info } = this.state;

        if (e.target.id == 'trial_remaining') {
            info[e.target.id] = e.target.checked ? 30 : null
        }
        else {
            info[e.target.id] = e.target.checked
        }

        //Store the value
        this.setState({ info });
        this.props.onChanged(info);
    }

    handleCopyClipboard(url) {
        const { showToast } = this.props

        navigator.clipboard.writeText(url)
        showToast("Copied URL", "successful")
    }

    handleFetchManifest() {
        let { info } = this.state;
        const { showToast } = this.props
        
        Util.fetch_js( "/tenant/get_outlook_manifest/", { domain: info.subdomain },
            js => {
                window.location.href = js.url
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        )
    }

    render() {
        const { info, show_sso_groups, show_scim_modal } = this.state;
        const { tenant_uid, tenants, usr_info, showToast } = this.props;
        const login_title = this.logins[info.auth_type];

        const public_leaderboard_url = `https://${info.subdomain}.drip7.com/leaderboard/${info.uid}/`

        const notify_title = info.notify //(info.notify == Notification.NONE || info.notify == null || info.notify == undefined)? "user specified": info.notify

        const demo_tenant = tenants.find( x => x.uid == info.demo_tenant_uid ) || { name: Language.getTitleCase("No demo tenant") }

        const can_present = (tenant_uid != usr_info.tenant.uid)

        return (
            <div className="field-container">
                <div className='customization-fields' role='tabpanel' id='tab_panel_main_information'>
                    <div >

                        <div className='grid-item' >
                            <label htmlFor='company-name'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("appears as tenant name throughout admin page")}
                                    </span>
                                    {Language.getTitleCase('name')}&nbsp;
                                    <span className='req'>*</span>
                                </span>
                            </label>
                            <input
                                type='text'
                                name='company-name'
                                placeholder={Language.getTitleCase('my example company')}
                                required=''
                                onChange={this.handleChange}
                                id='name'
                                value={info.name}
                            />

                            <label htmlFor='subdomain'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("appears before .drip7.com in the app url - 'company.drip7.com'")}
                                    </span>
                                    {Language.getTitleCase('subdomain')}&nbsp;
                                    <span className='req'>*</span>
                                </span>
                            </label>
                            <input
                                type='text'
                                name='subdomain'
                                placeholder='ExampleCompany'
                                required=''
                                onChange={this.handleChange}
                                id='subdomain'
                                value={info.subdomain}
                            />

                            {usr_info.role.toLowerCase() == 'super admin' &&
                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name='trial-remaining-tagline'
                                    onChange={this.handleChecked}
                                    id='trial_remaining'
                                    checked={info.trial_remaining != null}
                                />
                                <span className='checkmark'></span>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("when checked, the tenant is on a 30-day trial")}
                                    </span>
                                <label htmlFor='trial-remaining-tagline'>
                                    {Language.getTitleCase('30-day trial')}
                                </label>
                                </span>
                            </div>
                            }

                            <label htmlFor='email-label'>
                                <span className='tool-tip__container'>
                                    {/* <span className="tool-tip__text">
                                        {Language.getSentenceCase("appears as tenant name throughout admin page")}
                                    </span> */}
                                {Language.getTitleCase('email label')}
                                </span>
                            </label>
                            <input
                                type='text'
                                name='email-label'
                                placeholder={Language.getTitleCase('example')+"CO"}
                                onChange={this.handleChange}
                                id='email_label'
                                value={info.email_label}
                            />

                            <label htmlFor='login-tagline'>
                                <span className='tool-tip__container'>
                                    {/* <span className="tool-tip__text">
                                        {Language.getSentenceCase("appears as tenant name throughout admin page")}
                                    </span> */}
                                {Language.getTitleCase('login tagline')}
                                </span>
                            </label>
                            <input
                                type='text'
                                name='login-tagline'
                                placeholder={Language.getSentenceCase('Welcome to example company')}
                                onChange={this.handleChange}
                                id='login_tagline'
                                value={info.login_tagline}
                            />
                        </div>

                        <div className="grid-item" >
                            <label htmlFor='support-email'>
                            <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("this email will be visible on the login view. If this field is left blank, nothing will appear")}
                                    </span>
                                {Language.getTitleCase('support email')}
                                </span>
                            </label>
                            <input
                                type='email'
                                name='support-email'
                                placeholder={Language.getSentenceCase('email for support')}
                                onChange={this.handleChange}
                                id='support_email'
                                value={info.support_email}
                            />

                            <label htmlFor='friendly-name'>
                            <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("the name that shows how the sender wants to be identified")}
                                    </span>
                                {Language.getTitleCase('friendly name')}
                                </span>
                            </label>
                            <input
                                type='email'
                                name='support_email_name'
                                placeholder={Language.getSentenceCase('friendly name')}
                                onChange={this.handleChange}
                                id='support_email_name'
                                value={info.support_email_name}
                            />

                            <label htmlFor='support-phone'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("this number will be visible on the login view. if this field is left blank, nothing will appear")}
                                    </span>
                                {Language.getTitleCase('support phone')}
                                </span>
                            </label>
                            <input
                                type='tel'
                                name='support-phone'
                                placeholder='000-000-0000'
                                onChange={this.handleChange}
                                id='support_phone'
                                value={info.support_phone}
                            />

                            <label htmlFor='support-text'>
                            <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("this number will be visible on the login view. if this field is left blank, nothing will appear")}
                                    </span>
                                {Language.getTitleCase('support text')}
                                </span>
                            </label>
                            <input
                                type='text'
                                name='support-text'
                                placeholder='000-000-0000'
                                onChange={this.handleChange}
                                id='support_sms'
                                value={info.support_sms}
                            />

                            <label htmlFor='support-url'>
                            <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("this support center link appears in the user profile drop-down menu")}
                                    </span>
                                {Language.getTitleCase('support url')}
                                </span>
                            </label>
                            <input
                                type='text'
                                name='support-url'
                                placeholder={Language.getSentenceCase("Questions? Visit Our Site")}
                                onChange={this.handleChange}
                                id='support_url'
                                value={info.support_url}
                            />

                            
                        </div>


                    </div>
                    <div>



                        <div className="grid-item">
                            <div className='customization-checkbox' style={{ margin: "0px"}}>
                                <input
                                    type='checkbox'
                                    id='public_leaderboard'
                                    name='public-leaderboard'
                                    onChange={this.handleChecked}
                                    checked={info.public_leaderboard}
                                    aria-label='public-leaderboard'
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='public-leaderboard'>
                                    <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("this links directly to a view of the leaderboard")}
                                        </span>
                                        {Language.getTitleCase('public leaderboard')}
                                    </span>
                                </label>
                            </div>
                            <input
                                type='text'
                                id='leaderboard_url'
                                name='leaderboard_url'
                                aria-label="public leaderboard url"
                                value={public_leaderboard_url}
                                disabled={!info.public_leaderboard}
                                readOnly={true}
                                onClick={() => this.handleCopyClipboard(public_leaderboard_url)}
                            />

                            <label htmlFor='profile-screen-message'>
                            <span className='tool-tip__container'>
                                    {/* <span className="tool-tip__text">
                                    
                                        {Language.getSentenceCase("Profile screen message Tool tip")}
                                    </span> */}
                                {Language.getTitleCase('Profile Screen Message')}
                                </span>
                            </label>
                            <textarea
                                name='profile-screen-message'
                                placeholder={Language.getSentenceCase('Hello Dear Friend!')}
                                id='profile_screen'
                                value={info.profile_screen}
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className="grid-item">
                        <div className='dropdown dropdown--attached'>
                                <DropdownButton title={login_title}>
                                    {Object.keys(this.logins).map(k => (
                                        <Dropdown.Item
                                            as='button'
                                            onClick={e => this.handleSelectAuthentication(k)}
                                            id={k}
                                            key={k}>
                                            {this.logins[k]}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                            {info.auth_type == AuthenticationType.AZURE_SSO && (
                                <Drip7Button
                                    name='show-sso-modal'
                                    onClick={this.handleSSOModal}>
                                    {Language.getTitleCase('configure single sign-on')}
                                </Drip7Button>
                            )}
                            <Drip7Button
                                name='show-scim-modal'
                                onClick={this.handleSCIMModal}>
                                {Language.getTitleCase('configure scim')}
                            </Drip7Button>
                            {info.auth_type == AuthenticationType.USER_PWD && (
                                <div className='customization-checkbox'>
                                    <input
                                        type='checkbox'
                                        aria-label="checkbox"
                                        name='can-register-tagline'
                                        onChange={this.handleChecked}
                                        id='can_register'
                                        checked={info.can_register}
                                    />
                                    <span className='checkmark'></span>
                                    <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("when checked, the register button will appear on the login page, to allow for registering a new user")}
                                        </span>
                                    <label htmlFor='can_register'>
                                        {Language.getTitleCase('Allow anyone to register')}
                                    </label>
                                    </span>
                                </div>
                            )}
                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name='users-can-edit'
                                    readOnly
                                    id='user_editing'
                                    onChange={this.handleChecked}
                                    checked={info.user_editing}
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='user_editing'>
                                <span className='tool-tip__container'>
                                        {/* <span className="tool-tip__text">
                                            {Language.getSentenceCase("__replace__ Tool tip")}
                                        </span> */}
                                    {Language.getTitleCase('Users can edit their information')}
                                    </span>
                                </label>
                            </div>
                            {info.auth_type == AuthenticationType.USER_PWD && 
                                <div className='customization-checkbox'>
                                    <input
                                        type='checkbox'
                                        aria-label="checkbox"
                                        name='users-can-delete'
                                        readOnly
                                        id='allow_delete'
                                        onChange={this.handleChecked}
                                        checked={info.allow_delete}
                                    />
                                    <span className='checkmark'></span>
                                    <label htmlFor='allow_delete'>
                                    <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("when checked, user can have their data completely deleted from our database")}
                                        </span>
                                        {Language.getTitleCase('Users can delete their account and data')}
                                    </span>
                                    </label>
                                </div>
                            }

                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name='allow_anonymity'
                                    readOnly
                                    id='allow_anonymity'
                                    onChange={this.handleChecked}
                                    checked={info.allow_anonymity}
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='allow_anonymity'>
                                <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("when checked, users can optionally be anonymous on the leaderboard, so others can not see their name")}
                                        </span>
                                    {Language.getTitleCase('allow leaderboard anonymity')}
                                    </span>
                                </label>
                            </div>

                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name='reset_points_yearly'
                                    readOnly
                                    id='reset_points_yearly'
                                    onChange={this.handleChecked}
                                    checked={info.reset_points_yearly}
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='reset_points_yearly'>
                                <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("resets all users points to zero annually")}
                                        </span>
                                    {Language.getTitleCase('reset points yearly')}
                                    </span>
                                </label>
                            </div>

                            {can_present &&
                                <div className='customization-checkbox'>
                                    <input
                                        type='checkbox'
                                        name='presentation'
                                        aria-label="checkbox"
                                        readOnly
                                        id='presentation'
                                        onChange={this.handleChecked}
                                        checked={info.presentation}
                                    />
                                    <span className='checkmark'></span>
                                    <label htmlFor='presentation'>
                                    <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("this links directly to a view of the leaderboard")}
                                        </span>
                                        {Language.getTitleCase('presentation mode **Dangerous**')}
                                    </span>
                                    </label>
                                </div>
                            }

                            <div className='dropdown dropdown--attached'>
                                <label htmlFor='notify'>
                                <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("how often should users get notifications. user specified says that users can choose daily, weekly, or never.")}
                                        </span>
                                    {Language.getTitleCase('Notification override')}
                                    </span>
                                </label>
                                <DropdownButton id="notify" title={Language.getTitleCase(notify_title)}>
                                    {Object.entries(Notification).map( ([key, value]) => (
                                        <Dropdown.Item
                                            as='button'
                                            onClick={this.handleChange}
                                            id="notify"
                                            key={key}
                                            value={value}>
                                            {Language.getTitleCase(value)}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>

                            {tenants.length > 1 &&
                                <div className='dropdown dropdown--attached'>
                                    
                                    <label htmlFor='demo_tenant_uid'>
                                    <span className='tool-tip__container'>
                                        <span className="tool-tip__text">
                                            {Language.getSentenceCase("this only appears for tenants that have multiple subtenants, and specifies which tenant is used to demo the app")}
                                        </span>    {Language.getTitleCase( 'Select a demo tenant for registration' )}
                                    </span>
                                    </label>
                                    <DropdownButton id="demo_tenant_uid"
                                                    title={demo_tenant.name}>
                                        <Dropdown.Item
                                            as='button'
                                            onClick={this.handleChange}
                                            id="demo_tenant_uid"
                                            key={"demo_tenant_key_-1"}
                                            value={""}>
                                            ---
                                        </Dropdown.Item>
                                        {Object.entries( tenants.filter(x => x.uid != tenant_uid ) ).map( ( [key, value] ) => (
                                            <Dropdown.Item
                                                as='button'
                                                onClick={this.handleChange}
                                                id="demo_tenant_uid"
                                                key={`demo_tenant_key_${key}`}
                                                value={value.uid}>
                                                {value.name}
                                            </Dropdown.Item>) )}
                                    </DropdownButton>
                                </div>
                            }
                            <Drip7Button
                                name='outlook_ext_url'
                                className='solid-button'
                                onClick={this.handleFetchManifest}>
                                {Language.getTitleCase('outlook phish extension manifest')}
                            </Drip7Button>
                        </div>

                    </div>

                </div>
                <Drip7Modal>
                    {show_sso_groups && (
                        <SSOConfigModal
                            tenant={info}
                            onUpdate={this.handleSSOUpdate}
                            onClose={this.handleSSOModal}
                            showToast={showToast}
                        />
                    )}
                    {show_scim_modal &&
                        <SCIMConfigModal
                            tenant={info}
                            onUpdate={this.handleSCIMUpdate}
                            onClose={this.handleSCIMModal}
                            showToast={showToast}
                        />
                    }
                </Drip7Modal>
            </div>
        );
    }
}

export const TenantSettingsTab = withStore(Klass);
