import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import { findContent } from "../../helpers/content";
import Language from "../../helpers/language";

import {Access, AnswerType, ContentType, Languages} from "../../helpers/consts";
import { ContentTextWidget } from "../../components/admin/content_text_widget";
import { ExplanationTextWidget } from "../../components/admin/explanation_text_widget";
import { ContentImageWidget } from "../../components/admin/content_image_widget";
import { ContentVideoWidget } from "../../components/admin/content_video_widget";
import { ContentPhishWidget } from "../../components/admin/content_phish_widget";
import { AnswerBooleanWidget } from "../../components/admin/answer_boolean_widget";
import { AnswerMultipleWidget } from "../../components/admin/answer_multiple_widget";
import { AnswerFillWidget } from "../../components/admin/answer_fill_widget";
import { AnswerSliderWidget } from "../../components/admin/answer_slider_widget";
import { AnswerCheckboxWidget } from "./answer_checkbox_widget";
import { AnswerQRCodeWidget } from "./answer_qrcode_widget";


export const QuestionBuilderWidget = (props) => {
    const {access, language, question, contents, categories, tenant_uid, qrcode_allowed, onChange, onContentChange, onFileChange, showToast} = props

    const clean_categories = categories.filter( x => (x.phish_count <= 0) )
    const category = clean_categories.find(x => x.uid == question.category_uid ) || {name: "Categories"}

    const [content, placeholder, _] = findContent( language, contents );

    const ct = question.content_type.toLowerCase()
    const at = question.answer_type.toLowerCase()

    return (
        <div className='basic-modal__field-container basic-modal__field-container--mt-10'>
            <div className='basic-modal__field basic-modal__field--add-question'>
                <label
                    className='basic-modal__label'
                    htmlFor='newQuestionContent'>
                    {Language.getTitleCase('content type')}
                </label>
                <div className='dropdown dropdown--attached dropdown--add-question'>
                    <DropdownButton title={Language.getTitleCase(question.content_type)}>
                        {Object.entries(ContentType)
                            .filter(([_, c]) => c != "Phish")
                            .map(([key, content_type]) => (
                                <Dropdown.Item
                                    as='button'
                                    onClick={onChange}
                                    id='content_type'
                                    value={content_type}
                                    key={"content_type_" + key}>
                                    {Language.getTitleCase(content_type)}
                                </Dropdown.Item>
                            ))}
                    </DropdownButton>
                </div>
            </div>

            <div className='basic-modal__field basic-modal__field--add-question'>
                <label
                    className='basic-modal__label'
                    htmlFor='newQuestionAnsType'>
                    {Language.getTitleCase('answer type')}
                </label>
                <div className='dropdown dropdown--attached dropdown--add-question'>
                    <DropdownButton title={Language.getTitleCase(question.answer_type)}>
                        {Object.entries(AnswerType).filter(([key, answer_type]) => {
                            return qrcode_allowed || key != 'QRCode'
                        }).map(([key, answer_type]) => (
                                <Dropdown.Item
                                    as='button'
                                    onClick={onChange}
                                    id='answer_type'
                                    value={answer_type}
                                    key={"answer_type_" + key}>
                                    {Language.getTitleCase(answer_type)}
                                </Dropdown.Item>
                            )
                        )}
                    </DropdownButton>
                </div>
            </div>

            <div className='basic-modal__field-containeradd-question basic-modal__add-question--text width-100'>
                {ct == "text" && (
                    <ContentTextWidget
                        access={access}
                        question={question}
                        content={content.content}
                        showToast={showToast}
                        onChange={onContentChange}
                    />
                )}
                {ct == "image" && (
                    <ContentImageWidget
                        access={access}
                        question={question}
                        content={content.content}
                        showToast={showToast}
                        onChange={onContentChange}
                        onFile={onFileChange}
                    />
                )}
                {ct == "video" && (
                    <ContentVideoWidget
                        access={access}
                        question={question}
                        content={content.content}
                        showToast={showToast}
                        onChange={onContentChange}
                        onFile={onFileChange}
                    />
                )}
                {ct == "phish" && (
                    <ContentPhishWidget
                        access={access}
                        question={question}
                        content={content.content}
                        showToast={showToast}
                        onChange={onContentChange}
                    />
                )}

                <div className="basic-modal__field--add-question">
                    <label className='basic-modal__label'
                           htmlFor='newQuestionAns'>
                        {Language.getTitleCase('answer')}
                    </label>
                    {at == "boolean" && (
                        <AnswerBooleanWidget
                            access={access}
                            answer={content.answer}
                            placeholder={placeholder.answer}
                            onChange={onContentChange}
                        />
                    )}
                    {(at == "multiple" || at == "buttons") && (
                        <AnswerMultipleWidget
                            access={access}
                            answer={content.answer}
                            placeholder={placeholder.answer}
                            onChange={onContentChange}
                        />
                    )}
                    {(at == "fill" || at == "area") && (
                        <AnswerFillWidget
                            access={access}
                            answer={content.answer}
                            placeholder={placeholder.answer}
                            onChange={onContentChange}
                        />
                    )}
                    {at == "slider" && (
                        <AnswerSliderWidget
                            access={access}
                            answer={content.answer}
                            placeholder={placeholder.answer}
                            onChange={onContentChange}
                        />
                    )}
                    {at == "checkbox" && (
                        <AnswerCheckboxWidget
                            access={access}
                            answer={content.answer}
                            placeholder={placeholder.answer}
                            onChange={onContentChange}
                        />
                    )}
                    {at == "qr code" && (
                        <AnswerQRCodeWidget
                            access={access}
                            answer={content.answer}
                            name={question.name}
                            onChange={onContentChange}
                        />
                    )}
                </div>

                <ExplanationTextWidget
                    access={access}
                    explanation={content.explanation}
                    showToast={showToast}
                    onChange={onContentChange}
                />
            </div>
        </div>
    );
}
