import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl,
    Form
} from "react-bootstrap";
import { Link } from "react-router-dom";

export const AnswerTypeNone = React.forwardRef((props, ref) => {
    const { onChanged } = props;

    React.useImperativeHandle(ref, () => ({
        reset: () => {
        },
        attemptAnswer: () => {
            onChanged( true, true, true )
            return true;
        }
    }));

    return (
        <div className='daily-questions__answers daily-questions__answers--slider'>
        </div>
    );
});
