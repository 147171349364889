import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { QueueModal } from "../../modals/admin/queue_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import { QueueActiveWidget } from "../../components/admin/queue_active_widget";
import { queueAudience } from "../../helpers/content";
import { Notification, ViewMode } from "../../helpers/consts";


export const QueueTrackListWidget = (props) => {
    const { queues, selected, onChange, onShowQueue } = props

    const handleSelect = (checked, uid) => {
        let { selected } = props

        if (checked) {
            selected[uid] = true;
        }
        else if (uid in selected) {
            delete selected[uid];
        }

        onChange( { target: { id: "selected", value: selected }})
    }

    const handleSelectAll = (checked) => {
        let { queues } = props
        let selected = {}

        //Check all?
        if ( checked ) {
            for (let i = 0; i < queues.length; i++) {
                selected[queues[i].uid] = true;
            }
        }

        onChange( { target: { id: "selected", value: selected }})
    }

    return (
        <table
            className='management__table'
            role='region'
            aria-live='polite'>
            <tbody>
                <tr className='management__table-row management__table-row--head'>
                    <th className='management__table-head management__table-head--input'>
                        <div className='d7__checkbox-container'>
                            <input
                                className='d7__checkbox'
                                type='checkbox'
                                aria-label="checkbox"
                                onChange={e => handleSelectAll( e.target.checked ) }
                            />
                            <span className='dash'></span>
                        </div>
                    </th>
                    <th className='management__table-head management__table-head--name'>
                        <div className='management__table-heading'>
                            {Language.getTitleCase('queue name')}
                        </div>
                    </th>
                    <th className='management__table-head'>
                        <div className='management__table-heading'>
                            {Language.getTitleCase('delivery')}
                        </div>
                    </th>
                    <th className='management__table-head'>
                        <div className='management__table-heading'>
                            {Language.getTitleCase('active')}
                        </div>
                    </th>
                    <th className='management__table-head'>
                        <div className='management__table-heading'>
                            {Language.getTitleCase('leaderboard')}
                        </div>
                    </th>
                    <th className='management__table-head'>
                        <div className='management__table-heading'>
                            {Language.getTitleCase('audience')}
                        </div>
                    </th>
                    <th className='management__table-head'>
                        <div className='management__table-heading'>
                            {Language.getTitleCase('content count')}
                        </div>
                    </th>
                </tr>
                {Object.entries(queues).map( ([key, queue]) => (
                    <tr className='management__table-row management__table-row--body'
                        key={"queue_row_" + key}>
                        <td className='management__table-data management__table-data--input'>
                            <div className='d7__checkbox-container'>
                                <input
                                    className='d7__checkbox'
                                    type='checkbox'
                                    aria-label="checkbox"
                                    checked={ queue.uid in selected }
                                    onChange={e => handleSelect( e.target.checked, queue.uid ) } />
                                <span className='checkmark'></span>
                            </div>
                        </td>
                        <td className='management__table-data management__table-data--name'
                            onClick={() => onShowQueue( queue )}>
                            <div className='management__table-text'>
                                {queue.name}
                            </div>
                        </td>
                        <td className='management__table-data'
                            onClick={() => onShowQueue( queue )}>
                            <div className='management__table-text'>
                                {queue.delivery}
                            </div>
                        </td>
                        <td className='management__table-data'
                            onClick={() => onShowQueue( queue )}>
                            <QueueActiveWidget queue={queue} />
                        </td>
                        <td className='management__table-data'
                            onClick={() => onShowQueue( queue )}>
                            <div className='management__table-text'>
                                {queue.leaderboard? "True": "False"}
                            </div>
                        </td>
                        <td className='management__table-data'
                            onClick={() => onShowQueue( queue )}>
                            <div className='management__table-text'>
                                {queueAudience(queue)}
                            </div>
                        </td>
                        <td className='management__table-data'
                            onClick={() => onShowQueue( queue )}>
                            <div className='management__table-text'>
                                { queue.curriculum.delivery_count }
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
