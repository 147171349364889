import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { QueueModal } from "../../modals/admin/queue_modal";
import { QueueTrackModal } from "../../modals/admin/queue_track_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import { QueueActiveWidget } from "../../components/admin/queue_active_widget";
import { queueAudience } from "../../helpers/content";
import { Notification, ViewMode } from "../../helpers/consts";
import { QueueTrackListWidget } from "../../components/admin/queue_track_list_widget";
import { QueueTrackGraphicalWidget } from "../../components/admin/queue_track_graphical_widget";
import {Drip7Button} from "../../components/drip7_button";


export const Queue = (props) => {
    const { showToast, isPhishing, isPolicy } = props

    const [state, setState] = useState({
        tenant_uid: props.tenant_uid,
        loaded: false,
        show_queue: null,
        show_queue_track: null,
        search: "",
        modes: {},
        queues: [],
        queue_tracks: [],
        selected: {},
        confirm: null,
    })
    const { tenant_uid, loaded, show_queue, show_queue_track, search, modes, queues, queue_tracks, selected, confirm} = state

    // let tenant = {'subscriptions': []};
    // for (let i = 0; i < tenants.length; i++) {
    //     if (tenants[i].uid == tenant_uid) {
    //         tenant = tenants[i];
    //     }
    // }

    // const { is_msp } = usr_info.tenant
    // const has_content = is_msp || tenant.subscriptions.find(x => x.toLowerCase() == "content") != undefined || true
    // const has_phishing = is_msp || tenant.subscriptions.find(x => x.toLowerCase() == "phishing") != undefined || true

    useEffect(() => {
        updateList( props.tenant_uid )
        if(isPhishing !== true && isPolicy !== true){
            props.onPathChange("queue")
        } 
    }, [])

    useEffect(() => {
        if ( tenant_uid != props.tenant_uid ) {
            updateList( props.tenant_uid )
        }
    })

    const updateList = ( tenant_uid ) => {
        Util.fetch_js( "/queue/list/", { tenant_uid, detailed: true, policy_only: isPolicy },
            js => {
                const { queues, queue_tracks } = js;
                const questionQueues = isPhishing? queues.filter(track => track.delivery.toLowerCase()=='phish') : queues.filter(track => track.delivery.toLowerCase()!='phish')

                console.log(queues)

                setState(prev => ({ ...prev,
                    queues:questionQueues,
                    queue_tracks,
                    tenant_uid,
                    loaded: true,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const filterQueues = (search, queues) => {
        //Do nothing
        if (search == "") {
            return queues;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < queues.length; i++) {
            const queue = queues[i];
            if (queue.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(queue);
            }
        }

        return result;
    }

    const handleChange = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }

    const handleUpdateQueues = (new_queues) => {
        const q_l = {}
        new_queues.forEach( x => { q_l[x.uid] = x })

        setState(prev => ({ ...prev,
            queues: queues.map( x => (x.uid in q_l)? q_l[x.uid]: x  )
        }))
    }

    const handleModalClose = (queue) => {
        updateList( tenant_uid )
        setState(prev => ({ ...prev,
            show_queue: null,
            show_queue_track: null,
        }))
    }

    const handleRemove = () => {
        Util.fetch_js( "/queue/remove/", { queue_uids: Object.keys(selected) },
            js => {
                showToast("Deleted queue(s)", "successful");
                updateList( tenant_uid )
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState( prev => ({ ...prev,
            confirm: null,
            selected: {}
        }))
    }

    const confirmAction = (action) => {
        const confirm = {
            action,
            msg: Language.getTitleCase('delete queues'),
            yes: Language.getTitleCase('remove'),
            no: Language.getTitleCase('cancel')
        };
        setState( prev => ({ ...prev,
            confirm
        }))
    }

    const handleShowQueue = (show_queue) => {
        setState( prev => ({ ...prev,
            show_queue
        }))
    }

    const handleShowTrack = ( show_queue_track ) => {
        if ( show_queue_track.uid == "unbound" ) {
            return
        }

        setState( prev => ({ ...prev,
            show_queue_track
        }))
    }

    const handleChangeMode = (e) => {
        const modes = { ...state.modes,
            [e.target.id]: e.target.value,
        }
        setState( prev => ({ ...prev,
            modes
        }))
    }

    //Build the queue_tracks from the filtered queues
    let safe_modes = { ...modes }
    let queue_tracks_lookup = { "unbound": { name: "Unbound", uid: "unbound", queues: []} }
    filterQueues(search, queues).forEach( q => {
        let uid = (q.queue_track == null)? "unbound": q.queue_track.uid
        if ( uid in queue_tracks_lookup ) {
            queue_tracks_lookup[uid].queues.push( q )
        }
        else {
            queue_tracks_lookup[uid] = { ...q.queue_track, queues: [q] }
        }

        //Set default safe_modes
        if ( !(uid in safe_modes) ) {
            safe_modes[uid] = ViewMode.GRAPHICAL
        }
    })

    //Ensure the safe modes have unbound listed
    if ( !('unbound' in safe_modes) ) {
        safe_modes['unbound'] = ViewMode.GRAPHICAL
    }

    //Add empty tracks
    if ( search == "" ) {
        queue_tracks.forEach(qt => {
            //Already accounted for, do nothing
            if ( qt.uid in queue_tracks_lookup ) {
                console.log("Dup")
                return
            }

            //Add in a new blank queue track
            queue_tracks_lookup[qt.uid] = { ...qt, queues: [] }

            //Set default safe_modes
            if ( !(qt.uid in safe_modes) ) {
                safe_modes[qt.uid] = ViewMode.GRAPHICAL
            }
        })
    }

    Object.keys(queue_tracks_lookup).forEach(uid => {
        if ( safe_modes[uid] != ViewMode.HISTORIC ) {
            queue_tracks_lookup[uid].queues = queue_tracks_lookup[uid].queues.filter( x => x.active )
        }
        else {
            queue_tracks_lookup[uid].queues = queue_tracks_lookup[uid].queues.filter( x => !x.active )
        }
    })

        //Arrange the queue's
    let qt_keys = Object.values(queue_tracks).sort((a,b) => a.name.localeCompare(b.name)).filter( x => x.uid != 'unbound').map( x => x.uid )
    if ( 'unbound' in queue_tracks_lookup ) {
        qt_keys = ['unbound'].concat( qt_keys )
    }

    const qt_ary = (loaded)? qt_keys.map( uid => queue_tracks_lookup[uid] ): []

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                {isPhishing !== true && isPolicy !== true && <nav className='central__nav'>
                    <ul className="central__nav-list">
                        <li className="central__nav-item central__nav-item--small central__nav-item--selected">
                            <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill='var(--drip7-primary-color)' d="M22.2187 5.39003C23.332 4.86999 24.5937 4.86999 25.707 5.39003L41.9609 12.8935C42.5547 13.1906 43 13.7849 43 14.4536C43 15.1965 42.5547 15.7908 41.9609 16.088L25.707 23.5914C24.5937 24.1114 23.332 24.1114 22.2187 23.5914L5.96484 16.088C5.37109 15.7908 5 15.1965 5 14.4536C5 13.7849 5.37109 13.1906 5.96484 12.8935L22.2187 5.39003ZM41.9609 22.4027C42.5547 22.6999 43 23.2942 43 23.9629C43 24.7058 42.5547 25.3001 41.9609 25.5973L25.707 33.1007C24.5937 33.6207 23.332 33.6207 22.2187 33.1007L5.96484 25.5973C5.37109 25.3001 5 24.7058 5 23.9629C5 23.2942 5.37109 22.6999 5.96484 22.4027L9.97266 20.5455L21.2539 25.7458C22.9609 26.563 24.9648 26.563 26.6719 25.7458L37.9531 20.5455L41.9609 22.4027ZM26.6719 35.2551L37.9531 30.0547L41.9609 31.912C42.5547 32.2092 43 32.8035 43 33.4721C43 34.2151 42.5547 34.8094 41.9609 35.1065L25.707 42.61C24.5937 43.13 23.332 43.13 22.2187 42.61L5.96484 35.1065C5.37109 34.8094 5 34.2151 5 33.4721C5 32.8035 5.37109 32.2092 5.96484 31.912L9.97266 30.0547L21.2539 35.2551C22.9609 36.0723 24.9648 36.0723 26.6719 35.2551Z" />
                            </svg>
                            {Language.getTitleCase('queue')}
                        </li>
                    </ul>
                </nav>} 
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by curriculum') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        {Object.keys(selected).length > 0 && (
                            <Drip7Button
                                color='red-500'
                                onClick={() => confirmAction(handleRemove) }>
                                {Language.getTitleCase('remove queues')}
                            </Drip7Button>
                        )}
                        { !isPhishing && !isPolicy && (
                            <Drip7Button
                                onClick={() => handleShowTrack( true )}>
                                {Language.getTitleCase('create track')}
                            </Drip7Button>
                        )}
                        <Drip7Button
                            onClick={() => handleShowQueue( true )}>
                            {!isPolicy? Language.getTitleCase('assign curriculum'): Language.getTitleCase('assign policy')}
                        </Drip7Button>
                    </div>
                </div>

                {Object.entries(qt_ary).map(([key, queue_track]) => (
                    <div key={`queue_track_wrapper-${key}`}
                         className="queue-track-wrapper m-8">
                        {!isPolicy &&
                        <div className="queue-track-header">
                            {queue_track.uid != "unbound" &&
                            <label className="queue-track-header--item"
                                   onClick={() => handleShowTrack( queue_track )}>
                                {queue_track.name}
                            </label>
                            }

                            <DropdownButton id="mode" title={`${safe_modes[queue_track.uid]} View`}
                                            className="queue-track-header--item">
                                {Object.entries(ViewMode).map( ([key, value]) => (
                                    <Dropdown.Item
                                        key={`mode-${key}`}
                                        as='button'
                                        onClick={handleChangeMode}
                                        id={queue_track.uid}
                                        value={value}>
                                        {Language.getTitleCase(value)}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                        }

                        {(safe_modes[queue_track.uid] == ViewMode.LIST || safe_modes[queue_track.uid] == ViewMode.HISTORIC) &&
                            <QueueTrackListWidget
                                queues={queue_track.queues}
                                selected={selected}
                                onChange={handleChange}
                                onShowQueue={handleShowQueue}
                                />
                        }

                        {safe_modes[queue_track.uid] == ViewMode.GRAPHICAL &&
                            <QueueTrackGraphicalWidget
                                queue_track={queue_track}
                                queues={queue_track.queues}
                                selected={selected}
                                isPolicy={isPolicy}
                                showToast={showToast}
                                onChange={handleChange}
                                onRefresh={() => updateList( props.tenant_uid )}
                                onShowQueue={handleShowQueue}
                                onUpdateQueues={handleUpdateQueues}
                            />
                        }

                        {queue_track.queues.length == 0 &&
                            <div className="text-center mt-6">
                                <h3 className="mt-2 text-sm font-semibold text-gray-900">No queues</h3>
                                <p className="mt-1 text-sm text-gray-500">Get started by assigning a new queue.</p>
                                <div className="my-6">
                                    <Drip7Button
                                        onClick={() => handleShowQueue( true )}>
                                        {!isPolicy? Language.getTitleCase('assign curriculum'): Language.getTitleCase('assign policy')}
                                    </Drip7Button>
                                </div>
                            </div>
                        }
                    </div>
                ))}

                {confirm != null && (
                    <ConfirmModal
                        msg={confirm.msg}
                        yesTxt={confirm.yes}
                        noTxt={confirm.no}
                        onYes={() => confirm.action(true)}
                        onNo={() => setState(prev => ({...prev, confirm: null}))}
                    />
                )}
            </div>

            <Drip7Modal>
                {show_queue != null && (
                    <QueueModal
                        queue={show_queue}
                        tenant_uid={tenant_uid}
                        showToast={showToast}
                        onClose={handleModalClose}
                        phishQueue={isPhishing}
                        policyQueue={isPolicy}
                    />
                )}

                {show_queue_track != null && (
                    <QueueTrackModal
                        queue_track={show_queue_track}
                        tenant_uid={tenant_uid}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}
            </Drip7Modal>
        </div>
    );
}
