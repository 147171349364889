import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Language from "../../helpers/language";

import {AnswerType, BlockMode, ContentType, Languages} from "../../helpers/consts";
import {InputWithArrows} from "../input_with_arrows";
import { CurriculumGuidedSlotWidget } from "./curriculum_guided_slot_widget";
import PlusImg from '../../assets/images/shared/plus.svg'
import {
    CurriculumSpecificSlotWidget
} from "./curriculum_specific_slot_widget";

export const CurriculumBlockWidget = props => {
    const { block, slots, category_summaries, tag_summaries, category_questions, category_uids, question_uids, onEditSlot, onChange, onRemove, showToast } = props;

    const [state, setState] = useState({
    });

    const handleChange = (e) => {
        if ( e.target.id == 'mode' && slots.length > 0 ) {
            showToast(Language.getSentenceCase("Please first remove all slots"), "failure")
            return
        }

        let block = props.block
        block[e.target.id] = e.target.value

        onChange( block )
    }

    const handleAddSlot = () => {
        let block = props.block
        block.slots.push({
            category_uids: [],
            tag_uids: [],
            content_types: [],
            count: 1,
        })

        onChange( block )
        onEditSlot( block.slots.length - 1 )
    }

    const handleRemoveSlot = ( idx ) => {
        let block = props.block
        block.slots.splice( idx, 1 )
        onChange( block )
    }

    return (
        <div className='basic-modal__config'>
            <div className='central__filters'>
                <div className="buttons">
                    <div className='dropdown dropdown--attached dropdown--question-filter'>
                        <DropdownButton title={block.mode}>
                            {Object.entries(BlockMode).map(([key, mode]) => (
                                <Dropdown.Item
                                    as='button'
                                    onClick={handleChange}
                                    id='mode'
                                    value={mode}
                                    key={"mode_" + key}>
                                    {mode}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>

                    {false &&
                        <div className='dropdown dropdown--attached dropdown--question-filter'>
                            <input
                                placeholder={Language.getSentenceCase('name')}
                                id='name'
                                value={block.name}
                                onChange={handleChange}
                            />
                        </div>
                    }

                    {block.mode == BlockMode.GUIDED &&
                        <div className='dropdown dropdown--attached dropdown--question-filter'>
                            <label className='basic-modal__label'>
                                {Language.getTitleCase('repeat block')}
                            </label>
                            <InputWithArrows
                                id="repeat"
                                value={block.repeat}
                                min={1}
                                onChange={handleChange}
                            />
                        </div>
                    }
                </div>

                <svg
                    className='close-button'
                    style={{ marginRight: '22px' }}
                    width='24'
                    height='24'
                    viewBox='0 0 13 17'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onRemove}>
                    <path d="M1.375 14.4688C1.375 15.2598 1.99023 15.875 2.78125 15.875H11.2188C11.9805 15.875 12.625 15.2598 12.625 14.4688V4.625H1.375V14.4688ZM9.34375 6.96875C9.34375 6.73438 9.54883 6.5 9.8125 6.5C10.0469 6.5 10.2812 6.73438 10.2812 6.96875V13.5312C10.2812 13.7949 10.0469 14 9.8125 14C9.54883 14 9.34375 13.7949 9.34375 13.5312V6.96875ZM6.53125 6.96875C6.53125 6.73438 6.73633 6.5 7 6.5C7.23438 6.5 7.46875 6.73438 7.46875 6.96875V13.5312C7.46875 13.7949 7.23438 14 7 14C6.73633 14 6.53125 13.7949 6.53125 13.5312V6.96875ZM3.71875 6.96875C3.71875 6.73438 3.92383 6.5 4.1875 6.5C4.42188 6.5 4.65625 6.73438 4.65625 6.96875V13.5312C4.65625 13.7949 4.42188 14 4.1875 14C3.92383 14 3.71875 13.7949 3.71875 13.5312V6.96875ZM13.0938 1.8125H9.57812L9.28516 1.28516C9.16797 1.05078 8.9043 0.875 8.66992 0.875H5.30078C5.06641 0.875 4.77344 1.05078 4.68555 1.28516L4.42188 1.8125H0.90625C0.642578 1.8125 0.4375 2.04688 0.4375 2.28125V3.21875C0.4375 3.48242 0.642578 3.6875 0.90625 3.6875H13.0938C13.3281 3.6875 13.5625 3.48242 13.5625 3.21875V2.28125C13.5625 2.04688 13.3281 1.8125 13.0938 1.8125Z" fill="#FD6E6E"/>
                </svg>
            </div>

            <div className='central_block__filters'>
                {block.mode == BlockMode.GUIDED && <>
                    {Object.entries(slots).map(([idx, slot]) => (
                        <CurriculumGuidedSlotWidget
                            key={`guided_${idx}`}
                            slot={slot}
                            category_summaries={category_summaries}
                            onEditSlot={() => onEditSlot(idx)}
                            onRemove={() => handleRemoveSlot(idx)}
                        />
                    ))}
                    <div className='slot__wrapper slot__add' onClick={handleAddSlot}>
                        <h4>{Language.getTitleCase('slot')}</h4>
                        <img src={PlusImg} alt='addition symbol' style={{ width: '24px' }}/>
                    </div>
                </>}

                {block.mode == BlockMode.SPECIFIC &&
                    <CurriculumSpecificSlotWidget
                        category_summaries={category_summaries}
                        category_questions={category_questions}
                        category_uids={category_uids}
                        question_uids={question_uids}
                        slots={slots}
                        onChange={handleChange}
                        />
                }
            </div>
        </div>
    );
};
