import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import { QueueCampaignRowWidget } from "./queue_campaign_row_widget";
import Language from "../../helpers/language";


export const QueueCampaignWidget = props => {
    const { campaigns, onChange, onRemove } = props;

    return (
        <table className="queue-table">
            <tbody>
                <tr>
                    <th>{Language.getTitleCase('effected months')}</th>
                    <th>{Language.getTitleCase('week of month')}</th>
                    <th>{Language.getTitleCase('day of week')}</th>
                    <th></th>
                </tr>
                {Object.entries(campaigns).map(([key, campaign]) => (
                    <QueueCampaignRowWidget
                        key={"campaign_row_widget_" + key}
                        campaign={campaign}
                        onChange={(k, v) => onChange(key, k, v)}
                        onRemove={() => onRemove(key)}
                    />
                ))}
            </tbody>
        </table>
    );
};
