import React, { useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

export const ContentPhishPreview = props => {
    const { question, content, showToast } = props;
    const question_uid = question.uid || "";
    const subject = content.subject || "";
    const body = content.body || "";

    const handleSendTest = e => {
        Util.fetch_js( "/phish/send_test/", { subject, body, question_uid },
            js => {
                showToast(Language.getSentenceCase('Phish sent'), 'successful');
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    };

    return (
        <div className="width-100 m-v-sm">
            {/*}
            <div className='basic-modal__field width-100 m-b-sm' style={{ alignItems: 'center' }}>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    onClick={handleSendTest}>
                    {Language.getTitleCase('Send Test')}
                </button>
            </div>
            {*/}
            <div className='basic-modal__field width-100'>
                <h3 className='basic-modal__label'>
                    {subject}
                </h3>
            </div>
            <div className='basic-modal__field width-100'>
                <iframe className='basic-modal__add-question basic-modal__add-question--text'
                        style={{height: '800px'}}
                        title='phish_preview'
                        srcDoc={body}>
                </iframe>
            </div>
        </div>
    );
};
