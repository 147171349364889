import React, { useState } from "react";
import Language from "../../helpers/language";

export const UserPopup = (props) => {
    const { onClose, user, badges } = props;

    const addBadge = (user) => {
        let user_badges = [];
        // Iterate through each id with completed badges
        Object.entries(badges).map(([key, value ]) => {
            if(key == user.uid) {
                // Assign badge collection to the user 
                user_badges = [...[value][0]];
            } 
        })
        return (
            <div className='up-popup up-popup--user-info'>
                <div className='up-popup__icon-wrapper'>
                    <div className='up-popup__icon-wrapper--cropped'></div>
                </div>
                <div className='up-popup__progress'>
                    <img className='user-popup__icon' alt='popup icon' src={user.profile_url} />
                </div>
                {user.trophy < 6 && user.trophy != '' &&(
                <div className='leaderboard__user-trophy user-popup__user-trophy'>
                    <i className={`leaderboard__trophy-icon leaderboard__trophy-icon--rank-${user.trophy}`}></i>
                </div>)}
                <h2 className='up-popup__badge-name'>
                    {user.me ? `${user.name} (${Language.getString('me')})` : user.name}
                </h2>
                <div className='up-popup__badge-stats'>
                    <div className='up-popup__badge-stats-points'>
                        <h2>{user.points > 0 ? user.points : 0}</h2>
                        <h4>{Language.getTitleCase('Points Received')}</h4>
                    </div>
                    <div className='up-popup__badge-stats-percentage'>
                        <h2>{user_badges.length}</h2>
                        <h4>{Language.getTitleCase('badges earned')}</h4>
                    </div>
                </div>
                {user_badges.length > 0 && (
                    <div className='user-popup__badge-stats'>
                        <div className='user-popup__badge-stats-points'>
                            <h4>{Language.getTitleCase('all badges')}</h4>
                            <div className='user-popup__badge'>
                                {user_badges.map((badge, index) => (
                                    <img key={index} alt='badge' src={badge.image}></img>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='up-popup__container' onClick={onClose}>
            {addBadge(user)}
        </div>
    );
};