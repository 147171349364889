import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sha256 } from "js-sha256";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { buildQuestions, filterQuestions } from "../../helpers/content";
import {AnswerType, BlockMode, ContentType, Languages} from "../../helpers/consts";
import { QuestionTableWidget } from "./question_table_widget";
import {InputWithArrows} from "../input_with_arrows";
import TrashImage from "../../assets/images/admin/trash.svg";

export const CurriculumGuidedSlotWidget = props => {
    const { slot, category_summaries, tag_summaries, onEditSlot, onChange, onRemove } = props;

    const handleRemove = e => {
        e.stopPropagation()
        onRemove()
    }

    const nameBase = ( sum, uids ) => {
        let base = Language.getTitleCase("Any")
        if ( sum != null && sum != undefined && sum.length > 0 && uids.length >= 1 ) {
            //Pull the category, and if found, store it
            const c = sum.filter(x => x.uid in c_uids)
            if (c.length >= 1) {
                base = c[0].name
            }
        }

        return base
    }

    let c_uids = {}
    let t_uids = {}
    slot.category_uids.forEach( x => c_uids[x] = true )
    slot.tag_uids.forEach( x => t_uids[x] = true )

    const cat_base = nameBase( category_summaries, slot.category_uids )
    const tag_base = nameBase( tag_summaries, slot.tag_uids )

    //Build out the titles
    let cat_title = ( slot.category_uids.length > 1 ) ? `${Util.truncate(cat_base, 14)} +${slot.category_uids.length - 1}` : Util.truncate( cat_base, 16 )
    let tag_title = ( slot.tag_uids.length > 1 ) ? `${Util.truncate(tag_base, 14)} +${slot.tag_uids.length - 1}` : Util.truncate( tag_base, 16 )

    const cts = Object.values( slot.content_types )
    const ct_title = Util.truncate( (cts.length > 0)? cts.join(', '): Language.getTitleCase('any'), 16 )

    return (
        <div className='slot__wrapper' onClick={onEditSlot}>
            <div className='basic-modal__slot_header'>
                <label className='basic-modal__label'>
                    {Language.getTitleCase('question count')}: {slot.count}
                </label>
                <svg
                    className='close-button'
                    width='12'
                    height='12'
                    viewBox='0 0 13 17'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={handleRemove}>
                    <path d="M1.375 14.4688C1.375 15.2598 1.99023 15.875 2.78125 15.875H11.2188C11.9805 15.875 12.625 15.2598 12.625 14.4688V4.625H1.375V14.4688ZM9.34375 6.96875C9.34375 6.73438 9.54883 6.5 9.8125 6.5C10.0469 6.5 10.2812 6.73438 10.2812 6.96875V13.5312C10.2812 13.7949 10.0469 14 9.8125 14C9.54883 14 9.34375 13.7949 9.34375 13.5312V6.96875ZM6.53125 6.96875C6.53125 6.73438 6.73633 6.5 7 6.5C7.23438 6.5 7.46875 6.73438 7.46875 6.96875V13.5312C7.46875 13.7949 7.23438 14 7 14C6.73633 14 6.53125 13.7949 6.53125 13.5312V6.96875ZM3.71875 6.96875C3.71875 6.73438 3.92383 6.5 4.1875 6.5C4.42188 6.5 4.65625 6.73438 4.65625 6.96875V13.5312C4.65625 13.7949 4.42188 14 4.1875 14C3.92383 14 3.71875 13.7949 3.71875 13.5312V6.96875ZM13.0938 1.8125H9.57812L9.28516 1.28516C9.16797 1.05078 8.9043 0.875 8.66992 0.875H5.30078C5.06641 0.875 4.77344 1.05078 4.68555 1.28516L4.42188 1.8125H0.90625C0.642578 1.8125 0.4375 2.04688 0.4375 2.28125V3.21875C0.4375 3.48242 0.642578 3.6875 0.90625 3.6875H13.0938C13.3281 3.6875 13.5625 3.48242 13.5625 3.21875V2.28125C13.5625 2.04688 13.3281 1.8125 13.0938 1.8125Z" fill="#FD6E6E"/>
                </svg>
            </div>

            <label className='basic-modal__label'>
                {Language.getTitleCase('categories')}
            </label>
            <div className='central__filters'>
                <label className="profile-settings__label"> {cat_title} </label>
            </div>

            <label className='basic-modal__label'>
                {Language.getTitleCase('tags')}
            </label>
            <div className='central__filters'>
                <label className="profile-settings__label"> {tag_title} </label>
            </div>

            <label className='basic-modal__label'>
                {Language.getTitleCase('content type')}
            </label>
            <div className='central__filters'>
                <label className="profile-settings__label"> {ct_title} </label>
            </div>
        </div>
    );
};
